@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

.appBarRoot {
  border-bottom: 2px solid $grey-light;
  box-shadow: none;
  transition: unset;

  .linkContainer {
    @include desktopTablet {
      margin-right: 40px;
    }
  }

  .actions {
    margin-left: auto;
    display: flex;
    align-items: center;
    align-content: center;

    .iconMyFolder {
      width: 23px;
      height: 18px;
    }

    .iconNotifications {
      width: 23px;
      height: 21px;
    }
  }

  .interlocutor {
    color: $pure-white;
    background-color: $primary-blue;
    border-radius: 40px;
    height: 30px;
    width: 30px;
    font-size: 18px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.appBarRootDrawerOpen {
  box-shadow: unset;
  border-bottom: solid 1px $grey-light;
}

.home {
  margin-right: 20px;
}

.toolbar {
  min-height: $appbar-height-desktop;

  @include mobileTablet {
    min-height: $appbar-height-mobile;
  }

  .filters {
    flex-grow: 1;
    margin: 0 auto;
    max-width: 650px;

    @include desktop-xl {
      max-width: 720px;
    }
  }
}

.colorPrimary {
  background-color: $pure-white;
  display: flex;
  justify-content: center;
  // Desktop
  @include desktopTablet {
    min-height: $appbar-height-desktop;
  }
  z-index: 800;
  // Mobile
  @include not-desktop {
    z-index: 1399;
  }
}

.iconButtonRoot {
  padding: 14px;
  border: 1px solid rgba(46, 117, 216, 0.1);
  box-sizing: border-box;
  border-radius: 6px;

  @include desktopTablet {
    width: 48px;
    height: 48px;
    margin-left: 15px;
    &:hover {
      background: $primary-blue-light;
      border: 1.5px solid rgba($primary-blue, 0.4);
      box-sizing: border-box;
    }
  }

  @include mobileTablet {
    margin-left: 12px;
    padding: 0;
    border: none;
  }

  .iconButtonLabel {
    width: 25px;
    height: 25px;
    color: $primary-dark-blue;
  }
}

.panelOpen {
  @include desktopTablet {
    background: $primary-blue-light;
    border: 1.5px solid rgba($primary-blue, 0.4);
    box-sizing: border-box;
  }
}

// Tooltip Desktop
.tooltipRoot {
  .toolTipContainer {
    background-color: $pure-white;
    color: $primary-dark-blue;
    border-radius: 12px;
  }

  .tooltipMenuLinkContainer {
    padding: 4px 8px;
  }

  .arrow {
    color: $pure-white;
    &::before {
      border: 1px solid rgba(46, 117, 216, 0.1);
      box-shadow: 0 4px 15px rgba(15, 73, 139, 0.104988);
      box-sizing: border-box;
    }
  }

  .tooltip {
    //4px 8px
    padding: unset;
  }

  .tooltipPlacementBottom {
    margin: 5px 0;
  }

  .tooltipArrow {
    background-color: $pure-white;
    border: 1px solid rgba(46, 117, 216, 0.1);
    box-shadow: 0 4px 15px rgba(15, 73, 139, 0.104988);
    border-radius: 12px;
  }

  .tooltipButton {
    font-family: $vinciFontName;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: $primary-dark-blue;
    text-transform: unset;
    padding: 10px 20px 10px 20px;

    &:hover {
      background-color: unset;
    }
  }

  .tooltipButtonStartIcon {
    color: $primary-blue;
  }
}

.folderBlock {
  background: $pure-white;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.04);
  height: calc(100vh - 32px);
  overflow: hidden;
  position: fixed;
  right: 16px;
  top: 16px;
  width: 380px;
  z-index: 2200;

  @include mobileTablet {
    border: 0;
    border-top: 1px solid $grey;
    border-radius: 0;
    height: calc(100vh - #{$appbar-height-mobile});
    top: $appbar-height-mobile;
    right: 0;
    width: 100vw;
  }
}

.notificationsBlock {
  @include desktopTablet {
    border: 1px solid rgba(216, 221, 233, 0.5);
    box-sizing: border-box;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.04);
    height: calc(100vh - 32px);
    position: fixed;
    right: 16px;
    top: 16px;
    width: 362px;
    z-index: 2200;
    background: #ffffff;

    border-radius: 5px;
  }

  @include mobileTablet {
    height: -moz-calc(100vh - 56px);
    height: -webkit-calc(100vh - 56px);
    height: calc(100vh - 56px);
  }
}

.hide {
  display: none;
}

.bg {
  @include desktopTablet {
    background: rgba(5, 35, 64, 0.6);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 2100;
  }
}

.menuPaper {
  color: $primary-dark-blue;
  border-radius: 12px;

  .menuList {
    padding: 0;
  }

  .menuItemRoot {
    padding: 4px 8px;
  }

  .menuItemButton {
    font-family: $vinciFontName;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: $primary-dark-blue;
    text-transform: unset;
    padding: 10px 20px 10px 20px;

    &:hover {
      background-color: unset;
    }
  }

  .menuItemButtonStartIcon {
    color: $primary-blue;
  }
}

.actionBtnWrapper {
  position: relative;

  .actionBtnNotifs {
    @include desktopTablet {
      position: absolute;
      top: 13px;
      right: 9px;
    }

    @include mobileTablet {
      position: absolute;
      top: 2px;
      right: -1px;
    }
  }

  &.actionBtnSearch {
    .actionBtnNotifs {
      top: 7px;
      right: 8px;

      @include mobileTablet {
        top: -4px;
        right: -2px;
      }
    }
  }

  &.actionBtnNewsletters {
    .actionBtnNotifs {
      @include mobileTablet {
        top: -4px;
        right: -2px;
      }
    }
  }
}
