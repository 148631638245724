@import "assets/styles/1-settings";
@import "assets/styles/variables";
@import "assets/styles/2-tools";

.root {
  display: flex;
  background: $pure-white;

  .text {
    font-size: 18px;
    line-height: 1.2;
    color: #293138;
  }

  .pictosList {
    margin: 0;
    padding: 0;

    li {
      min-height: 25px;
      position: relative;
      list-style: none;
      padding-left: 40px;

      &:not(:first-child) {
        margin-top: 20px;
      }

      .picto {
        width: 25px;
        position: absolute;
        left: 0;
        top: 0;

        @supports (object-fit: contain) {
          height: 25px;
          object-fit: contain;
          object-position: center center;
        }
      }
    }
  }

  .button {
    align-self: flex-start;
    height: 45px;
    text-transform: uppercase;
  }

  .media {
    border-radius: 10px;

    .video {
      width: 100%;
      overflow: hidden;
      border-radius: inherit;
    }

    .image {
      display: block;
      width: 100%;
      height: auto;
      border-radius: inherit;
    }
  }

  @include not-mobile {
    align-items: center;
    padding: 70px $home-margin-h;

    .content {
      display: flex;
      flex-direction: column;
      flex: 1 0 0;
      padding-right: 50px;

      .button {
        margin-top: 40px;
      }
    }

    .media {
      flex: 0 1 auto;
      width: 57%;
      max-width: 730px;
    }
  }

  @include mobile {
    flex-direction: column;
    padding: 35px 20px;

    .content {
      .button {
        margin-top: 35px;
      }
    }

    .media {
      width: 100%;
      margin-top: 35px;

      .video {
        width: 100%;
      }
    }
  }
}
