@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

.drawerRoot {
  .paperRoot {
    padding: $appbar-height-mobile 0 0 0;

    @include not-mobile {
      max-width: 412px;
      padding: 16px;
    }
  }

  .drawerContainer {
    @include not-mobile {
      border-radius: 5px;
    }
  }
}

.header {
  display: flex;
  align-items: center;
  height: 68px;
  padding: 0 16px 0 24px;

  @include mobile {
    border-bottom: 1px solid $grey-light;
  }

  .iconContainer {
    position: relative;
    z-index: 0;

    .nb {
      position: absolute;
      z-index: 10;
      padding: 0 0 0.1em;

      @include desktopTablet {
        top: -1 * map-get($notification-dot-dim, xl) / 2;
        right: -1 * map-get($notification-dot-dim, xl) / 2;
      }

      @include mobileTablet {
        top: -1 * map-get($notification-dot-dim, sm) / 2;
        right: -1 * map-get($notification-dot-dim, sm) / 2;
      }
    }
  }

  .icon {
    width: 1em;
    height: 1em;
    position: relative;
    z-index: 0;
    font-size: 22px;
  }

  .title {
    display: flex;
    align-items: center;
    margin-left: 16px;
    font-weight: 500;
    font-size: 18px;
    line-height: 1;
    color: $pure-black;
  }

  .close {
    margin-left: auto;
    font-size: 15px;
    color: $pure-black;
  }
}

.content {
  padding: 16px 16px 0;
}

.group {
  &:not(:first-child) {
    margin-top: 35px;
  }

  .title {
    margin-bottom: 10px;
    font-size: 10px;
    color: $black;

    &.today {
      color: #b4becb;
    }
  }
}

.newsletter {
  display: flex;

  .iconContainer {
    display: flex;
    flex: 0 0 auto;
    width: 2.2em;
    height: 2.2em;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    border: 2px solid #ebeef4;
    border-radius: 100%;
    background: $primary-dark-blue;
    font-size: 20px;

    .icon {
      width: 1em;
      height: 1em;
    }
  }

  .content {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    justify-content: center;
    color: $pure-black;
  }

  .title {
    margin: 0;
    font-size: 14px;
  }

  .link {
    font-size: 12px;
    text-decoration: underline;
    color: inherit;
  }

  .time {
    margin-left: 10px;
    padding-top: 6px;
    font-size: 10px;
    color: #b4becb;
  }

  &.unread {
    .iconContainer {
      background: $pink;
    }
  }

  & + & {
    margin-top: 25px;
  }
}
