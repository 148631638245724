@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

$gutter: (
  xl: 50px,
  md: 20px,
);
$circleDim: 86px;

.title {
  margin: 0;
  font-weight: 500;
  font-size: 24px;
  color: #293138;
}

.list {
  display: flex;
  flex-wrap: wrap;

  @include desktopTablet {
    margin-right: (-1 * map-get($gutter, xl) / 2);
    margin-left: (-1 * map-get($gutter, xl) / 2);

    > .item {
      width: calc(#{100% / 3} - #{map-get($gutter, xl)});
      margin-right: (map-get($gutter, xl) / 2);
      margin-left: (map-get($gutter, xl) / 2);
    }
  }

  @include mobileTablet {
    flex-direction: column;
    margin-top: (-1 * map-get($gutter, md) / 2);
    margin-bottom: (-1 * map-get($gutter, md) / 2);

    > .item {
      margin-top: (map-get($gutter, md) + $circleDim) / 2;
      margin-bottom: (map-get($gutter, md) / 2);
    }
  }
}

.item {
  display: flex;
  position: relative;
  margin-top: ($circleDim / 2);
  padding: (10px + $circleDim / 2) 50px;
  border-radius: 8px;
  background-color: $primary-blue;
  color: $white;

  @include desktopTablet {
    &:not(:last-child):after {
      content: "+";
      position: absolute;
      top: calc(50% - 0.2em);
      left: calc(100% + #{map-get($gutter, xl) / 2});
      font-size: 50px;
      line-height: 1;
      color: #293138;
      transform: translate(-50%, -50%);
    }
  }
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  height: $circleDim;
  width: $circleDim;
  box-shadow: 0 10px 9px 0 rgba($black2, 0.18);
  background-color: $white;
  border-radius: 100%;
}

.img {
  width: 46px;
  height: auto;
}

.text {
  max-width: 200px;
  margin: 0 auto;
  text-align: center;
}
