@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.show {
  display: block;
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.1s;
  width: 0;
  height: 0;
}

.modalSkin {
  position: relative;
  min-width: 1060px;
  width: 1060px;
  text-shadow: none;

  background: $pure-white;
  border-radius: 12px;

  @include mobileTablet {
    min-width: 0;
    width: 100%;
    height: auto;
  }
}

.modalOuter {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.modalRoot {
  min-width: 1060px;
  width: 1060px;
  top: calc(#{$appbar-height-desktop} + 30px);
  transition: top 0.3s ease 0.3s;

  position: fixed;
  left: 50%;
  z-index: 1001;
  transform: translateX(-50%);
  max-height: calc(100% - #{$appbar-height-desktop} - 60px);
  display: flex !important;

  // Mobile
  @include mobileTablet {
    top: $appbar-height-mobile;
    z-index: 3000;
    min-width: unset;
    overflow: auto;
    max-height: none;
    height: calc(100% - #{$appbar-height-mobile});
    width: 100%;
  }
}

.modalRootForm {
  .scrollPaper {
    @include tablet {
      height: calc(100% - #{$appbar-height-desktop});
      margin-top: $appbar-height-desktop;
    }

    @include mobile {
      height: calc(100% - #{$appbar-height-mobile});
      margin-top: $appbar-height-mobile;
    }
  }

  .modalSkin {
    max-height: calc(100% - #{$appbar-height-desktop});

    @include tablet {
      margin: 32px 16px;
      max-width: 860px;
      min-width: 0;
      width: calc(100% - 32px);
    }

    @include mobile {
      margin: 32px 16px;
      max-height: calc(100% - #{$appbar-height-mobile});
    }
  }

  .modalSkinPdf {
    width: 670px;
  }
}

.modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $primary-dark-blue;
  border-radius: 10px 10px 0 0;
  padding: 30px 0 30px 70px;
  color: $white;

  @include mobileTablet {
    padding: 16px 0 16px 28px;
    border-radius: 0;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
  }

  .title {
    display: inline-flex;
    align-items: center;
    font-weight: 500;
    font-size: 26px;
    line-height: 26px;

    .icon {
      margin-right: 12px;
    }
  }
  .icon {
    margin-right: 6px;
  }
}

.modalHeaderSuccess {
  background: $green;
}

.rightContent {
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  margin-right: 0;
  padding: 0;

  .iconButton {
    color: $pure-white;
    font-size: 15px;
    padding-left: 35px;
    padding-right: 45px;
    &:hover {
      background: none;
    }

    @include mobileTablet {
      padding-left: 0;
      padding-right: 24px;
    }
  }
}

.modalContent {
  max-height: 100%;
  overflow-y: auto;

  .form {
    position: relative;
    padding: 16px 78px 0 78px;
    @include mobileTablet {
      padding: 16px 24px 0 24px;
    }

    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 26px;
      line-height: 26px;
      color: $pure-black;
      padding-bottom: 28px;
    }

    .grid {
      // Mobile & portrait tablet
      @include mobileTablet {
        display: flex;
        flex-direction: column;
      }
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      .input {
        padding-bottom: 24px;
        &:nth-child(n + 1) {
          margin-right: 20px;
        }
      }
    }

    .input {
      padding-bottom: 8px;
    }
  }

  .additionalInformation {
    position: absolute;
    top: 100%;
    font-size: 12px;
    line-height: 14px;
    color: $pure-grey;
  }
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  padding: 0 78px 16px 78px;

  // Mobile
  @include mobileTablet {
    flex-direction: column-reverse;
    padding: 24px 24px;
  }

  .cancelButton {
    width: 160px;
    margin-right: 16px;
    // Mobile
    @include mobileTablet {
      width: auto;
    }
  }
  .createButton {
    width: 240px;
    @include mobileTablet {
      width: auto;
      margin-bottom: 10px;
    }
  }
}

.errorFooter {
  justify-content: flex-end;
  padding: 0;

  .errorContent {
    width: 100%;
    background-color: rgba(224, 60, 49, 0.04);
    color: $red;
    padding-top: 16px;
    padding-bottom: 40px;

    @include mobileTablet {
      padding-top: 31px;
      padding-bottom: 20px;
      padding-right: 0;
    }

    .center {
      margin: auto;
      h4 {
        font-size: 22px;
        line-height: 30px;
        text-align: center;
        font-weight: 500;
        padding: 0;
        margin: 0;

        @include mobileTablet {
          padding: 0 45px 20px 45px;
        }
      }
      p {
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        padding: 0;
        margin: 6px 0 0;
        @include mobileTablet {
          padding: 0 28px;
        }
      }
    }

    .right {
      position: relative;

      .iconButton {
        position: absolute;
        top: -5px;
        right: 35px;
        @include mobileTablet {
          top: -20px;
          right: 5px;
        }

        &:hover {
          background: none;
        }
        .svgIcon {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}

.helperTextDisplay {
  padding-top: 12px;
}

.actionButton {
  display: flex;
  justify-content: center;
  height: 45px;

  @include mobileTablet {
    padding: 24px 32px;
    border-bottom: 1px $grey solid;
    width: 100%;
    min-height: 96px;
  }
}

.link {
  width: 100%;
  height: 100%;
  background: $primary-blue;
  border: none;
  border-radius: 22.5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: $white !important;
  display: flex;
  align-items: center;
  text-align: center;
  flex: none;
  order: 1;
  flex-grow: 0;
  padding: 6px 28px;
  justify-content: center;

  &:hover {
    text-decoration: none;
  }
}

.cardsList {
  align-items: center;
  background-color: $grey-light;
  display: flex;
  justify-content: center;
  padding: 16px 0;
}

.cardsListItem {
  align-self: stretch;
  max-width: 250px;
  padding: 0 24px;
  width: 33.33%;
}

.lotCardWrapper {
  background-color: $grey-light;
  display: flex;
  padding: 12px 51px;

  @include mobileTablet {
    display: block;
    padding: 12px 0;
    width: 100%;
  }
}

.lotCard {
  width: 400px;

  @include mobileTablet {
    width: 100%;
  }
}

.programWardWrapper {
  background-color: $grey-light;
  padding: 0 58px;

  @include mobileTablet {
    padding: 0 4px;
  }
}

.modalSkinPdf {
  .modalContent {
    .form {
      padding: 16px 78px;

      @include mobileTablet {
        padding: 16px 24px 0;
      }
    }
  }

  .modalFooter {
    display: flex;
    justify-content: flex-end;

    .cancelButton {
      width: 50%;

      @include mobileTablet {
        width: 100%;
      }
    }
    .createButton {
      width: 50%;

      @include mobileTablet {
        width: 100%;
        margin-bottom: 10px;
      }
    }

    &.errorFooter {
      padding: 0;
    }
  }
}

.sendPdf {
  .additionalInformation {
    position: relative;

    @include mobile {
      position: absolute;
    }
  }
}
