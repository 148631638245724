@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.medias {
  transition: $transition-duration-fast;
  transition-property: visibility, opacity;

  &.enterActive,
  &.enterDone,
  &.exit {
    opacity: 1;
  }

  &.exitActive,
  &.exitDone,
  &.enter {
    opacity: 0;
  }
}

.activeMedia {
  :global .container :local & {
    @include mobile {
      margin-right: -20px;
      margin-left: -20px;
    }
  }
}
