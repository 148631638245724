@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

$padding-x: (
  md: 40px,
  sm: 16px,
);
$padding-y: (
  md: 40px,
  sm: 24px,
);

.root {
  @include not-mobile {
    padding: 0 map-get($padding-x, md);
  }

  @include mobile {
    padding: 0 map-get($padding-x, sm);
  }
}

.title {
  margin: 0;
  font-weight: 400;
  font-size: 32px;
  color: $pure-black;
  line-height: 1;
}

.titleAddress {
  margin-top: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: $primary-dark-blue;

  &::before {
    display: inline-block;
    margin-right: 0.5ch;
    font-weight: 400;
    color: $pure-black;
  }

  &[data-prefix]::before {
    content: attr(data-prefix);
  }
}

.map {
  position: relative;

  @include not-mobile {
    margin: 24px (-1 * map-get($padding-x, md)) (-1 * map-get($padding-y, md));
  }

  @include mobile {
    margin: 24px (-1 * map-get($padding-x, sm)) (-1 * map-get($padding-y, sm));
    overflow: hidden;
  }
}

.itinerary {
  $height: 85px;
  $btnGap: 12px;

  height: $height;
  position: absolute;
  border-radius: $height;
  padding: $btnGap $btnGap $btnGap 35px;
  box-shadow: 0 4px 15px rgba($primary-dark-blue, 0.1);

  @include desktop {
    font-size: 20px;
  }

  @include not-desktop {
    font-size: 16px;
  }

  @include not-mobile {
    left: 10px;
    bottom: 127px;
  }

  @include mobile {
    display: none;
  }

  .destination {
    max-width: calc(100% - #{$height - $btnGap * 2});
  }

  .start {
    width: $height - $btnGap * 2;
    height: $height - $btnGap * 2;
  }

  .itineraryAddress {
    line-height: 20px;
  }
}
