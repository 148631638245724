@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

$folderLinkHeaderHeight: 68px;
$folderButtonCreateHeight: 70px;

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  height: $folderLinkHeaderHeight;
  padding: 0 16px 0 24px;

  @include mobile {
    border-bottom: 1px solid $grey-light;
  }
}

.title {
  align-items: center;
  color: $pure-black;
  display: flex;
  font-family: $vinciFontName;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  justify-content: space-between;
  line-height: 18px;

  span {
    margin-left: 16px;
  }
}

.svgIcon {
  width: 25px;
  height: 26px;
}

.close {
  border: none;
  color: $pure-black;
  font-size: 15px;
  padding: 0;
}

.content {
  flex-grow: 1;

  @include mobileTablet {
    overflow-y: auto;
  }
}

.scroll {
  height: 100%;
}

.scrollContent {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.searchContainer {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  padding: 20px 24px;
}

.search {
  width: 100%;
}

.searchField {
  height: 48px;
}

.cards {
  display: none;
  flex: 0 0 auto;

  &.open {
    display: block;
  }
}

.folderList {
  flex-grow: 1;
  padding: 0 24px;
  position: relative;
}

.folderItem {
  margin: 0 0 7px;

  &:last-child {
    margin: 0;

    @include mobileTablet {
      margin: 0 0 $folderButtonCreateHeight * 3;
    }
  }

  .folderButton {
    background-color: $white;
    border-radius: 6px;
    justify-content: flex-start;
    letter-spacing: unset;
    padding: 5px 24px;
    text-align: left;

    &:hover {
      background-color: $primary-blue-light;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    }

    .startIcon {
      margin-left: 0;
      margin-right: 24px;
    }

    .folderButtonTitle {
      color: $pure-black;
      font-family: $vinciFontName;
      font-size: 18px;
      font-weight: 500;
      text-transform: none;
    }

    .folderButtonDate {
      color: $pure-grey;
      font-family: $vinciFontName;
      font-size: 16px;
      font-weight: 400;
      line-height: 1;
      text-transform: none;
    }
  }
}

.folderEmpty {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 0 20px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  .folderEmptyText {
    font-size: 16px;
    margin: 20px 0;
    text-align: center;
  }

  img {
    height: 50px;
    width: 50px;
  }
}

.createBtnContainer {
  background-color: $pure-white;
  flex: 0 0 auto;
  height: $folderButtonCreateHeight;
  padding: 11px 24px;
  width: 100%;

  @include mobileTablet {
    bottom: 0;
    left: 0;
    position: fixed;
  }
}

.createBtn {
  display: flex;
  justify-content: center;

  .createButton {
    height: 48px;
    padding: 0 50px;
  }

  .label {
    align-items: center;
    display: flex;
    font-family: $vinciFontName;
    font-weight: 500;
    font-size: 16px;
    text-transform: none;
    text-align: center;
  }
}

.startIconContainer {
  position: relative;
}

.folderNbElements {
  color: $primary-blue;
  font-family: $vinciFontName;
  font-size: 10px;
  font-weight: 500;
  left: 0;
  line-height: 1;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: 16px;
}

.toggle {
  background-color: $primary-dark-blue;
  color: $pure-white;
  display: flex;
  font-size: 18px;
  font-weight: 500;
  justify-content: center;
  padding: 17px 0;
  position: relative;
  text-transform: capitalize;
}

.toggleButton {
  align-items: center;
  background: $pure-white;
  border: 0;
  border-radius: 8px;
  color: $pure-black;
  display: flex;
  font-size: 12px;
  height: 30px;
  justify-content: center;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;

  &:hover {
    background-color: $primary-blue;
    color: $pure-white;
  }

  .icon {
    transition: transform 0.25s ease-in-out;
  }

  .iconOpen {
    transform: rotate(180deg);
  }
}

.drawerRoot {
  .paperRoot {
    max-width: 412px;
    padding: 16px;

    @include mobile {
      max-width: none;
      padding: $appbar-height-mobile 0 0 0;
    }
  }

  .drawerContainer {
    border-radius: 5px;

    @include mobile {
      border-radius: 0;
    }
  }
}
