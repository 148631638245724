@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.root {
  color: $red2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.link {
  &:hover,
  &:focus,
  &:active {
    .icon {
      color: $primary-dark-blue;
    }
  }
}

.icon {
  height: 38px;
  width: 38px;
  color: $red2;
  fill: currentColor;
  transition: color 0.25s;
}

.title {
  font-size: 12px;
  color: $black;
}

.detail {
  font-weight: 500;
  font-size: 12px;
  color: $primary-dark-blue;
  text-align: center;
}

.bookletLink {
  background: none;
  border: 0;
  color: $primary-dark-blue;
  font-family: $vinciFontName;
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  outline: none;
  padding: 0;

  &::after {
    background-color: $primary-blue;
    content: "";
    display: block;
    height: 1px;
    transition: 300ms;
    width: 100%;
  }

  &:hover::after {
    width: 36%;
  }
}
