@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.root.selectorWeight {
  display: flex;
  border-radius: 8px;
  box-shadow: 0 4px 4px rgba($pure-black, 0.25);

  @include not-mobile {
    min-width: 444px;
    min-height: 72px;
    padding: 10px 20px 10px 25px;
  }

  @include mobile {
    flex-direction: column;
    padding: 8px 10px;
  }
}

.message.selectorWeight {
  flex: 1 0 auto;
  padding: 0;

  @include mobile {
    text-align: center;
  }
}

.action.selectorWeight {
  $gutter: 8px;

  align-self: stretch;
  margin: 0 (-$gutter / 2);

  > * {
    margin: 0 ($gutter / 2);
  }

  @include not-mobile {
    padding-left: 20px;
  }

  @include mobile {
    justify-content: space-evenly;
    padding: 10px 0 0;
  }
}

.button {
  min-width: 100px;
  border-radius: 35px;
  padding: 5px 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;

  @include not-mobile {
    height: 35px;
  }

  @include mobile {
    height: 28px;
  }
}

.success.selectorWeight {
  $mainColor: $green;
  $textColor: $pure-white;

  background: $mainColor;
  color: $textColor;

  .button {
    &.primary {
      border: 0;
      background: $textColor;
      color: $mainColor;
    }

    &.secondary {
      background: transparent;
      border: 1px solid currentColor;
      color: $textColor;
    }
  }
}

.error.selectorWeight {
  $mainColor: $red;
  $textColor: $pure-white;

  background: $mainColor;
  color: $textColor;

  .button {
    &.primary {
      border: 0;
      background: $textColor;
      color: $mainColor;
    }

    &.secondary {
      background: transparent;
      border: 1px solid currentColor;
      color: $textColor;
    }
  }
}

.warning.selectorWeight {
  $mainColor: $orange;
  $textColor: $pure-white;

  background: $mainColor;
  color: $textColor;

  .button {
    &.primary {
      border: 0;
      background: $textColor;
      color: $mainColor;
    }

    &.secondary {
      background: transparent;
      border: 1px solid currentColor;
      color: $textColor;
    }
  }
}

.info.selectorWeight {
  $mainColor: $primary-blue;
  $textColor: $pure-white;

  background: $mainColor;
  color: $textColor;

  .button {
    &.primary {
      border: 0;
      background: $textColor;
      color: $mainColor;
    }

    &.secondary {
      background: transparent;
      border: 1px solid currentColor;
      color: $textColor;
    }
  }
}
