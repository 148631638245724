@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.services {
  @include desktop {
    margin: 0 auto;
    max-width: 225px;
    width: 100%;
  }
  @include not-desktop {
    padding: 0 40px;
  }

  :global {
    .slick-slider {
      .slick-arrow {
        border: 1px solid $primary-dark-blue;
        border-radius: 50%;
        height: 22px;
        width: 22px;
        z-index: 1;

        &:before {
          border-style: solid;
          border-width: 2px 2px 0 0;
          color: $primary-dark-blue;
          content: "";
          display: inline-block;
          height: 7px;
          opacity: 1;
          position: relative;
          transform: rotate(45deg);
          vertical-align: top;
          width: 7px;
        }

        &.slick-next {
          right: 0;

          &:before {
            margin-left: -3px;
          }
        }

        &.slick-prev {
          left: 0;

          &:before {
            margin-right: -3px;
            transform: rotate(-135deg);
          }
        }
      }
    }
  }
}

.servicesTitle {
  color: $pure-grey;
  font-size: 12px;
  margin: 0 0 2px 0;
  text-align: center;
}

.servicesItem {
  padding: 0 25px;
}

.vi4youImg {
  display: block;
  height: 40px;
}
