@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

:global {
  .gm-ui-hover-effect {
    display: none !important;
  }
  .google_map_infobox {
    border: none;
    width: 202px;
  }

  @include mobileTablet {
    .gm-style .gm-style-iw,
    .gm-style .gm-style-iw a,
    .gm-style .gm-style-iw span,
    .gm-style .gm-style-iw label,
    .gm-style .gm-style-iw div {
      overflow-y: scroll;
      overflow-x: visible;
    }

    .gm-style-iw div {
      overflow: visible !important;
    }
  }

  .gm-style .gm-style-iw-t::after {
    background: none;
    box-shadow: none;
  }

  .gm-style-iw + div {
    display: none;
  }

  .gm-style .gm-style-iw-c {
    background: none;
    box-shadow: none;
    padding: 0;
    border-radius: 0;
    overflow: auto;
  }

  .gm-style .gm-style-iw-d {
    overflow: visible !important;
  }

  .gm-style div,
  .gm-style span,
  .gm-style label,
  .gm-style a {
    font-family: Vinci Sans, Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
  }

  .gm-style div,
  .gm-style span,
  .gm-style label {
    text-decoration: none;
  }

  .gm-style a,
  .gm-style label {
    display: inline;
  }

  .gm-style div {
    display: block;
  }

  .gm-style img {
    border: 0;
    padding: 0;
    margin: 0;
  }
}

.root {
  display: flex !important;
  flex-direction: column;
  padding-left: 4px;
  width: 259px;

  @include mobileTablet {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-left: 0;
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .content {
    background-color: $pure-white;
    border-radius: 12px;
    box-shadow: 0 4px 15px 0 rgba(15, 73, 139, 0.1);
    overflow: hidden;

    @include mobileTablet {
      background: none;
      border-radius: 0;
      box-shadow: none;
      padding: 32px 24px;
    }
  }

  .programInfos {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    @include mobileTablet {
      border-radius: 6px;
      box-shadow: 0 4px 15px 0 rgba(15, 73, 139, 0.1);
      flex-direction: row;
    }

    &:hover {
      .name {
        color: $primary-dark-blue;
      }
    }

    &:focus {
      outline: none;
    }
  }

  .image {
    position: relative;

    @include mobileTablet {
      overflow: hidden;
      padding-left: 4px;
      width: 30%;
    }

    img {
      border-radius: 4px 4px 0 0;
      display: block;
      height: auto;
      width: 100%;

      @include mobileTablet {
        border-radius: 4px 0 0 4px;
        height: 100%;
        width: auto;
      }
    }
  }

  .programContent {
    padding: 12px 16px 0;

    @include mobileTablet {
      background-color: $pure-white;
      border-radius: 0 4px 4px 0;
      flex-grow: 1;
      padding: 16px;
    }
  }

  .thumbnailLabel {
    position: absolute;
    font-weight: 500;
    font-size: 10px;

    @include desktopTablet {
      top: 10px;
      left: -4px;
    }

    @include mobileTablet {
      width: 74px;
      left: 0;
    }
  }

  .pictos {
    align-items: center;
    bottom: 10px;
    display: flex;
    left: 12px;
    margin: 0 -5px;
    position: absolute;

    @include mobileTablet {
      bottom: 8px;
      left: 8px;
      margin: 0 -4px;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .pictosItem {
    padding: 0 5px;

    @include mobileTablet {
      padding: 0 4px;
    }

    .vi4you {
      height: 40px;
      width: 40px;

      @include mobileTablet {
        height: 20px;
        width: 20px;
      }
    }

    .controlledPrice {
      height: 20px;
      width: auto;

      @include mobileTablet {
        height: 15px;
      }
    }
  }

  .name {
    color: $pure-black;
    font-family: $vinciFontName;
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 8px;
  }

  .infos {
    align-items: center;
    display: flex;
  }

  .pastille {
    margin: 0 8px 0 0;
  }

  .location {
    color: $primary-blue;
    font-family: $vinciFontName;
    font-size: 16px;
    margin: 0 0 8px;

    @include mobile {
      font-size: 14px;
      margin: 0;
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    padding: 0 32px 14px;

    @include mobileTablet {
      background: none;
      padding: 0;
    }
  }

  .buttonWrapper {
    width: 100%;
    margin: 7px 0 0;
  }

  .button {
    box-shadow: none;
    color: $pure-black;
    font-size: 16px;
    height: 46px;
    padding: 0 10px;
    text-transform: none;
    .iconHover {
      display: none;
    }
    &:hover {
      .iconDefault {
        display: none;
      }
      .iconHover {
        display: initial;
      }
    }
  }

  .buttonLabel {
    display: flex;
    justify-content: space-between;

    @include mobileTablet {
      justify-content: center;
    }

    .label {
      flex-grow: 1;

      @include mobileTablet {
        flex-grow: 0;
        min-width: 130px;
      }
    }
  }

  .kelQuartierOpen {
    background-color: $pure-white;
    border: 1px solid $grey;

    .label {
      color: $black;
    }
  }

  .icon {
    color: $primary-blue;
    font-size: 16px;
  }

  .iconSvg {
    height: 28px;
    width: 45px;
  }

  .label {
    color: $pure-black;
    font-family: $vinciFontName;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    &:hover {
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      color: $primary-dark-blue;
    }
  }
}

.closeCardBtn {
  align-items: center;
  background-color: $pure-white;
  border: 0;
  border-radius: 8px;
  box-shadow: 4px 0 15px 0 rgba(15, 73, 139, 0.1);
  color: $black;
  display: flex;
  font-size: 16px;
  height: 48px;
  justify-content: center;
  left: 16px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 16px;
  width: 48px;
  z-index: 1;
}
