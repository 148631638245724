@import "src/assets/styles/1-settings";

.chipRoot.selectorWeight {
  background-color: $pink;
  height: 22px;
  vertical-align: top;

  &.chipLight {
    border: 1px solid $pure-black;

    .icon,
    .label {
      color: $pure-black;
    }
  }

  .label {
    // color: $pure-white;
    font-family: $vinciFontName;
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    padding: 0 10px;
    text-transform: uppercase;
  }

  .icon {
    color: unset;
    font-size: 10px;
    margin-left: 10px;
  }
}

.fullWidth {
  width: 100%;
}
