@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.root {
  background-color: $white;
  border: 1px solid $grey;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 15px 70px 15px 80px;
  position: relative;
}

.label,
.value {
  color: $primary-dark-blue;
  font-size: 16px;
}

.value {
  height: 24px;
  font-weight: 500;
  word-wrap: break-word; // apparently words like "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa" are allowed and should be accounted for...
}

.iconStart,
.icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 22px;
}

.icon {
  color: $black;
  right: 22px;
}

.iconStart {
  color: $primary-dark-blue;
  left: 22px;
  width: 24px;
  height: 24px;
}

.cards {
  display: flex;

  @include mobileTablet {
    flex-wrap: wrap;
  }
}

.card {
  flex: 0 0 auto;
  width: 50%;
  font-family: $vinciExpanded;

  @include mobileTablet {
    width: 100%;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.card:not(:first-child) {
  padding-left: 90px;

  @include mobileTablet {
    padding-left: unset;
  }
}
