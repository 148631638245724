@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

.loading {
  display: flex;
  flex: 1 0 auto;
  height: 100%;
  min-height: calc(100vh - #{$appbar-height-mobile});
  justify-content: center;
  align-items: center;
  background: rgba($black2, 0.3);
}

.root {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  background-color: $grey-light;

  @include desktop {
    margin: 76px 76px 56px;
    padding: 70px;
  }

  @include not-desktop {
    padding: 20px;
  }
}

.goBack {
  display: flex;
  align-self: flex-start;
  align-items: center;
  position: relative;
  margin-bottom: 26px;
  margin-left: 25px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  text-transform: uppercase;
  color: $pure-black;

  &::before {
    @extend %icomoon;

    content: map-get($icomoon, "arrow-left");
    position: absolute;
    top: calc(50% - 0.5em);
    right: calc(100% + 5px);
    font-size: 20px;
    color: $primary-dark-blue;
  }
}

.pageTitle {
  margin: 0 0 24px;
  font-weight: 500;
  font-size: 48px;
  line-height: 1;
  color: $pure-black;
}

.program {
  max-width: 460px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .name {
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.2;
    color: $primary-dark-blue;
  }

  .address {
    font-weight: 400;
    font-size: 16px;
    line-height: 1;
    color: $pure-black;
  }
}

.report {
  margin-top: 50px;
  border-radius: 10px;
  background: $pure-white;

  @include desktop {
    padding: $report-detail-padding-md;

    .image {
      width: calc(100% + #{2 * $report-detail-padding-md});
      margin: (-$report-detail-padding-md) (-$report-detail-padding-md) $report-detail-padding-md;
    }
  }

  @include not-desktop {
    padding: $report-detail-padding-sm;

    .image {
      width: calc(100% + #{2 * $report-detail-padding-sm});
      margin: (-$report-detail-padding-sm) (-$report-detail-padding-sm) $report-detail-padding-sm;
    }
  }

  .header {
    margin-bottom: 30px;
  }

  .image {
    display: block;
    height: auto;
    border-top-right-radius: inherit;
    border-top-left-radius: inherit;
  }

  .title {
    margin: 0;
    font-weight: 500;
    font-size: 26px;
    color: $pure-black;
  }

  .date {
    display: block;
    margin-top: 10px;
    font-size: 16px;
    color: $grey-light-3;
  }

  .banner {
    max-width: 500px;
    margin-bottom: 20px;
  }
}

.freeBlocks {
  margin-top: 30px;

  .freeBlock {
    box-shadow: 0 4px 15px 0 rgba(#0f498b, 0.1);
  }

  @include desktop {
    margin-right: 20px;
    margin-left: 20px;

    .freeBlock:not(:first-child) {
      margin-top: 20px;
    }
  }
}

.documents {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 40px;

  .document {
    display: inline-flex;
    align-items: center;
    height: 60px;
    border-radius: 4px;
    padding: 10px 24px;
    background: $primary-dark-blue;
    font-size: 16px;
    text-decoration: none;
    color: $pure-white;
    box-shadow: 0 4px 15px 0 rgba(#0f498b, 0.1);
    transition: background-color 0.25s ease-in-out;

    &:not(:first-child) {
      margin-top: 20px;
    }

    &:hover,
    &:focus,
    &:active {
      background: $primary-blue;
    }

    .icon {
      margin-right: 14px;
      margin-left: 34px;
      font-size: 18px;
    }
  }
}
