@import "assets/styles/1-settings";

.root {
  .labelRoot {
    color: $pure-black;
    font-family: $vinciFontName;
    font-size: 15px;
    line-height: 1.4;
    overflow: hidden;
    padding-right: 32px;
    text-overflow: ellipsis;
    top: 50%;
    transform: translate(24px, -50%) scale(1);
    white-space: nowrap;
    width: calc(100% - 32px);

    &:global(.MuiFormLabel-filled) {
      display: none;
    }

    &.labelFocused {
      color: $primary-blue;
      transform: translate(24px, -50%) scale(1);
    }

    &.labelFilled {
      display: none;
    }
  }

  .selectRoot {
    align-items: center;
    background-color: $pure-white;
    border: 1px solid $grey;
    border-radius: 30px;
    box-sizing: border-box;
    color: $primary-dark-blue;
    display: flex;
    font-family: $vinciFontName;
    font-size: 16px;
    font-weight: 500;
    height: 48px;
    padding: 0 16px;

    &:hover {
      background-color: $primary-blue-light;
      border-color: rgba(5, 117, 230, 0.4);
    }

    &:focus {
      background-color: $pure-white;
      border-radius: 30px;
    }
  }

  :global(.MuiOutlinedInput-notchedOutline) {
    border: 0;
    display: none;
    padding: 0 40px;
    top: 0;
  }

  .icon {
    color: $pure-black;
    font-size: 8px;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }

  .selectInputUnderline {
    &:after,
    &:before {
      content: none;
    }
  }

  .selectInputFocused {
    .icon {
      color: $primary-blue;
      transform: translateY(-50%) rotate(180deg);
    }
  }

  label + .selectInputFormControl {
    margin: 0;
  }

  .selectValue {
    display: flex;
    width: calc(100% - 24px);

    &.full {
      width: 100%;
    }

    span {
      &:before {
        content: ", ";
      }

      &:first-child {
        &:before {
          content: "";
        }
      }
    }
  }

  .chip {
    flex: 0 0 auto;

    &.hidden {
      display: none;
    }

    &.first {
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 100%;
    }
  }
}

.popover {
  .paper {
    border: 1px solid $grey;
    border-radius: 22px;
    box-shadow: 0 5px 21px 0 rgba(15, 73, 139, 0.1);
    margin-top: 8px;
  }

  .menuList {
    margin: 23px 24px 20px;
    max-height: 320px;
    padding: 0;
    overflow-y: auto;

    :global(.MuiListItem-root) {
      padding-left: 0;

      &:hover {
        background: none;
      }

      :global(.MuiTouchRipple-root) {
        display: none;
      }
    }
  }
}
