@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 100%;
}

.root {
  background: $pure-white;
  color: $pure-black;
  display: flex;
  flex-direction: column;
  min-height: 100%;

  @include mobileTablet {
    padding: 0;
  }
}

.banner {
  display: flex;
  justify-content: center;
  max-height: 67vh;
  overflow: hidden;
  padding: 0 80px;
  position: relative;

  @include not-desktop {
    padding: 0;
  }
}

.opacity {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.55) 100%);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.detailImgWrapper {
  max-width: 80%;
  position: relative;

  @include not-desktop {
    max-width: none;
    width: 100%;
  }
}

.detailImg {
  display: block;
  height: auto;
  left: 0;
  width: 100%;
  position: relative;
  transform: none;
}

.print {
  background: rgba(255, 255, 255, 0.16);
  border: 0;
  border-radius: 2px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
  height: 36px;
  outline: none;
  padding: 0;
  position: absolute;
  right: 40px;
  top: 40px;
  width: 70px;

  @include mobile {
    display: none;
  }

  img {
    display: block;
    margin: 7px auto;
    width: 30px;
  }
}

.main {
  background: $pure-white;
  display: block;
  flex: 1 0 auto;
  padding: 50px 80px;
  position: relative;

  @include mobileTablet {
    padding: 30px 20px;
  }
}

.title {
  bottom: 140px;
  color: $white;
  font-size: 52px;
  font-weight: 300;
  left: 50px;
  margin: 0;
  position: absolute;

  @include mobileTablet {
    bottom: 83px;
    left: 25px;
    font-size: 37px;
  }

  @include mobile {
    bottom: 44px;
    font-size: 18px;
  }
}

.content {
  max-width: 80%;
  margin: 0 auto;

  @include mobile {
    max-width: 100%;
  }
}

.date {
  font-weight: 300;
}

.desc {
  font-size: 18px;
  font-weight: 500;

  @include mobile {
    font-size: 22px;
  }
}

.separation {
  border-top: solid 2px;
  margin-top: 45px;
  opacity: 0.08;
}
