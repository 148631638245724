@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.root {
  align-items: center;
  display: flex;

  @include not-mobile {
    height: 90px;
    padding: 10px 24px 10px 32px;
  }

  @include mobile {
    height: auto;
    padding: 10px 16px 10px 13px;
  }
}

.content {
  align-items: flex-start;
  display: flex;
  flex-grow: 1;
}

.checkbox {
  padding-left: 11px;
}

.documentInfos {
  flex-grow: 1;
}

.title {
  font-size: 14px;
  margin: 3px 0 0 0;
}

.nature {
  font-size: 16px;
  font-weight: 500;
}

.actions {
  $gutter: 8px;

  display: flex;
  margin: 0 (-$gutter / 2);
  padding-left: 8px;

  .action {
    background-color: $pure-white;
    border: 1px solid $grey;
    border-radius: 6px;
    height: 40px;
    min-width: 0;
    margin: 0 ($gutter / 2);
    width: 50px;

    .actionIcon {
      height: 1em;
      font-size: 20px;
      margin: 0;
      width: 1em;
    }
  }
}
