@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

.loading {
  display: flex;
  flex: 1 0 auto;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: rgba($black2, 0.3);
}

.root {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  background-color: $grey-light;

  @include desktop {
    margin: 76px 76px 56px;
    padding: 70px;
  }

  @include not-desktop {
    padding: 20px;
  }
}

.title {
  margin: 38px 0 24px;
  font-weight: 500;
  font-size: 48px;
  line-height: 1;
  color: $pure-black;
}

.program {
  max-width: 460px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .name {
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.2;
    color: $primary-dark-blue;
  }

  .address {
    font-weight: 400;
    font-size: 16px;
    line-height: 1;
    color: $pure-black;
  }
}

.reports {
  display: flex;
  flex-direction: column;

  .report {
    transition: box-shadow 0.25s ease-in-out;

    &:hover {
      box-shadow: 0 4px 15px rgba(15, 73, 139, 0.11);
    }
  }

  @include desktop {
    $gutter: 60px;

    margin: ($gutter / 2) 70px 0;

    .report {
      margin: ($gutter / 2) 0;
    }
  }

  @include not-desktop {
    margin-top: 30px;

    .report {
      margin-top: 20px;
    }
  }

  .seeMore {
    position: relative;
    margin-left: 15px;
    border: 0;
    padding: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 1;
    background: none;
    color: $pure-black;

    @include desktop {
      align-self: flex-end;
    }

    @include not-desktop {
      align-self: center;
      margin-top: 20px;
      margin-bottom: 10px;
    }

    &::before {
      @extend %icomoon;

      content: map-get($icomoon, "plus");
      position: absolute;
      top: 50%;
      right: calc(100% + 5px);
      font-size: 10px;
      color: $primary-blue;
      transform: translate(0, -50%);
    }

    &::after {
      content: "";
      width: 100%;
      height: 1px;
      position: absolute;
      left: 0;
      bottom: -5px;
      background: $primary-blue;
    }
  }
}
