@import "../../assets/styles/1-settings";

.root {
  display: inline-flex;
  position: relative;

  .determinate {
    color: $primary-dark-blue;
  }

  .label {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
