@import "src/assets/styles/1-settings";

.phone {
  width: 100%;

  .root {
    font-family: $vinciFontName;
    font-size: 16px;

    .input {
      border: 1px solid $grey;
      border-radius: 6px;
      color: $primary-dark-blue;
      font-family: $vinciFontName;
      font-size: 16px;
      font-weight: 500;
      height: 48px;
      padding: 10px 16px 0 93px;
      width: 100%;
    }

    .label {
      cursor: text;
    }

    :global(.special-label) {
      background-color: transparent;
      color: $pure-grey;
      display: block;
      font-family: $vinciFontName;
      font-size: 16px;
      left: 0;
      line-height: 1;
      padding: 0;
      top: 0;
      transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      transform: translate(93px, 16px) scale(1);
      pointer-events: none;
    }

    :global(.flag-dropdown) {
      background-color: $grey-light;
      border: 1px solid $grey;
      border-right: 0;
      border-radius: 6px 0 0 6px;
      width: 77px;

      &:global(.open) {
        :global(.selected-flag) {
          background-color: $grey-light;
        }
      }
    }

    :global(.selected-flag) {
      align-items: center;
      display: flex;
      padding: 0 0 0 16px;
      width: 100%;

      &:focus,
      &:hover {
        background-color: $grey-light;
      }

      :global(.flag) {
        position: initial;
        margin: 0;
      }

      :global(.arrow) {
        background-color: transparent;
        border: 2px solid $primary-dark-blue;
        border-left: 0;
        border-top: 0;
        height: 10px;
        left: auto;
        margin: 0;
        position: absolute;
        right: 16px;
        transform: translateY(calc(-50% - 2px)) rotate(45deg);
        width: 10px;

        &:global(.up) {
          border: 2px solid $primary-dark-blue;
          border-left: 0;
          border-top: 0;
          transform: translateY(calc(-50% + 2px)) rotate(225deg);
        }
      }
    }

    .dropdown {
      border-radius: 4px;
      box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
        0px 3px 14px 2px rgba(0, 0, 0, 0.12);

      li {
        color: $pure-black;
        font-size: 16px;
      }
    }
  }

  .filled,
  .focus {
    :global(.special-label) {
      transform: translate(77px, 4px) scale(0.75);
    }
  }

  .focus {
    .input,
    :global(.flag-dropdown) {
      border-color: $primary-dark-blue;
    }

    :global(.special-label) {
      color: $primary-dark-blue;
    }
  }

  .error {
    .input,
    :global(.flag-dropdown) {
      border-color: $red;
    }

    .input {
      color: $red;
    }

    :global(.special-label) {
      color: $red;
    }
  }

  .disabled {
    .input {
      color: $grey;
    }
  }

  .helperTextRoot {
    font-family: $vinciFontName;
    font-size: 16px;
    margin: 4px 0 0;

    &.helperTextError {
      color: $red;
    }
  }
}
