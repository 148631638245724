@import "src/assets/styles/1-settings";

.rootLine {
  .line {
    margin-left: -30px;
    margin-right: -30px;
    width: auto;
    border-top-width: 3px;
    border-radius: 1px;
    border-color: $grey;
  }
}

.completed {
  .line {
    width: auto;
    border-top-width: 3px;
    border-radius: 1px;
    border-color: $green;
  }
}

.active {
  .line {
    width: auto;
    border-top-width: 3px;
    border-radius: 1px;
    border-color: $green;
  }
}

.alternativeLabel {
  top: 17px;
}
.icon {
  font-weight: 600;
}
.rootIcon {
  background-color: $grey;
  z-index: 1;
  color: $white;
  width: 38px;
  height: 38px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  border: 0;
}
.activeIcon {
  background-color: $primary-blue;
}
.completedIcon {
  background-color: $green;
}

.rootColorLabel {
  color: $grey;

  .activeColorLabel {
    color: $primary-blue;
  }
  .completedColorLabel {
    color: $green;
  }
}

.label {
  font-family: $vinciFontName;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
}
