@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.root {
  font-family: $vinciFontName;
  padding: 20px 25px;
}

.title {
  align-items: center;
  color: $primary-blue;
  display: flex;
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
}

.titleIcon {
  height: 18px;
  margin: 0 11px 0 0;
  width: 18px;
}

.list {
  margin: 10px 0 0;
}

.listItem {
  line-height: 1;
  padding: 7px 0;
}

.searchLink {
  align-items: center;
  background: none;
  border: 0;
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
  margin: 0;
  min-width: 0;
  padding: 0;
  text-decoration: none;
  width: 100%;

  &:hover {
    text-decoration: none;
  }
}

.searchTitle {
  color: $primary-dark-blue;
  flex-shrink: 0;
  font-weight: 500;
}

.searchFilters {
  color: $black;
  margin: 0 0 0 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
