@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

.root {
  border-top: 1px solid $grey-light;
  padding-bottom: 8px;
  background: $pure-white;
}

.main {
  padding: 15px 23px;
  background: $white;
}

.separator {
  &:not(:first-child) {
    border-top: 1px solid $grey-light;
  }
}

.stepsContainer {
  display: flex;
  overflow: auto;
}

.steps {
  flex: 0 0 auto;
  padding: 20px 23px 16px;
}

.tags {
  $gutter: 8px;

  display: flex;
  align-items: center;
  margin: (-$gutter / 2);

  > * {
    margin: ($gutter / 2);
  }
}

.lot {
  border: 0;
  padding: 0;
  background: none;
  font-weight: 500;
  font-size: 16px;
  text-decoration: underline;
  color: $primary-blue;
}

.programContainer {
  display: flex;
  align-items: center;
}

.program {
  text-align: left;

  @include desktopTablet {
    padding-left: 12px;
  }

  &:hover {
    text-decoration: underline;
    color: $primary-dark-blue;
  }

  .programName,
  .programLocation {
    display: block;
  }

  .programName {
    color: $pure-black;

    &:hover {
      color: $primary-dark-blue;
    }

    @include mobileTablet {
      text-decoration: none;
      color: $pure-black;
      font-weight: 500;
    }
  }

  .programLocation {
    text-transform: uppercase;
    color: $primary-blue;

    &:hover {
      color: $primary-dark-blue;
    }
  }
}

.programReports {
  flex: 0 0 auto;
  margin-left: 10px;
}

.meta {
  display: table;
  width: 100%;
  margin: 0;
  padding: 16px 0 20px;

  dt {
    display: table-cell;
    padding: 6px 12px 6px 24px;
  }

  dd {
    padding: 6px 24px 6px 12px;
    text-align: right;
  }
}

.metaLine {
  display: table-row;
}

.actions {
  border: solid $grey-light;
  border-width: 1px 0;
}
