@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.root {
  display: flex;
  flex-direction: column;
  padding: 0 0 14px;

  .buttonWrapper {
    margin: 8px 0;
  }

  .button {
    background-color: $white;
    border: 1px solid $grey-light;
    height: 35px;
    justify-content: start;
    letter-spacing: unset;
    margin: 0;
    min-width: 0;
    width: 100%;

    &:hover {
      background-color: $primary-blue-light;
      border: 1px solid $primary-dark-blue;
    }
  }

  .buttonLabel {
    color: $primary-dark-blue;
    font-family: $vinciFontName;
    font-size: 14px;
    font-weight: 500;
    text-transform: none;
  }

  .buttonSvgIcon {
    height: 18px;
    margin: 0 10px 0 0;
    width: 18px;
  }
}
