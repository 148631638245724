@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.confirmation {
  display: flex;
  flex-direction: column;
  text-align: center;

  .svgIcon {
    margin: 32px auto 16px;
    width: 60px;
    height: 60px;
  }

  h3 {
    margin: 0 0 12px;
    padding: 0;
    font-weight: 300;
    font-size: 40px;
    line-height: 50px;
    color: #353535;
    // Mobile
    @include mobileTablet {
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 22px;
    }
  }

  p {
    margin: 0;
    padding: 0;
    font-size: 18px;
    line-height: 26px;
    color: $pure-black;
    // Mobile
    @include mobileTablet {
      padding: 0 24px;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
    }
  }

  p:last-of-type {
    margin-bottom: 32px;
  }

  .highlight {
    font-weight: 500;
  }

  .link {
    text-decoration: underline;
    color: $primary-blue;
    &:visited {
      color: $primary-blue;
    }
  }

  .button {
    height: 45px;
    width: 159px;
    margin: 0 auto 32px;
    // Mobile
    @include mobileTablet {
      width: 100%;
    }
  }
}
