@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.root {
  background-color: $orange;
  border-radius: 6px;
  color: $pure-white;
  display: flex;
  margin: 0 auto 16px;
  width: 60%;
  position: relative;
  overflow: hidden;

  @include not-desktop {
    width: 100%;
  }

  @include mobile {
    flex-direction: column-reverse;
    margin: 0 24px 16px;
    width: calc(100% - 48px);
  }
}

.textContent {
  padding: 30px 0 30px 50px;
  width: 55%;

  &.fullWidth {
    padding: 30px 50px;
    width: 100%;
  }

  @include mobile {
    padding: 16px 24px;
    text-align: center;
    width: 100%;
  }
}

.rte {
  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    margin: 0 0 10px;
  }
}

.title,
.text {
  line-height: 1.2;
}

.title {
  font-size: 40px;
  margin: 0 0 15px;

  @include mobile {
    font-size: 36px;
  }
}

.text,
.text * {
  font-size: 16px;
}

.imageContent {
  height: 100%;
  overflow: hidden;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
  width: 45%;

  @include mobile {
    position: relative;
    right: auto;
    top: auto;
    width: 100%;
  }

  img {
    clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 0% 100%);
    height: 100%;
    width: auto;

    @include mobile {
      clip-path: none;
      height: auto;
      width: 100%;
    }
  }
}

.cta {
  margin: 40px 0 0;

  @include mobile {
    margin-top: 22px;
  }

  .ctaBtn {
    color: $primary-dark-blue;

    @include mobile {
      width: 100%;
    }

    &:hover {
      color: $primary-blue;
    }
  }
}
