@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

.about {
  display: flex;
  margin: 0 -12px;

  @include mobileTablet {
    flex-direction: column;
  }
}

.aboutCol {
  flex: 0 0 auto;
  padding: 0 12px;
  width: 50%;

  @include mobileTablet {
    padding: 12px 0;
    width: 100%;
  }
}

.content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  @include desktopTablet {
    flex: 1 0 50%;
    padding-right: 1.5rem;
  }

  @include mobileTablet {
    margin-bottom: 1rem;
  }
}

.title {
  font-size: 32px;
  font-weight: 400;
  margin: 0 0 24px 0;

  @include mobile {
    text-align: center;
  }
}

.text {
  $font-size: 18px;
  $line-height: 1.4;
  $max-lines: (
    md: 4,
    sm: 5,
  );

  font-size: $font-size;
  line-height: $line-height;

  p {
    margin: 0;
  }

  &.shortened {
    overflow: hidden;

    @include not-mobile {
      max-height: map-get($max-lines, md) * $font-size * $line-height;
    }

    @include mobile {
      max-height: map-get($max-lines, sm) * $font-size * $line-height;
    }
  }

  /* stylelint-disable value-no-vendor-prefix */

  @supports (display: -webkit-box) and (-webkit-box-orient: vertical) and
    (-webkit-line-clamp: var(--max-lines, 0)) {
    &.shortened {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;

      @include not-mobile {
        -webkit-line-clamp: map-get($max-lines, md);
      }

      @include mobile {
        -webkit-line-clamp: map-get($max-lines, sm);
      }
    }
  }

  /* stylelint-enable value-no-vendor-prefix */
}

.readMore {
  background: none;
  border: 0;
  color: $primary-blue;
  font-size: 16px;
  font-weight: 400;
  padding: 0;
  text-decoration: none;
  transition: color $transition-duration-fast;

  &:hover,
  &:focus,
  &:active {
    outline: none;
    text-decoration: underline;
  }
}

.medias {
  @include desktopTablet {
    flex: 1 0 50%;
    align-self: flex-start;
    padding-left: 1.5rem;
  }

  @include mobileTablet {
    margin-top: 1rem;
  }
}

.actionButtons {
  margin: 24px 0 0;
}

.actionButton {
  padding: 8px 0;
}

.infos {
  margin: 32px 0;
}

.certifications {
  margin: 50px 0 0;

  @include mobile {
    margin-top: 20px;
  }
}
