@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.root {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 24px 110px;

  @include mobile {
    padding: 16px 24px;
  }
}

.item {
  $line-height: 1.2;

  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  line-height: $line-height;
  padding: 8px 0;

  > * {
    flex: 0 1 auto;
  }

  @include not-mobile {
    &::before {
      $dot-size: 2px;
      $dot-space: 7px;

      content: "";
      display: block;
      order: 5;
      flex-grow: 1;
      min-width: 30px;
      height: $line-height * 1em;
      margin: 0 16px;
      background: linear-gradient(90deg, $pure-black $dot-size, transparent 0) repeat-x right
        (-$dot-space) bottom;
      background-size: ($dot-size + $dot-space) $dot-size;
    }
  }

  @include mobile {
    flex-direction: column;
  }
}

.text {
  font-size: 18px;
  line-height: 1.4;
  order: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rte {
  font-size: inherit;

  p {
    margin: 0;
  }
}

.button {
  background: none;
  border: 0;
  color: $pure-black;
  flex-shrink: 0;
  font-family: $vinciFontName;
  font-size: 18px;
  font-weight: 400;
  margin: 0;
  order: 10;
  outline: none;
  padding: 0;
  white-space: nowrap;

  @include mobile {
    margin: 10px auto 0;
  }

  &::after {
    background-color: $primary-blue;
    content: "";
    display: block;
    height: 1px;
    transition: 300ms;
    width: 100%;
  }

  &:hover::after {
    width: 36%;
  }
}
