@import "assets/styles/1-settings";

div {
  .resultItemWrapper {
    flex-direction: column;
    padding: 0;
    font-family: $vinciFontName;

    .lotNumber {
      position: absolute;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      padding-top: 15px;
      text-align: center;
    }

    .resultItemHeader {
      display: inline-flex;
      justify-content: space-between;
      padding: 0px 22px;
      padding-top: 15px;
      padding-bottom: 20px;
      background-color: $white;
      width: 100%;

      > * {
        font-size: 16px;
        line-height: 19px;
        color: $pure-black;
      }
    }
    .resultItemHeader2 {
      display: flex;
      justify-content: space-between;
      padding: 15px 22px;
      padding-top: 0px;
      background-color: $white;

      .showMapBtn {
        align-items: center;
        background-color: transparent;
        border: 0;
        color: $primary-blue;
        display: flex;
        font-size: 18px;
        justify-content: center;
        margin: 0;
        padding: 0;

        .showMapBtnIcon {
          color: $primary-blue;
          font-weight: bold;
        }
      }

      .showMapBtnIcon {
        color: $primary-blue;
        margin: 0 5px 0 0;
      }
    }

    .btnDetails {
      background-color: $primary-blue;
      color: $pure-white;
      text-transform: unset;
      font-family: $vinciFontName;
      height: 56px;
      max-height: 56px;
      border-radius: 0;
    }
    .btnDetailsPromo {
      background-color: $orange;
      color: $pure-white;
    }
    .btnDetailsLabel {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
    }
    .btnDetailsLabelPromo {
      display: flex;
      flex-direction: column;
    }
    .labelPromo {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      text-transform: uppercase;
    }

    .resultItemInfo {
      padding: 24px;
      background-color: $pure-white;
      margin: 0px;
      border-bottom: 1px solid $grey-light;
    }

    div.actionsBtn {
      display: flex;
      background-color: $pure-white;
      justify-content: center;
      border-bottom: 1px solid $grey-light;
      flex-wrap: wrap;
      margin: 0 -8px;
      padding: 8px 0;

      .actionBtnContainer {
        padding: 8px;

        .actionBtn {
          width: 42px;
          min-width: 0;
          height: 42px;
          margin: 0;
          padding: 5px 10px;
        }

        .svgIcon {
          height: 25px;
          width: 40px;
        }
      }
    }
  }

  .group {
    align-items: flex-start;
    display: flex;
  }
}

.line {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 4px;

  dt {
    display: inline-flex;
    font-size: 14px;
    line-height: 18px;
    color: $pure-black;

    .additionnalInfo {
      font-size: 14px;
      line-height: 18px;
      color: $pure-grey;
      margin-left: 5px;
    }
  }
}

.promoText * {
  margin: 0;
}
