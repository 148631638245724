@import "src/assets/styles/1-settings";

.resultItem {
  display: flex;
  padding: 0;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  height: 100%;

  &.isLotOpen {
    font-weight: 500;
    height: auto;
  }

  > a {
    color: inherit;
  }

  &.odd {
    background: $white;
  }

  &.even {
    background: $pure-white;
  }

  &:hover {
    background: $primary-blue-light;
  }
}

div.resultItemTablet:nth-child(2n + 1) {
  padding: 0;
  background-color: unset;
}

.cell {
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  padding: 10px 12px;
  text-align: center;

  &:empty::before {
    content: "-";
  }

  &.cellPromo {
    padding: 0.5px 0;
  }

  label {
    margin-left: 0;
    margin-right: 0;
  }
}

.alignLeft {
  text-align: left;
}

.alignCenter {
  text-align: center;
}

.alignRight {
  text-align: right;
}
