// Vinci
@include font-face($vinciFontName, "vinci_sans", "regular-webfont", 400, normal);
@include font-face($vinciFontName, "vinci_sans", "medium-webfont", 500, normal);
@include font-face($vinciFontName, "vinci_sans", "light-webfont", 300, normal);
@include font-face($vinciFontName, "vinci_sans", "light-italic", 300, italic);

// Vinci Sans Expanded
@include font-face-reduced($vinciExpandedFontName, 'vinci_sans_expanded', 'extra_light', 200, normal);
@include font-face-reduced($vinciExpandedFontName, 'vinci_sans_expanded', 'light', 300, normal);
@include font-face-reduced($vinciExpandedFontName, 'vinci_sans_expanded', 'regular', 400, normal);
@include font-face-reduced($vinciExpandedFontName, 'vinci_sans_expanded', 'medium', 500, normal);
@include font-face-reduced($vinciExpandedFontName, 'vinci_sans_expanded', 'bold', 700, normal);
@include font-face-reduced($vinciExpandedFontName, 'vinci_sans_expanded', 'black', 900, normal);

$cacheBuster: "ze8f4z";
// Not using the font-face mixin because we don't have all the required file formats
@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon/icomoon.#{$cacheBuster}.ttf") format("truetype"),
    url("../fonts/icomoon/icomoon.#{$cacheBuster}.woff") format("woff"),
    url("../fonts/icomoon/icomoon.#{$cacheBuster}.svg#icomoon") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}
