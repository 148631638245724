@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.root {
  :global {
    .vinci-youtube-player {
      @include mobileTablet {
        width: 100%;
      }
    }
  }

  > :first-child {
    margin-top: 0;
  }
}
