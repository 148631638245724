@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

.chip {
  display: flex;
  justify-content: center;
  max-width: 195px;
  height: 34px;
  align-items: center;
  border: 0;
  border-radius: 20px;
  padding: 0 16px;
  background-color: $primary-dark-blue;
  font-family: $vinciFontName;
  font-size: 16px;
  font-weight: 500;
  color: $pure-white;

  @include desktop-xl {
    max-width: 217px;
  }

  @include not-desktop {
    max-width: 217px;
  }
}

.label {
  margin-top: -2px;
  padding: 0;
}

.deleteIcon {
  color: $pure-white;
  font-size: 12px;
  height: auto;
  margin: 0 0 0 16px;
  padding: 0;
  width: auto;

  @include mobileTablet {
    font-size: 10px;
  }
}
