@import "assets/styles/1-settings";
@import "assets/styles/2-tools";
@import "assets/styles/variables";

.sticky {
  z-index: 100;

  &.active {
    .menuProgramInfos {
      display: flex;
    }
  }

  .menu {
    border-bottom: 2px solid $grey-light;
  }
}

.menu {
  align-items: center;
  background-color: $pure-white;
  border-bottom: 1px solid $grey-light;
  box-sizing: content-box;
  display: flex;
  height: $programMenuAnchorsHeight;
  position: relative;

  @include desktopTablet {
    padding-right: 68px;
  }
}

.menuProgramInfos {
  color: $primary-dark-blue;
  display: none;
  flex-direction: column;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  margin: 0 0 0 24px;
  max-width: 260px;
  text-transform: uppercase;
}

.menuProgramInfosLocation {
  font-weight: 400;
}

.menuAnchors {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 10px 0 14px;
  position: relative;

  @include not-desktop {
    padding: 10px 0 14px;
  }

  .menuAnchorsFlexContainer {
    display: block;
    text-align: center;
  }

  .menuAnchorsScrollButtons {
    background-color: rgba(255, 255, 255, 0.85);
    bottom: 0;
    color: $primary-dark-blue;
    left: 0;
    position: absolute;
    top: 0;
    width: 28px;
    z-index: 10;

    &:last-of-type {
      left: auto;
      right: 0;
    }

    svg {
      height: 30px;
      width: auto;
    }
  }

  .menuAnchorsIndicator {
    background-color: $primary-dark-blue;
  }

  .menuAnchorsItem {
    color: $primary-dark-blue;
    font-family: $vinciFontName;
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    min-height: 0;
    min-width: unset;
    max-width: none;
    opacity: unset;
    padding: 0;
    position: relative;
    text-transform: none;

    .menuAnchorsItemIcon,
    .menuAnchorsItemIconHover {
      height: 16px;
      width: auto;

      &.size1 {
        height: 12px;
      }

      &.size2 {
        height: 18px;
      }

      &.size3 {
        height: 20px;
      }
    }

    .menuAnchorsItemIconHover {
      display: none;
    }

    &:hover {
      .menuAnchorsItemIconWrapper {
        background-color: $primary-dark-blue;
      }

      .menuAnchorsItemIcon {
        display: none;
      }

      .menuAnchorsItemIconHover {
        display: block;
      }
    }
  }

  .menuAnchorsItemIconWrapper {
    align-items: center;
    border: 1px solid rgba(46, 117, 216, 0.1);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    height: 34px;
    margin: 0 !important;
    width: 34px;
  }

  .menuAnchorsItemLabel {
    line-height: 1;
    white-space: nowrap;
  }

  .menuAnchorsItemNb {
    align-items: center;
    background-color: $primary-blue;
    border-radius: 50%;
    color: $pure-white;
    display: flex;
    font-size: 10px;
    font-weight: 700;
    height: 14px;
    justify-content: center;
    line-height: 1;
    position: absolute;
    right: 0;
    top: 0;
    width: 14px;
  }
}

.menuAnchorLink {
  padding: 0 20px;

  @include not-desktop {
    padding: 0 15px;
  }

  &:global(.active) {
    .menuAnchorsItemIconWrapper {
      background-color: $primary-dark-blue;
    }

    .menuAnchorsItemIcon {
      display: none;
    }

    .menuAnchorsItemIconHover {
      display: block;
    }
  }
}

.buttonReturnTopWrapper {
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
}

.buttonReturnTop {
  align-items: center;
  background-color: $pure-white;
  border: 1px solid rgba(46, 117, 216, 0.1);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  height: 40px;
  margin: 0;
  padding: 0;
  width: 40px;

  .buttonReturnTopIcon {
    color: $primary-blue;
    transform: rotate(180deg);
  }

  &:hover {
    background-color: $primary-dark-blue;

    .buttonReturnTopIcon {
      color: $pure-white;
    }
  }
}
