@import "assets/styles/1-settings";
@import "assets/styles/2-tools";
@import "assets/styles/variables";

.root {
  background: $blue-dark;

  @include not-mobile {
    display: flex;
    gap: 30px;
    padding: 70px $home-margin-h 40px;
  }

  @include mobile {
    flex-direction: column;
    padding: 30px 20px 35px;
  }
}

.content {
  max-width: 1000px;

  @include not-mobile {
    flex: 1 1 auto;
    align-self: flex-start;
    width: 50%;
  }
}

.logos {
  display: flex;
  align-items: center;

  @include not-mobile {
    gap: 50px;
    margin-bottom: 90px;

    .vip {
      width: 220px;
      height: auto;
    }

    .vi {
      width: auto;
      height: 35px;
    }
  }

  @include mobile {
    justify-content: space-around;
    margin-bottom: 32px;

    .vip {
      width: 158px;
      height: auto;
    }

    .vi {
      width: auto;
      height: 30px;
    }
  }
}

.title,
.text {
  > * {
    font-size: 1em;
    font-weight: inherit;
  }
}

.title {
  line-height: 1.2;
  color: $pure-white;

  @include not-mobile {
    margin-bottom: 50px;
    font-size: 48px;
  }

  @include mobile {
    margin-bottom: 28px;
    font-size: 26px;
    text-align: center;
  }
}

.text {
  font-weight: 300;
  line-height: 1.2;
  color: $pure-white;

  @include not-mobile {
    margin-bottom: 32px;
    font-size: 28px;
  }

  @include mobile {
    margin-bottom: 20px;
    font-size: 20px;
    text-align: center;
  }
}

.buttons {
  display: flex;

  .button {
    height: 45px;

    @include mobile {
      font-size: 15px;
    }
  }

  @include not-mobile {
    gap: 25px;
  }

  @include mobile {
    flex-direction: column;
    gap: 15px;
  }
}

.images {
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 0;
  margin-left: auto;

  @include not-mobile {
    align-self: center;
    flex: 1 1 auto;
    width: 50%;

    .dots {
      left: 120px;
      right: 0;
      bottom: 10px;
    }
  }

  @include desktop-xl {
    max-width: 610px;
  }

  @include mobile {
    margin-top: 45px;
  }

  .container {
    width: 100%;
    position: relative;
    z-index: 0;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 10;
      border: 3px solid;
      color: $primary-blue;
    }

    @include not-mobile {
      padding-left: 120px;
      padding-bottom: 40px;

      &::before {
        width: 170px;
        height: 170px;
        border-radius: 0 50px;
      }
    }

    @include mobile {
      width: 100%;
      padding-left: 45px;
      padding-bottom: 20px;

      &::before {
        width: 95px;
        height: 95px;
        border-radius: 0 25px;
      }

      &.slider {
        padding-bottom: 40px;

        &::before {
          bottom: 20px;
        }
      }
    }
  }

  .dots {
    position: absolute;

    @include not-mobile {
      left: 120px;
      right: 0;
      bottom: 10px;
    }

    @include mobile {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  :global {
    .slick-list {
      @include not-mobile {
        border-radius: 150px 0;
      }

      @include mobile {
        border-radius: 80px 0;
      }
    }

    .slick-track {
      display: flex;
      align-items: flex-end;
    }
  }

  .image {
    width: 100%;
  }
}
