@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.confirmation {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: $pure-white;
  margin: 20px 60px;

  @include not-desktop {
    margin: 40px 40px;
  }

  @include mobileTablet {
    margin: 0;
    padding: 0 18px;
  }

  &.confirmationGenerateContract,
  &.confirmationSign {
    padding-top: 30px;
  }

  .svgIcon {
    margin: 30px auto 24px;
    width: 60px;
    height: 60px;
  }

  h3 {
    margin: 0 0 16px;
    padding: 0;
    font-weight: 300;
    font-size: 40px;
    line-height: 50px;
    color: #353535;

    @include mobileTablet {
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 22px;
      margin: 0 0 25px;
      padding: 0 24px;
    }
  }

  p {
    margin: 0 0 10px;
    padding: 0;
    font-size: 18px;
    line-height: 26px;
    color: $pure-black;

    @include mobileTablet {
      padding: 0 24px;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
    }
  }

  p:last-of-type {
    margin-bottom: 29px;
  }

  .highlight {
    font-weight: 500;
  }

  .link {
    text-decoration: underline;
    color: $primary-blue;
    &:visited {
      color: $primary-blue;
    }
  }

  .button {
    margin: 0 auto 16px;

    > * {
      height: 45px;
      width: 303px;

      &:first-child {
        margin-bottom: 16px;
      }

      @include mobileTablet {
        width: 100%;
      }
    }
  }

  div.button {
    margin: 0 auto 0;
  }

  .loader {
    color: $pure-white;
  }

  .steps {
    margin: 23px 0 33px;

    @include mobile {
      margin: 20px 0 30px;
    }
  }
}

div.modalPaper {
  @include desktopTablet {
    max-width: 800px;
    margin-top: 330px;
  }
}
