@import "assets/styles/1-settings";

.root {
  .dots {
    position: absolute;
  }
}

.lazy {
  height: 100%;
}

.slider {
  height: 100%;
  position: relative;
  z-index: 0;

  :global {
    .slick-list,
    .slick-track,
    .slick-slide > * {
      height: 100%;
    }

    .slick-arrow {
      width: 35px;
      height: 35px;
      z-index: 20;
      border-radius: 100%;
      background: $white;
      font-size: inherit;
      color: $pure-black;
      transition: $transition-duration-fast ease-in-out;
      transition-property: background-color, color;

      &:hover {
        background: $primary-dark-blue;
        color: $white;
      }

      &::before {
        content: none;
      }
    }

    .slick-prev {
      left: 10px;
      transform: translate(0, -50%) rotate(90deg);
    }

    .slick-next {
      right: 10px;
      transform: translate(0, -50%) rotate(-90deg);
    }
  }
}

.dots {
  width: 50px;
  right: 0;
  left: 0;
  bottom: 20px;
  z-index: 20;
  margin: 0 auto;
  pointer-events: none;

  .dot {
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background: $pure-white;
  }

  &:global {
    &.slick-initialized .slick-slide {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  :global {
    .slick-slide {
      width: 10px;
      height: 10px;

      div:not([class]) {
        display: block !important;
      }
    }
  }
}
