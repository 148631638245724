@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

.text {
  padding: 0 24px;
  font-size: 16px;
  margin: 0;
}

.contact {
  margin: 0 24px;
  border-bottom: 1px solid $grey-light;

  &:last-child {
    border: 0;
  }
}

.panelHeader {
  font-family: $vinciExpanded;
}
