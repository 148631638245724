@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 14px 20px;
  font-weight: 500;
  text-align: center;
  color: $primary-dark-blue;

  svg path {
    fill: currentColor;
  }

  p {
    margin: 0;

    @include desktopTablet {
      &.smallP {
        font-size: 14px;
      }

      &.bigP {
        margin-bottom: 0.5px;
        font-size: 16px;
      }
    }

    @include mobileTablet {
      &.smallP {
        font-size: 18px;
      }

      &.bigP {
        font-size: 23px;
      }
    }
  }

  > .icon {
    @include desktopTablet {
      width: 32px;
      height: 32px;
      margin-bottom: 14px;
    }

    @include mobileTablet {
      width: 48px;
      height: 48px;
      margin-bottom: 26px;
    }
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 35px;
    border-radius: 35px;
    background: #deefff;
    font-weight: 500;
    font-size: 14px;
    color: $blue;
    transition: background-color $transition-duration-fast ease-in-out;

    .icon {
      width: 1em;
      height: 1em;
      margin-right: 5px;
      font-size: 20px;
    }

    @include desktopTablet {
      margin-top: 18px;

      &:hover {
        background-color: #cce6ff;

        .icon:global {
          animation: vibrate 1.25s infinite ease-in-out;
        }
      }
    }

    @include mobileTablet {
      margin-top: 25px;

      &:focus {
        background-color: #cce6ff;
      }

      .icon:global {
        animation: vibrate-spaced 6s 3s infinite ease-in-out;
      }
    }
  }
}
