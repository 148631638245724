@import "assets/styles/1-settings";
@import "assets/styles/variables";

.root {
  position: relative;
  background: $text;
  color: $white;

  a,
  button {
    padding: 0;
    border: 0;
    outline: none;
    background: none;
    color: inherit;
  }
}

.top {
  display: flex;
  padding-bottom: 30px;
  line-height: 1.4;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    > li {
      position: relative;
    }
  }
}

.title {
  font-weight: 500;
  font-size: 14px;
  margin: 0;

  &.rte {
    p, h1, h2, h3, h4, h5, h6 {
      margin: 0;
      line-height: 1;
    }
  }
}

.bottom {
  font-size: 12px;
}

.rte {
  * {
    font-size: inherit;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 500;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    margin: 0 0 16px;

    @media (max-width: $breakpoint-sm-max) {
      margin-bottom: 8px;
    }
  }
}

// Desktop & landscape Tablet
@media (min-width: $breakpoint-md-min) {
  .root {
    padding: 50px 80px 0;
  }

  .authenticated {
    width: calc(100%);
  }

  .top {
    border-bottom: 1px solid $footer-border-color;
  }

  .about {
    width: 40%;

    .picto {
      width: 78px;
      height: auto;
      margin-right: 25px;
    }

    .content {
      flex: 1 1 0;
      max-width: 270px;
    }
  }

  .columns {
    display: flex;
    flex: 1;
    justify-content: space-between;
  }

  .logo {
    display: none;
  }

  .bottom {
    display: flex;
    align-items: center;
    height: 75px;
    color: rgba($white, 0.49);
  }

  .version {
    margin-left: 50px;
  }

  .links {
    display: flex;
    align-items: center;
    margin-left: auto;
    list-style: none;

    > * {
      margin-left: 50px;
    }
  }
}

// Mobile & portrait Tablet
@media (max-width: $breakpoint-sm-max) {
  .top {
    flex-direction: column;
    padding: 23px 20px 0;
  }

  .logo {
    display: block;
    width: 170px;
    height: auto;
    margin: 30px auto;
  }

  .bottom {
    padding: 0 20px 35px;
    text-align: center;
    color: transparentize($pure-white, 0.5);

  }

  .links {
    display: contents;
    list-style: none;

    li {
      margin-left: 0;
    }
  }

  .copyright, .version, .links li {
    display: inline-block;

    &:not(:first-child)::before {
      content: "-";
      padding-right: 0.5ch;
      padding-left: 0.5ch;
    }
  }
}
