.root.v4 {
  height: 1.9em;
  width: 1.9em;
}

.checked.v4 {
  &::before {
    height: 1.6rem;
    width: 1.6rem;
  }
}

.label.v4 {
  font-size: 100% !important; // stylelint-disable-line declaration-no-important
  padding: 0.8rem;
}
