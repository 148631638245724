@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

$listGutter: (
  md: 24px,
  sm: 8px,
);
$headerBackground: $white;

.heading {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;

  @include not-mobile {
    height: 40px;
  }

  @include mobile {
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 0;
  }
}

.selectAll {
  align-self: center;
  background: none;
  border: 0;
  color: $primary-dark-blue;
  font-size: 15px;
  font-weight: 400;
  margin: 0 8px 0 0;
  padding: 0;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }

  @include mobileTablet {
    align-self: flex-end;
    margin-bottom: 10px;
  }
}

.actions {
  $actionsGutter: 8px;

  display: flex;
  margin: -$actionsGutter / 2;

  @include not-mobile {
    padding-left: 20px;
  }

  @include mobile {
    flex-wrap: wrap;
    margin-bottom: 16px;
    width: calc(100% + #{$actionsGutter});
  }

  > * {
    margin: $actionsGutter / 2;

    @include mobile {
      width: calc(50% - #{$actionsGutter});
    }
  }

  .action {
    background-color: transparent;
    border-width: 1px;
    color: $primary-dark-blue;
    font-family: $vinciFontName;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: unset;
    height: 40px;
    max-height: none;
    max-width: none;
    min-width: auto;
    padding: 0 24px;
    text-transform: none;

    @include mobileTablet {
      height: 42px;
    }

    @include mobile {
      flex: 1 1 auto;
      padding: 0 12px;
    }

    &:hover {
      background-color: $primary-blue-light;
      border: 1px solid rgba($primary-blue, 0.4);
    }
  }

  .actionIcon {
    height: 20px;
    margin-right: 8px;
    width: 20px;
  }
}

.list {
  display: flex;

  @include tablet-landscape {
    flex-wrap: wrap;
  }

  @include desktopTablet {
    align-items: flex-start;
    margin: 0 (-1 * map-get($listGutter, md) / 2);
  }

  @include mobileTablet {
    flex-direction: column;
    margin: (-1 * map-get($listGutter, sm) / 2) 0;
  }
}

.col {
  flex: 1 0 0;

  @include tablet-landscape {
    flex: 0 0 auto;
    width: calc(50% - #{map-get($listGutter, md)});
  }

  @include not-mobile {
    margin: 0 (map-get($listGutter, md) / 2);
    border: 1px solid $headerBackground;
    border-radius: 4px;
  }

  @include mobileTablet {
    margin: (map-get($listGutter, sm) / 2) 0;
  }
}

.colHeader {
  align-items: center;
  color: $pure-black;
  display: flex;
  height: 90px;
  font-size: 26px;
  font-weight: 500;
  line-height: 1;
  padding: 10px 24px 10px 35px;

  @include mobile {
    padding: 10px 16px;
  }
}

.colIcon {
  font-size: 20px;
  margin-right: 35px;

  > * {
    height: 1em;
    width: 1em;
  }
}

.colBody {
  display: flex;
  flex-direction: column;
}

.document {
  border: 1px solid $grey-light;
  border-radius: 4px;
}

.labelCircularProgress {
  font-size: 10px;
}
