@import "src/assets/styles/1-settings";

.textFieldRoot {
  height: 100%;

  .inputRoot {
    border-radius: 28px;
    background: $pure-white;
    min-height: 48px;

    &:active {
      border: 1px solid $grey;
    }

    font-family: $vinciFontName;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: $primary-dark-blue;
  }

  .input {
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      font-family: $vinciFontName;
      font-size: 16px;
      line-height: 19px;
      font-weight: normal;

      opacity: 1;
      color: $pure-black;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      font-family: $vinciFontName;
      font-size: 16px;
      line-height: 19px;
      font-weight: normal;

      opacity: 1;
      color: $pure-black;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      font-family: $vinciFontName;
      font-size: 16px;
      line-height: 19px;
      font-weight: normal;

      opacity: 1;
      color: $pure-black;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      font-family: $vinciFontName;
      font-size: 16px;
      line-height: 19px;
      font-weight: normal;

      opacity: 1;
      color: $pure-black;
    }
  }

  .notchedOutline {
    border: 1px solid $grey !important;
  }

  .inputAdornment {
    padding: 15px 10px 15px 10px;

    .svgIcon {
      width: 18px;
      height: 18px;
    }
  }
}
