@import "assets/styles/1-settings";
@import "assets/styles/variables";

.listWrapper {
  width: 100%;
}

.lotsListHeader {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: sticky;
  width: 100%;
  z-index: 10;

  &.withTop {
    top: $programMenuAnchorsHeight;
  }
}

.listHeader {
  display: flex;
  padding-right: 10px;
  background: $grey-light;

  &.header-sticky {
    top: $programMenuAnchorsHeight;

    &--large {
      top: 2 * $header-height;
    }
  }

  &.borderRadius {
    border-radius: 8px 8px 0 0;
  }
}

.lotsListWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.table {
  :global {
    .ReactVirtualized__Table__headerRow {
      display: flex;
    }

    .ReactVirtualized__Table__row {
      display: flex;
    }
  }
}

.scroll {
  :global {
    .os-host {
      padding: 0;
    }
  }
}
