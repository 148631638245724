@import "src/assets/styles/1-settings";

.button {
  background: none;
  border: none;
  color: $primary-dark-blue;
  font-family: $vinciFontName;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  padding: 0 20px 0 0;
  position: relative;

  &:after {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid $primary-dark-blue;
    content: "";
    height: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
  }

  &.open {
    color: $primary-blue;

    &:after {
      border-top-color: $primary-blue;
    }
  }
}

.popover {
  position: absolute !important;
  z-index: 500 !important;
  div.paper {
    border: 1px solid $grey;
    border-radius: 22px;
    box-shadow: 0 5px 21px 0 rgba(15, 73, 139, 0.1);
    margin: 11px 0 0;
  }

  .listItem {
    display: flex;
    justify-content: center;
    padding: 8px 20px;

    &:hover {
      background: none;
      cursor: default;
    }

    :global(.MuiTouchRipple-root) {
      display: none;
    }
  }
}
