@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.buttonDropdownContainer {
  position: relative;

  .button {
    background-color: $pure-white;
    border-color: $grey-2;
    height: 35px;
    justify-content: start;
    letter-spacing: unset;
    margin: 0;
    min-width: 0;
    width: 100%;

    &:hover {
      background-color: $primary-blue-light;
      border-color: $primary-dark-blue;
    }
  }
}

.active {
  border-radius: 0 0 4px 4px;
}

.isActive {
  .buttonLabel {
    color: $pure-white;
  }
}

.dropdownContent {
  display: none;
  background: $white;
  border: 1.5px solid $grey-light;
  box-sizing: border-box;
  box-shadow: 4px 0 15px rgba(15, 73, 139, 0.104988);
  border-radius: 0 0 4px 4px;

  position: absolute;
  z-index: 1;
  width: 100%;

  &.dropdownOnTop {
    bottom: 100%;
    border-radius: 4px 4px 0 0;
  }

  .button {
    font-family: $vinciFontName;
    border: none;
    background: none;
    margin: 0;
    padding: 0 0 0 14px;
    color: $primary-blue;
    text-transform: unset;
    text-decoration: none;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;

    display: flex;
    align-items: center;
    cursor: pointer;

    .icon {
      margin-right: 12px;
    }
  }
}

.show {
  display: inherit;
}

.buttonLabel {
  color: $primary-dark-blue;
  font-family: $vinciFontName;
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
}

.buttonSvgIcon {
  height: 18px;
  margin: 0 10px 0 0;
  width: 18px;
}

.buttonIcon {
  color: $white;
  font-size: 16px;
}

.buttonDropdownIcon {
  font-size: 8px;
  margin-right: 0;
}
