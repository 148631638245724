@import "../../../common/assets/styles/main";
@import "KelQuartierSettings.module";
@import "KelQuartierV4.module";
@import "KelQuartierVI3P.module";

.root {
  @include revertContainerPadding();

  position: relative;

  @include not-desktop {
    height: $program-map-height + $program-map-title-height-sm;
  }

  @include mobileTablet {
    height: auto;
  }
}

.container {
  @include containerPadding;
}

.poisContainer {
  max-width: $page-max-width;
  margin-right: auto;
  margin-left: auto;
  position: relative;

  @include not-desktop {
    @include revertContainerPadding();
  }
}

.drawer {
  background: $white;
  width: 100%;
}

.poi {
  position: absolute;
  bottom: 0;
  box-shadow: $default-box-shadow;
  transition: transform $transition-duration-fast,
    bottom $transition-duration-fast $transition-duration-fast;
  opacity: 1;
  background: $white;
  border-radius: $block-border-radius;

  @include not-desktop {
    top: -($program-map-height + $program-map-title-height-sm);
    right: $page-margin-x-sm;
  }

  @include mobileTablet {
    display: none;
  }
}

.poiClosed {
  transition: bottom $transition-duration-fast,
    transform $transition-duration-fast $transition-duration-fast;
  transform: scale(0);

  @include desktop {
    bottom: $program-map-height + $program-map-title-height-lg;
  }

  @include not-desktop {
    bottom: $program-map-height + $program-map-title-height-sm;
  }
}

.actionXL {
  margin-bottom: 30px;
  position: absolute;
  @include mobileTablet {
    display: none;
  }
}

.actionSM {
  position: absolute;

  @include desktopTablet {
    display: none;
  }

  @include mobileTablet {
    left: 50%;
    transform: translateX(-50%);
  }
}

.button {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  max-width: 100%;
  padding: 0 1.6rem;
  border: 0;
  border-radius: 3.2rem;
  color: $text-light;
  cursor: pointer;
  background-color: $white;
  box-shadow: $default-box-shadow;
  transition: $transition-duration-fast;
  transition-property: background-color, box-shadow;

  &:hover,
  &:focus,
  &:active {
    outline: none;
    background-color: $neutral-lighter;
  }

  @include mobileTablet {
    margin-top: 0;
  }
}

.buttonIcon {
  margin-right: 1rem;
}

.buttonOpen {
  background-color: $neutral-white;
  box-shadow: 0 0 0 0 rgba($default-shadow-color, 0);

  &:hover,
  &:focus,
  &:active {
    background-color: $neutral-lighter;
  }
}
