@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.drawerRoot {
  @include desktopTablet {
    z-index: 2000 !important;
  }

  .backdropRoot {
    background-color: rgba(5, 34, 65, 0.6);

    @include mobile {
      display: none;
    }
  }

  .paperRoot {
    background: none;
    height: 100%;
    max-width: 1024px;
    padding: $appbar-height-desktop 0 40px 0;
    width: 100%;

    @include mobileTablet {
      padding: $appbar-height-mobile 0 0 0;
    }
  }

  .drawerContainer {
    background: $pure-white;
    border-radius: 4px 0 0 4px;
    box-sizing: border-box;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.04);
    height: 100%;
    overflow: hidden;
    width: 100%;

    @include mobile {
      border: 0;
      border-top: 1px solid $grey;
      border-radius: 0;
    }
  }
}
