@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.root {
  display: flex;
}

// MODIFIERS
.standard {
  flex-direction: column;
}

.columns {
  @include not-mobile {
    $gutter-x: 80px;
    $gutter-y: 36px;

    flex-flow: row wrap;
    margin: (-$gutter-y / 2) (-$gutter-x / 2);

    .child {
      flex: 1 0 50%;
      padding: ($gutter-y / 2) ($gutter-x / 2);
    }
  }

  @include mobile {
    flex-direction: column;

    .child {
      &:not(:first-child) {
        margin-top: 50px;
      }
    }
  }
}

.vertical {
  flex-direction: column;

  .child {
    &:not(:first-child) {
      margin-top: 20px;
    }
  }
}
