@import "src/assets/styles/1-settings";

div {
  .resultItemWrapper {
    flex-direction: column;
    padding: 0;
    font-family: $vinciFontName;

    a {
      color: inherit;
    }

    .resultItemHeader {
      $promoWidth: 30px;

      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 24px;
      background-color: $grey-light;
      width: 100%;
      min-height: 85px;
      position: relative;

      .headerCell {
        font-family: $vinciFontName;
        margin-right: 8px;
        font-size: 16px;
        line-height: 19px;
        color: $pure-black;
        position: relative;
        span:first-child {
          font-weight: 500;
        }
      }

      &.withPromo {
        padding-right: 40px;
        padding-left: $promoWidth + 20px;
      }

      .promo {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: $promoWidth;
      }

      .leftContent,
      .rightContent {
        display: inline-flex;
        align-items: center;
      }

      .leftContent {
        a:first-of-type {
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
        }
      }

      .rightContent {
        .showMapBtn {
          align-items: center;
          background-color: transparent;
          border: 0;
          color: $primary-blue;
          display: flex;
          font-size: 18px;
          justify-content: center;
          margin: 0;
          padding: 0;
          margin-right: 30px;

          .showMapBtnIcon {
            color: $primary-blue;
            font-weight: bold;
          }
        }
      }

      .btnDetailsContainer {
        width: 30px;
        height: 30px;
        transform: rotate(-90deg);
        margin-top: -3px;
        .rootBtnDetails {
          max-width: 30px;
          min-width: 30px;

          background: $pure-white;

          border: 1px solid $grey-light;
          box-sizing: border-box;
          border-radius: 8px;

          &:hover {
            background: $pure-white;
          }
        }
      }
    }

    .btnDetails {
      background-color: $primary-blue;
      text-transform: unset;
      font-family: $vinciFontName;
      height: 56px;
      max-height: 56px;
      border-radius: 0;
    }
    .btnDetailsLabel {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $pure-white;
    }

    .resultItemInfo {
      padding: 24px;
      background-color: $pure-white;
      margin: 0px;
      border: 1px solid $grey-light;
      border-top: none;

      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 10px;

      text-align: left;
      div {
        width: 100%;
      }

      > div:first-child {
        grid-column: 1 / 3;
        grid-row: 1;
      }
      > div:nth-child(2) {
        grid-column: 1 / 3;
        grid-row: 2;
      }
      > div:nth-child(3) {
        grid-column: 1 / 3;
        grid-row: 3;
      }
      > div:nth-child(4) {
        grid-column: 1 / 3;
        grid-row: 4;
      }
      > div:nth-child(5) {
        grid-column: 2 / 3;
        grid-row: 1;
      }
      > div:nth-child(6) {
        grid-column: 2 / 3;
        grid-row: 2;
      }
      > div:nth-child(7) {
        grid-column: 3 / 3;
        grid-row: 1;
      }
      > div:nth-child(8) {
        grid-column: 3 / 3;
        grid-row: 2;
      }
      > div:nth-child(9) {
        grid-column: 3 / 3;
        grid-row: 3;
      }
      > div:nth-child(10) {
        grid-column: 3 / 3;
        grid-row: 4;
      }
      > div:nth-child(n + 10) {
        grid-column: 3 / 3;
      }

      .dot {
        display: inline-flex;
        width: 4px;
        height: 4px;
        background-color: $grey;
        border-radius: 50%;
        margin-right: 4px;
        margin-bottom: 4px;
      }

      dt {
        display: inline-flex;
        font-size: 14px;
        line-height: 18px;
        color: $pure-black;

        .additionnalInfo {
          font-size: 14px;
          line-height: 18px;
          color: $pure-grey;
          margin-left: 5px;
        }
      }
      dd {
        display: inline-flex;
        margin-left: 4px;
        font-weight: 500;
      }
    }

    .actionsBtn {
      display: inline-flex;
      background-color: $pure-white;
      justify-content: left;
      width: 100%;
      border: 1px solid $grey-light;
      border-top: none;
      padding-left: 16px;

      .actionBtnContainer {
        margin: 16px 4px;
        .actionBtn {
          max-width: 50px;
          min-width: 50px;
          max-height: 42px;
          max-width: 42px;
        }
      }
    }
  }
}
