@import "assets/styles/1-settings";
@import "assets/styles/2-tools";
@import "assets/styles/variables";

$top-margin: 24px;
$right-margin: (
  xl: 75px,
  md: 40px,
  sm: 26px,
);
$bottom-margin: (
  xl: 50px,
  md: 40px,
  sm: 24px,
);
$left-margin: (
  xl: 14px,
  md: 40px,
  sm: 24px,
);
$tva-width: 352px;

.root {
  position: relative;
}

.backgroundContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: relative;
  z-index: 0;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.55) 100%);
    pointer-events: none;
  }

  .backgroundImg {
    display: block;
    position: relative;
    z-index: 0;
  }

  @include not-mobile {
    //height: 588px;
    height: calc(100vh - #{$header-height} - 80px);
    max-height: 588px;

    .backgroundImg {
      width: 100%;
      height: auto;

      @supports (object-fit: cover) {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
      }
    }
  }

  @include mobile {
    align-items: center;
    height: 350px;

    .backgroundImg {
      width: auto;
      height: 100%;
    }
  }
}

.bottom {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  z-index: 10;
  color: $pure-white;

  @include desktop {
    bottom: map-get($bottom-margin, xl);
    right: map-get($left-margin, xl);
    left: map-get($left-margin, xl);
  }

  @include tablet {
    bottom: map-get($bottom-margin, md);
    right: map-get($left-margin, md);
    left: map-get($left-margin, md);
  }

  @include mobile {
    flex-direction: column;
    right: map-get($right-margin, sm);
    bottom: $program-booster-tab-height;
    left: map-get($left-margin, sm);
    padding-bottom: map-get($bottom-margin, sm);
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0 0 auto;

  @include desktop {
    min-width: 650px;
    width: calc(65% - #{$tva-width});
    padding-right: 20px;
  }

  @include tablet {
    width: calc(100% - #{$tva-width});
    padding-right: 20px;
  }

  @include mobile {
    width: 100%;
  }
}

.label {
  margin-bottom: 12px;
}

.programTitle {
  margin: 0 0 22px;
  font-weight: 500;
  font-size: 48px;
  line-height: 1;

  @include mobileTablet {
    font-size: 28px;
    margin: 0 0 6px;
  }
}

.location {
  font-weight: 500;
  font-size: 20px;
  line-height: 1;
  text-transform: uppercase;

  @include mobileTablet {
    font-size: 14px;
  }
}

.actions {
  position: absolute;
  top: $top-margin;
  z-index: 20;

  @include desktop {
    right: map-get($right-margin, xl);
  }

  @include tablet {
    right: map-get($right-margin, md);
  }

  @include mobile {
    right: map-get($right-margin, sm);
    left: map-get($left-margin, sm);
  }
}

.booster {
  @include not-mobile {
    width: $tva-width;
    z-index: 15;
  }

  @include mobile {
    width: calc(100% - 32px);
    position: relative;
    z-index: 10;
    margin: -$program-booster-tab-height auto 0;
  }
}

.taxes {
  .pastille {
    .pastilleChip {
      border-radius: 20px;
      height: auto;
    }

    .pastilleLabel {
      font-size: 20px;
      font-weight: 700;
      line-height: 1;
      padding: 6px 13px 8px;

      @include mobileTablet {
        font-size: 10px;
        padding: 6px 10px;
      }
    }
  }
}

.imgControlledPrice {
  height: 35px;
}

.honoboost,
.controlled {
  width: 50px;
  height: 50px;
}
