@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

.root {
  display: flex;
  flex-wrap: wrap;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  max-height: 100%;
}

.fullListLot {
  @include desktopTablet {
    max-height: calc(100vh - 320px) !important;
    height: calc(100vh - 320px) !important;
  }
}

.lotsList {
  // Desktop & landscape Tablet
  @include desktopTablet {
    padding: 0 16px;
  }

  @include desktopTablet {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0;

    &.isExpanded {
      padding-bottom: 5px;
    }
  }

  .sortContainer {
    @include mobileTablet {
      display: flex;
      justify-content: flex-end;
      padding: 15px 32px;
      padding-top: 0px;
    }

    // Desktop & landscape Tablet
    @include desktopTablet {
      position: absolute;
      top: 183px;
      right: 450px;
    }
  }

  .table {
    width: 100%;
  }

  .noResults {
    display: flex;
    flex-direction: column;
    padding: 16px 24px;
    span:first-child {
      color: $primary-dark-blue;
      font-size: 20px;
      font-weight: 500;
    }
    span:nth-child(2) {
      font-size: 20px;
      line-height: 31px;
      color: $pure-grey;
    }
  }
}

.lotsRow {
  @include desktopTablet {
    > :first-child {
      text-align: center;
      padding: 12px 2px;
    }
  }
}

.listWrapper {
  height: 100%;
  width: 100%;
}
