@import "TogglableStylesVI3P.module";
@import "TogglableStylesV4.module";
// stylelint-disable color-no-hex

.root {
  background-color: rgba(255, 255, 255, 0.16);
  border: 1px solid #e3e4ec !important; // stylelint-disable-line declaration-no-important
  color: #003c71;
  font-size: 1rem;
  padding: 0;
  position: relative;
  transition: 0.25s ease-in-out;
  transition-property: border-color, background-color;
  //
  & input {
    clip: rect(0, 0, 0, 0);
    display: block;
    position: absolute;
  }
  //
  &::before {
    background: #003c71;
    border-radius: 50%;
    bottom: 0;
    content: "";
    display: block;
    height: 0;
    left: 0;
    margin: auto;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: inherit;
    transition-property: width, height, opacity, visibility;
    visibility: hidden;
    width: 0;
    will-change: width, height, opacity, visibility;
  }

  &:hover {
    border-color: #d6d6d6;
  }
}

.checked {
  &::before {
    left: 1px;
    opacity: 1;
    visibility: visible;
  }
}

.disabled {
  background-color: #d6d6d6;
}
