@import "../common/assets/styles/defaults";
@import "../common/assets/styles/tools";

.root {
  display: flex;
  align-items: center;
  background: $white;
  justify-content: space-between;
}

.destination {
  padding-right: 1rem;
  white-space: nowrap;
}

.name {
  margin-bottom: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  color: $text-dark;
}

.address {
  color: $text-light;

  > * {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @include mobile {
    white-space: normal;

    > div {
      display: inline;
    }
  }
}

.start {
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  margin-left: auto;
  border: 0;
  border-radius: inherit;
  background: $cyan-dark;
  font-size: 2.4rem;
  text-decoration: none;
  color: $white;
  cursor: pointer;
  transition: $transition-duration-fast;
  transition-property: background-color;

  &:hover,
  &:focus,
  &:active {
    outline: none;
    background-color: $blue;
  }
}
