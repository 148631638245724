@import "../../../common/assets/styles/main";
.root.vi3p {
  font-size: 1rem;
  @include mobileTablet {
    padding-left: 20px;
  }
}

.header.vi3p {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  margin-top: 0;
  @include mobileTablet {
    font-size: 20px;
  }
}
