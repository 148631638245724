@import "src/assets/styles/1-settings";

.statutEndDateContainer {
  margin: 4px 0 0;
}

.statutEndDate {
  color: $pure-black;
  font-size: 12px;
  text-transform: uppercase;
}
