@import "assets/styles/1-settings";

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 240px;
}

.odd {
  background-color: $white;
}
