@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.root {
  position: relative;

  &.odd {
    background-color: $white;

    .content {
      background-color: $white;
    }
  }
}

.content {
  background-color: $pure-white;
  position: relative;

  @include desktop {
    display: flex;
    margin: 0 -8px;
    padding: 16px 24px;
  }

  @include mobileTablet {
    padding-top: 0;
  }
}

.header {
  background-color: $primary-dark-blue;
  height: $lot-detail-header-height;
  padding: 20px 20px 16px 24px;
  position: relative;

  @include tablet {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

.detailImage,
.detailInfos,
.detailButtons {
  flex: 1 0 auto;
  padding: 0 8px;
}

.detailImage {
  width: 17.5%;
}

.detailInfos {
  width: 67%;
}

.detailButtons {
  width: 15.5%;

  @include mobileTablet {
    width: 100%;
  }
}

.noButtons {
  .detailImage {
    width: 17%;
  }

  .detailInfos {
    width: 83%;
  }
}

.name {
  align-items: center;
  color: $pure-white;
  display: flex;
  font-weight: 500;
  justify-content: space-between;
  line-height: 1;

  @include not-desktop {
    font-size: 26px;
  }

  @include mobileTablet {
    font-size: 32px;
  }
}

.moreBtn,
.returnBtn {
  background: none;
  border: none;
  padding: 0;
}

.returnBtn {
  align-items: center;
  color: $pure-white;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;

  @include tablet {
    left: 40px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.returnBtnIcon {
  color: $pure-white;

  @include not-desktop {
    font-size: 20px;
    margin-right: 6px;
  }

  @include mobileTablet {
    font-size: 24px;
    margin: 0;
  }
}

.moreBtnIcon {
  color: $pure-white;
  font-size: 24px;
}

.loading {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 266px;
  padding: 32px 0;
}

.loader {
  color: $primary-dark-blue;
}

.image {
  height: 384px;
  margin: 0 0 24px 0;

  @include mobile {
    height: 220px;
  }

  &.empty {
    height: 80px;
  }
}

.promo {
  margin: 5px 24px;

  @include not-desktop {
    margin: 0 24px 24px;
  }
}

.cols {
  display: flex;
  padding: 0 12px;

  .colsItem {
    padding: 0 12px;
  }

  .details {
    flex-grow: 1;
  }

  .buttons {
    width: 25%;
  }
}

.buttons {
  @include mobile {
    background-color: $pure-white;
    box-shadow: 4px 0 15px rgba(15, 73, 139, 0.104988);
    height: calc(100% - #{$lot-detail-header-height});
    padding: 16px;
    position: absolute;
    top: $lot-detail-header-height;
    right: 0;
    width: 225px;
    z-index: 1;
  }
}
