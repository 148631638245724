@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.root {
  height: 100%;
  position: relative;
  width: 100%;

  // Mobile & portrait Tablet
  @include mobileTablet {
    & > div > div > div:nth-child(11) > div {
      right: 40px !important;
      bottom: 240px !important;
      top: unset !important;
    }

    & > div > div > div:nth-child(12) > div {
      bottom: 170px !important;
    }
  }

  @include mobileTablet {
    // Attempt at fixing iOS bottom navigation bar absolute positioning.
    // The fix is constrained to mobile devices because Chrome desktop has trouble with it
    height: -webkit-fill-available;
    height: fill-available;
  }
}

.programActive {
  // Mobile & portrait Tablet
  @include mobileTablet {
    & > div > div > div:nth-child(11) > div {
      right: 40px !important;
      bottom: 340px !important;
      top: unset !important;
    }
  }
}

.clear {
  background: $pure-white;
  border: 1px solid $grey-2;
  padding: 10px 20px;

  @include mobileTablet {
    margin-left: 5px;
    padding: 5px;
    width: 10vh;
  }
}

.transitBox {
  background-color: $pure-white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(60, 64, 67, 0.3), 0 4px 8px 3px rgba(60, 64, 67, 0.15);
  display: flex !important;
  font-family: Roboto, Arial, sans-serif;
  margin: 0 0 15px 0;
}

.transitBoxLeft {
  align-items: flex-start;
  display: flex !important;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  padding: 8px 12px;
}

.transitBoxRight {
  align-items: center;
  border-left: 1px solid #dadce0;
  display: flex !important;
  padding: 8px 5px;
}

.transitBoxTitle {
  font-size: 12px !important;
  white-space: nowrap;
}

.transitBoxInfos {
  color: $grey-light-2;
  font-size: 11px !important;
  white-space: nowrap;
}
