@import "assets/styles/1-settings";

.root {
  position: relative;
  z-index: 0;

  &:hover {
    .slider {
      :global {
        .slick-arrow:not(.slick-disabled) {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}

.image {
  display: block;
  width: 100%;
  height: auto;

  @supports (object-fit: cover) {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.open {
  width: 34px;
  height: 34px;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 20;
  border: 1px solid $pure-grey;
  border-radius: 100%;
  background: $white;
  font-size: 18px;
  color: $primary-dark-blue;
  transition: $transition-duration-fast ease-in-out;
  transition-property: border-color, background-color, color;

  &:hover {
    border-color: transparent;
    background: $primary-dark-blue;
    color: $white;
  }
}

.sliderContainer {
  height: 100%;

  .slider {
    height: 100%;
    position: relative;
    z-index: 0;

    :global {
      .slick-list,
      .slick-track,
      .slick-slide > * {
        height: 100%;
      }

      .slick-arrow {
        width: 35px;
        height: 35px;
        z-index: 20;
        visibility: hidden;
        opacity: 0;
        border-radius: 100%;
        background: $white;
        font-size: inherit;
        color: $pure-black;
        transition: $transition-duration-fast ease-in-out;
        transition-property: opacity, visibility, background-color, color;

        &:hover {
          background: $primary-dark-blue;
          color: $white;
        }

        &::before {
          content: none;
        }
      }

      .slick-prev {
        left: 10px;
        transform: translate(0, -50%) rotate(90deg);
      }

      .slick-next {
        right: 10px;
        transform: translate(0, -50%) rotate(-90deg);
      }
    }
  }

  .dots {
    width: 50px;
    right: 0;
    left: 0;
    bottom: 20px;
    z-index: 20;
    margin: 0 auto;
    pointer-events: none;

    .dot {
      width: 5px;
      height: 5px;
      border-radius: 100%;
      background: $pure-white;
      opacity: 0.5;
      transition: $transition-duration-fast ease-in-out;
      transition-property: background-color, width, height, opacity;

      &.active {
        width: 6px;
        height: 6px;
      }

      &.current {
        opacity: 1;
        background: $primary-dark-blue;
      }
    }

    :global {
      .slick-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 10px;
        height: 10px;

        div:not([class]) {
          display: block !important;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.galleryImg {
  width: 100%;
}
