@import "assets/styles/1-settings";
@import "assets/styles/2-tools";
@import "assets/styles/variables";

.root {
  background-color: $pure-white;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

.buttonReturnTopIcon {
  color: $pure-white;
  font-size: 20px;
  margin-top: -2px;
  transform: rotate(180deg);
}

.paddingContent {
  padding-left: 32px;
  padding-right: 32px;
}

.alert {
  position: relative;
  padding: 12px 20px 10px 60px;
  font-weight: 500;
  box-shadow: 0 4px 4px rgba($pure-black, 0.25);

  &::before {
    content: "!";
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.2857em;
    height: 1.2857em;
    position: absolute;
    top: 12px;
    left: 20px;
    border-radius: 100%;
    padding-bottom: 0.1em;
    font-size: 14px;
  }
}

.freeBlock {
  background: $pure-white;

  @include desktop {
    margin: 30px auto;
    max-width: 60%;
  }

  @include mobile {
    margin: 0 24px 16px;
    width: calc(100% - 48px);
  }

  &::before {
    background-color: $primary-dark-blue;
    color: $pure-white;
  }

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }
}

.freeBlockRoot {
  padding-bottom: 0;
}

.freeBlockContent {
  padding: 0;
}

.lots {
  @include desktop {
    margin-top: 50px;
  }
}

.lotsRoot {
  padding-top: 0;
}

.promoBanner {
  background-color: $orange;
  color: $pure-white;
  font-size: 22px;
  padding: 7px 24px;
  text-align: center;

  @include mobile {
    font-size: 18px;
  }
}

.promoBannerText {
  p {
    margin: 0;
  }
}

.report {
  max-width: 980px;
  margin: 20px auto 0;
  transition: box-shadow 0.25s ease-in-out;

  &:hover {
    box-shadow: 0 4px 15px rgba(15, 73, 139, 0.11);
  }
}
