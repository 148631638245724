@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.root {
  &:global {
    &.slick-arrow {
      align-items: center;
      background: $white;
      border: 1px solid $pure-grey;
      border-radius: 100%;
      color: $black;
      display: flex;
      justify-content: center;
      height: 46px;
      padding: 0;
      position: absolute;
      top: 50%;
      transition: 0.25s ease-in-out;
      transition-property: color, box-shadow;
      width: 46px;
      z-index: 20;

      &:hover,
      &:focus,
      &:active {
        box-shadow: 0 5px 21px 0 rgba(0, 0, 0, 0.2);
        color: $pure-black;
        outline: none;
      }
    }

    &.slick-prev {
      left: 0;
      transform: translate(-50%, -50%);
    }

    &.slick-next {
      right: 0;
      transform: translate(50%, -50%);

      :local {
        .icon {
          transform: scaleX(-1);
        }
      }
    }
  }
}

.icon {
  display: block;
  font-size: 8px;
}
