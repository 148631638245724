@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.modalRoot {
  width: 560px;
  top: 50%;
  transition: top 0.3s ease 0.3s;

  position: fixed;
  left: 50%;
  overflow: visible;
  z-index: 1001;
  transform: translate(-50%, -50%);

  @include mobileTablet {
    z-index: 3000;
  }
}

.failSkin {
  position: relative;
  width: auto;
  height: auto;
  text-shadow: none;

  background: $pure-white;
  box-shadow: 0 4px 15px rgba(15, 73, 139, 0.104988);
  border-radius: 12px;

  .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 560px;
    background: rgba($red, 0.04);
    text-align: center;
    color: $red;
  }
}

.failHeader {
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;

  .iconButton {
    padding: 26px 25px 16px 25px;
    &:hover {
      background: none;
    }

    .svgIcon {
      width: 15px;
      height: 15px;
    }
  }
}

.failContent {
  max-width: 366px;
  padding: 0 24px 12px;

  .title {
    margin: 0 0 24px;
  }
}

.textSecondary {
  font-size: 14px;
  font-style: italic;

  strong {
    font-weight: 700;
  }
}
