@import "assets/styles/1-settings";

.root {
  border: 0;
  padding: 0;
  outline: 0;
  background: none;

  svg {
    display: block;
    width: 1em;
    height: 1em;
  }

  path {
    transition: $transition-duration-fast ease-in-out;
    transition-property: fill;
  }

  /* Handle Colors */
  --filter-icon-background-color: #{$pure-white};
  --filter-icon-circle-color: #{$grey};
  --filter-icon-text-color: #{$black};
  --filter-icon-accent-color: #{$grey};

  &:hover,
  &:focus,
  &:active {
    --filter-icon-background-color: #{$primary-blue-light};
    --filter-icon-circle-color: #{transparentize($primary-blue, 0.6)};
    --filter-icon-text-color: #{$primary-dark-blue};
    --filter-icon-accent-color: #{transparentize($primary-blue, 0.6)};
  }

  &.active {
    --filter-icon-background-color: #{$primary-dark-blue};
    --filter-icon-circle-color: #{transparentize($primary-blue, 0.6)};
    --filter-icon-text-color: #{$pure-white};
    --filter-icon-accent-color: #{transparentize($primary-blue, 0.6)};

    &:hover,
    &:focus,
    &:active {
      --filter-icon-background-color: #{$grey};
      --filter-icon-circle-color: #{$pure-grey};
      --filter-icon-text-color: #{$black};
      --filter-icon-accent-color: #{$pure-grey};
    }
  }
}
