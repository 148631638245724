@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

$list-gutter-x: 16px;
$list-gutter-y: 20px;

.title {
  position: relative;
  margin: 0;
  padding-bottom: 8px;
  font-weight: 500;
  font-size: 16px;
  color: $primary-dark-blue;

  &::before {
    content: "";
    width: 50px;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: currentColor;
  }

  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.list {
  display: flex;
  margin: 0 (-$list-gutter-x / 2);

  @include not-desktop {
    flex-wrap: wrap;
  }
}

.col {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin: 0 ($list-gutter-x / 2);
  width: 33.33%;

  @include not-desktop {
    flex: 1 1 auto;
  }

  @include mobile {
    width: 100%;
  }

  &:empty {
    flex: 0 1 0;
    margin: 0;
  }
}

.line {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin: 4px 0;
  padding-left: 10px;
  position: relative;

  &::before {
    background: $grey;
    border-radius: 100%;
    content: "";
    display: block;
    height: 4px;
    left: 0;
    position: absolute;
    top: 11px;
    width: 4px;
  }
}

.dt,
.dd {
  display: inline;
  font-size: 16px;
  line-height: 1.2;
}

.dt {
  margin: 3px 0 0;
  white-space: nowrap;

  &:not(:only-child) {
    margin-right: 0.5ch;
  }
}

.dd {
  color: $primary-dark-blue;
  font-weight: 500;
  margin: 3px 0 0;
}
