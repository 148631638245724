@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

$height: 124px;
$preview-marketing-dim: 137px;

.root {
  display: flex;
  flex-direction: column;
  height: $height;
  padding-bottom: 20px;
}

.name {
  min-height: 33px;
  padding: 7.5px 10px;
  font-weight: 500;
  font-size: 18px;
  line-height: 1;
  color: $pure-black;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.location,
.type {
  margin-bottom: 16px;
}

.location {
  display: flex;
  align-items: center;
  font-style: normal;
  font-size: 14px;
  line-height: 1;
  color: $black;

  .icon {
    flex: 0 0 auto;
    width: 8px;
    height: 12px;
    position: relative;
    top: 0.15em;
    margin-right: 8px;
  }

  .text {
    width: 0;
    flex: 1 0 auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.type {
  font-weight: 500;
}

.data {
  display: flex;

  .column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 10px;

    &.large {
      width: 0;
      flex: 1 0 0;
    }

    &.small {
      flex: 0 1 auto;
    }
  }

  .item {
    display: flex;
    font-size: 14px;
    line-height: 1;
    color: $black;

    &:not(:last-child) {
      margin-bottom: 12px;
    }

    .label {
      flex: 1 0 auto;
      margin-right: 15px;
    }
  }

  .highlight {
    font-weight: 500;
    color: $primary-dark-blue;
  }
}

.price {
  .tax {
    font-size: 14px;
    color: $primary-dark-blue;
  }
}

.preview {
  height: $height;
  padding: 0 $preview-marketing-dim 10px 10px;

  .name {
    padding-right: 0;
    padding-left: 0;
  }

  .type,
  .marketing {
    font-size: 14px;
    line-height: 1;
    color: $black;
  }

  .type {
    margin-bottom: 12px;
  }

  .marketing .date {
    font-weight: 500;
    color: $yellow-pale;
  }
}

.marketingAlert {
  position: absolute;
  top: calc(100% - #{$height});
  right: 17px;
  bottom: 0;
  margin: auto;
  font-size: 14px;
  background: $primary-dark-blue;
  color: $yellow-pale;

  &.active {
    background: $grey-light;
    color: $black;
  }

  &.hover {
    background: transparent;
    color: $primary-dark-blue;

    &:not(.active) {
      .icon,
      .countdown {
        color: $primary-blue;
      }
    }

    &:hover,
    &:focus,
    &:active {
      .icon,
      .countdown {
        color: inherit;
      }
    }
  }
}
