@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.root {
  margin: 0 auto;
  max-width: 1280px;

  @include mobile {
    padding: 16px;
  }

  :global(.rte-content) {
    font-size: 18px;
  }

  p {
    margin: 0 0 10px;
  }
}

.accordionItemsButton {
  &::after {
    color: $primary-dark-blue;
  }
}
