// stylelint-disable color-no-hex
// Shadows
$default-shadow-color: #0f498b;
$default-shadow: rgba($default-shadow-color, 0.1);
$default-box-shadow: 0 4px 15px 0 $default-shadow;

// Transitions
$transition-duration-fast: 0.25s;
$transition-duration-medium: 0.5s;

// Program map (kelquartier)
$program-map-height: 57rem;
$program-map-title-height-lg: 9.1rem;
$program-map-title-height-sm: 6.3rem;

// Neutral
$neutral-lighter: #f3f3f3;
$neutral-white: #f7f7f7;
$neutral-grey: #aaaaaa;

// Text
$text-light: $neutral-grey;
