@import "src/assets/styles/1-settings";

$background: $white;
$sharesHeight: 14px;

.root {
  position: relative;
  z-index: 0;
  border: 1px solid $grey-light;
  background: $background;
  box-shadow: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.1875;
  color: $primary-dark-blue;

  &.rounded:first-child {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }

  &.rounded:last-child {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    border-radius: inherit;
    box-shadow: 0 4px 4px rgba($black2, 0.25);
    visibility: hidden;
    opacity: 0;
    transition: 0.2s ease-in-out;
    transition-property: visibility, opacity;
  }

  &:hover {
    &::after {
      visibility: visible;
      opacity: 1;
    }

    .expander {
      border-color: transparent;
      background: $primary-blue;
      fill: $pure-white;
    }
  }
}

.headerIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.4em;
  height: 1.4em;
  margin-right: 22px;
  border-radius: 100%;
  padding-bottom: 0.1em;
  background: $primary-dark-blue;
  font-weight: 700;
  font-size: 18px;
  color: $pure-white;
}

.expander {
  $dim: 30px;
  $arrowSize: 10px;
  $borderWidth: 1px;

  width: $dim;
  height: $dim;
  border: $borderWidth solid $grey-light;
  border-radius: 8px;
  padding: ($dim - $arrowSize) / 2 - $borderWidth;
  background: $pure-white;
  font-size: $arrowSize;
  transition: 0.2s ease-in-out;
  transition-property: border-color, background, fill;
}

.steps {
  $gutter: 25px;

  display: flex;
  position: relative;
  list-style: none;
  margin: 0 (-$gutter / 2);
  padding: 0;

  > * {
    flex: 1 0 0;
    position: relative;
    z-index: 10;
    margin: 0 ($gutter / 2);
  }

  &::before {
    content: "";
    position: absolute;
    top: 14px;
    right: 10%;
    left: 10%;
    z-index: 0;
    border-top: 1px solid $primary-dark-blue;
  }
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: $primary-dark-blue;
}

.stepNumber {
  margin-bottom: 14px;
  border-radius: 30px;
  padding: 7px 12px;
  font-weight: 700;
  font-size: 14px;
  line-height: 1;
  background: $primary-dark-blue;
  text-transform: uppercase;
  color: $pure-white;
}

.stepIcons {
  $gutter: 14px;

  display: flex;
  margin: 0 (-$gutter / 2) 12px;

  > * {
    margin: 0 ($gutter / 2);
  }
}

.stepIcon {
  width: 1em;
  height: 1em;
  font-size: 22px;
  fill: currentColor;
}

.shares {
  $gutter: 25px;

  display: flex;
  margin: 14px (-$gutter / 2) 0;
}

.share {
  $dashColor: $primary-dark-blue;

  position: relative;
  z-index: 0;
  background: linear-gradient(90deg, $background 3px, transparent 0),
    linear-gradient(
        0deg,
        transparent 6px,
        transparent 6px,
        $dashColor 6.5px,
        transparent 7px,
        transparent 14px
      )
      repeat-x;
  background-size: 6px 100%, 1px 14px;
  font-size: $sharesHeight;
  line-height: 1;
  text-align: center;

  &::before,
  &::after {
    $dim: 5px;

    content: "";
    display: block;
    width: $dim;
    height: $dim;
    position: absolute;
    top: calc(#{$sharesHeight / 2} - #{$dim / 2});
    border-radius: 100%;
    background: radial-gradient($dashColor 0, $dashColor 0);
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }

  &:not(:first-child)::before {
    content: none;
  }
}

.shareLabel {
  display: table;
  margin: 0 auto;
  padding: 0 3px;
  background: $background;
}

.root {
  .accordionSummaryRoot {
    padding: 0;

    .accordionSummaryContent {
      margin: 0;
      padding: 22px 32px;
    }

    .accordionSummaryexpandIcon {
      margin: 0;
      padding: 22px;
    }
  }

  .accordionDetailsRoot {
    padding: 0 32px 22px;
  }
}
