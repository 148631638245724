@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.root {
  width: fit-content;

  .link {
    font-family: $vinciFontName;
    font-size: 16px;
    line-height: 19px;
    color: $pure-black;
    margin-left: 9px;

    &::after {
      content: "";
      margin-left: 5px;
      width: 102%;
      margin-top: 3px;
      margin-bottom: 10px;
      height: 1px;
      display: block;
      background: $primary-blue;
      transition: 300ms;
    }

    &:hover::after {
      width: 36%;
    }
  }

  .bold {
    font-family: $vinciFontName;
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: $primary-blue;
    &:hover {
      color: $primary-dark-blue;
    }
  }

  .group {
    font-family: $vinciFontName;
    font-size: 19px;
    color: $black;
    display: table;

    &:hover,
    &:focus,
    &:visited,
    &:link,
    &:active {
      color: $primary-blue;
    }

    .icon {
      display: table-cell;
      font-family: $vinciFontName;
      font-size: 20px;
      line-height: 19px;
      border-bottom: 9px solid transparent;
      border-right: 10px solid transparent;
    }

    .label {
      display: table-cell;
      font-family: $vinciFontName;
      font-size: 16px;
      line-height: 19px;
    }
  }
}

.appBar {
  .link {
    font-family: $vinciFontName;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: $primary-dark-blue;
    margin-left: 0;
  }
  @include mobileTablet {
    margin-top: 12px;
  }

  &:hover {
    color: $primary-blue;
  }

  .link {
    &:hover {
      margin-top: -2px;
      padding-bottom: 11px;
      border-bottom: 2px solid $primary-blue;
    }
    &::after {
      content: none;
    }
  }
}

.activeLink {
  a {
    color: $primary-dark-blue !important;
  }

  .link {
    margin-top: -2px;
    padding-bottom: 11px;
    border-bottom: 2px solid $primary-blue;
    &::after {
      content: none;

      @include mobileTablet {
        margin-bottom: unset;
        margin-top: 9px;
      }
    }
  }
}
