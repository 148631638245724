@mixin font-face($font-family, $file-name, $type, $weight, $style) {
  $baseDir: "../fonts";

  @font-face {
    font-family: $font-family;
    src: url("#{$baseDir}/#{$file-name}/#{$file-name}-#{$type}.eot");
    src: url("#{$baseDir}/#{$file-name}/#{$file-name}-#{$type}.eot?#iefix") format("eot"),
      url("#{$baseDir}/#{$file-name}/#{$file-name}-#{$type}.woff") format("woff"),
      url("#{$baseDir}/#{$file-name}/#{$file-name}-#{$type}.ttf") format("truetype"),
      url("#{$baseDir}/#{$file-name}/#{$file-name}-#{$type}.svg") format("svg");
    font-style: $style;
    font-weight: $weight;
  }
}

@mixin font-face-reduced($font-family, $file-name, $type, $weight, $style) {
  $baseDir: "../fonts";

  @font-face {
    font-family: $font-family;
    src: url("#{$baseDir}/#{$file-name}/#{$file-name}_#{$type}.woff") format("woff"),
    url("#{$baseDir}/#{$file-name}/#{$file-name}_#{$type}.woff2") format("woff2"),
    url("#{$baseDir}/#{$file-name}/#{$file-name}_#{$type}.ttf") format("truetype");
    font-style: $style;
    font-weight: $weight;
  }
}

// Media Queries
@mixin desktop-xxl() {
  @media (min-width: $breakpoint-xxl-min) {
    @content;
  }
}

@mixin desktop-xl() {
  @media (min-width: $breakpoint-xl-min) {
    @content;
  }
}

@mixin desktop() {
  @media (min-width: $breakpoint-lg-min) {
    @content;
  }
}

@mixin not-desktop() {
  @media (max-width: $breakpoint-md-max) {
    @content;
  }
}

@mixin desktopTablet() {
  @media (min-width: $breakpoint-md-min) {
    @content;
  }
}

@mixin tablet-landscape() {
  @media (min-width: $breakpoint-md-min) and (max-width: $breakpoint-md-max) {
    @content;
  }
}

@mixin tablet() {
  @media (min-width: $breakpoint-sm-min) and (max-width: $breakpoint-md-max) {
    @content;
  }
}

@mixin tablet-portrait() {
  @media (min-width: $breakpoint-sm-min) and (max-width: $breakpoint-sm-max) {
    @content;
  }
}

@mixin mobileTablet() {
  @media (max-width: $breakpoint-sm-max) {
    @content;
  }
}

@mixin mobile() {
  @media (max-width: $breakpoint-xs-max) {
    @content;
  }
}

@mixin not-mobile() {
  @media (min-width: $breakpoint-sm-min) {
    @content;
  }
}

//---------- Icons font ----------//
%icomoon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important; // stylelint-disable-line
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@function strip-unit($value) {
  @if type-of($value) == "number" and not unitless($value) {
    @return $value / ($value * 0 + 1);
  }

  @return $value;
}

$youtube-button-bg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 68 48'%3E%3Cpath class='ytp-large-play-button-bg' d='M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z' fill='%23f00'%3E%3C/path%3E%3Cpath d='M 45,24 27,14 27,34' fill='%23fff'%3E%3C/path%3E%3C/svg%3E");

%youtube-thumbnail {
  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: $youtube-button-bg no-repeat center center;
    background-size: 25% auto;
  }
}

%program-label {
  padding: 7px 15px 11px;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.26px;
  text-transform: uppercase;
  background: $royal-blue;
  color: $pure-white;
}
