@import "assets/styles/1-settings";
@import "assets/styles/2-tools";
@import "assets/styles/variables";

.root {
  display: flex;
  width: 100%;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background: $pure-white;
  box-shadow: 0 3px 25px #0038681a;
  transition: transform $transition-duration-fast ease-in-out;

  &:not(.visible) {
    transform: translateY(-100%);
  }
}

.buttons {
  display: flex;
  align-items: center;
}

// Desktop & landscape Tablet
@media (min-width: $breakpoint-md-min) {
  .root {
    height: $header-height;
    padding: 0 35px;

    .logo {
      margin-right: 50px;

      img {
        width: auto;
        height: 100%;
      }
    }

    .vip {
      height: 40px;
    }

    .vi {
      height: 35px;
    }
  }

  .buttons {
    gap: 15px;
    margin-left: auto;
  }

  .separator {
    height: 40px;
    margin: 0 20px;
    border-left: 1px solid $grey;
  }
}

// Mobile & portrait Tablet
@media (max-width: $breakpoint-sm-max) {
  .root {
    height: $header-height-mobile;
  }

  .root {
    padding: 0 15px;

    .logo {
      display: none;
    }
  }

  .buttons {
    flex: 1 0 auto;
    justify-content: space-between;

    .link {
      justify-content: center;
      height: 30px;
      padding: 0 20px 0.2em;
      font-size: 12px;
    }

    .contact {
      width: 30px;
      padding: 0;
      font-size: 0;

      :global {
        .icon {
          margin-right: 0;
        }
      }
    }
  }

  .separator {
    display: none;
  }
}
