@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.notification {
  .iconCircle {
    background-color: $primary-dark-blue;
  }

  .title {
    font-size: 14px;
    line-height: 16px;
    color: $pure-black;
  }

  .detail {
    font-size: 12px;
    line-height: 130%;
    color: $pure-black;
  }

  a.link {
    color: $primary-blue;
  }

  &:hover {
    a.link {
      color: $primary-dark-blue;
    }
  }
}

.unreadNotification {
  .iconCircle {
    background-color: $pink;
  }
  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: $pure-black;
  }

  .detail {
    font-size: 12px;
    line-height: 130%;
    color: $pure-black;
  }
}

.actionUrl {
  line-height: 1px;
}

.notificationContent {
  font-family: $vinciFontName;
  display: flex;
  justify-content: left;
  align-items: center;
  border: 0;
  background: none;
  text-align: left;
  width: 100%;

  margin: 12px 0;

  @include mobileTablet {
    justify-content: space-between;
  }

  .leftContent {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div.date {
    font-size: 12px;
    line-height: 12px;
    text-align: right;
    color: #b4becb;

    align-self: flex-start;
    margin-left: 14px;
  }

  .link {
    padding-top: 2px;
    text-decoration: underline;
  }
}

.iconCircle {
  flex: 1;
  width: 44px;
  min-width: 44px;
  max-width: 44px;
  height: 44px;
  background-color: $primary-dark-blue;
  border: 2px solid #ebeef4;
  box-sizing: border-box;
  border-radius: 100px;
  color: $pure-white;

  margin-right: 16px;

  i {
    display: flex;
    align-content: center;
    justify-content: center;
    margin-top: 10px;
    transform: rotate(-15deg);
    font-size: 20px;

    &:global(.icon--send-demand) {
      font-size: 18px;
      margin-left: -3px;
      margin-top: 11px;
      transform: none;
    }
  }
}
