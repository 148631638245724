@charset "UTF-8";
@import "../1-settings";
@import "../variables";

.block-content-title {
  position: relative;
  display: block;
  margin: 0;
  font-weight: 500;
  font-size: 48px;
  color: $blue-dark;
}

.content-login {
  position: relative;

  .content-image-wrapper {
    position: relative;
    display: block;
    height: calc(100vh - 64px);
    margin: 0 auto;
    z-index: 50;
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    img {
      width: 100%;
      height: auto;
    }
  }

  .mobile-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 16px;

    .vip {
      display: block;
      width: 44px;
      height: auto;
    }

    .vinci {
      display: block;
      width: auto;
      height: 18px;
    }
  }

  .content {
    position: absolute;
    display: block;
    right: 0;
    bottom: 0;
    left: 0;
    height: 330px;
    margin: 0 auto;
    box-sizing: border-box;
  }

  .content-left {
    height: 100%;
  }

  .content-right {
    width: 32%;
    height: 100%;
    background-color: $white;
  }

  .content-title {
    color: $white;
    font-size: 68px;
    line-height: 1;
    margin: 0;

    * {
      font-size: 68px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    p {
      margin: 0 0 24px;
    }

    a {
      color: $primary-dark-blue;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .login-title {
    font-size: 28px;
    font-weight: 500;
    color: $text;
    padding-bottom: 5px;
  }

  .login-button {
    display: block;
    width: 200px;

    > * {
      display: flex;
      text-transform: uppercase;
      margin-top: 20px;
      font-size: 14px;
    }

    .btn-reverse {
      border: 1px solid #cecece;
      border-radius: 2px;
    }
  }
}

// Second section
.section-under-first {
  padding: 50px $home-margin-h 55px;
  background: $white;

  .block-content-text {
    padding-right: 50px;
    font-size: 14px;
    line-height: 1.7;
    color: $white;

    .btn-primary {
      margin-top: 30px;
      font-weight: 500;
      background: $white;
      text-transform: uppercase;
      font-size: 15px;
      padding: 10px 24px;
      max-width: 192px;

      &:hover {
        border: 1px solid transparent;
        color: $white;
        background-color: $blue !important;
      }
    }
  }

  .vinci-title {
    color: inherit;
    font-size: 48px;
    line-height: 1em;
    margin: 0 0 25px;

    //max-width: 336px

    * {
      font-size: 48px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    p {
      margin: 0 0 16px;
    }

    a {
      color: $white;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .vinci-text {
    h1,
    h2,
    h3,
    h4,
    h5,
    p {
      margin: 0 0 16px;
    }

    a {
      color: $white;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .block-content {
    display: flex !important;
    align-items: center;
    outline: none;
  }

  .vinci-youtube-player,
  .image-wrapper {
    width: 60%;
    flex: 1 0 auto;
  }

  .image-wrapper {
    img {
      width: 100%;
      height: auto;
    }
  }
}

// Third & Fourth sections
.highlight-slider {
  padding: 40px $home-margin-h 60px;
  overflow: hidden;
  background-color: $white-soft;

  .slick-slider {
    padding-top: 20px;
  }

  .highlight-col {
    padding-right: 15px;

    + * {
      padding-left: 15px;
      display: flex;
      flex-direction: column;
    }
  }

  .block-content-button {
    margin-top: 20px;
  }
}

.highlight-slider-item {
  background: $white;
  height: 50%;

  .content-img {
    display: block;
    position: relative;

    img {
      display: block;
      width: 100%;
      height: auto;
    }

    .etiquette-fiscalite {
      padding: 0.3em 0.9em;
      font-size: 12px;
    }
  }

  .content-description {
    margin: auto 0;
    background: $white;
    padding: 15px 20px;
  }

  .content-title {
    margin-top: 10px;
    color: $blue-dark;
    font-weight: 500;
    font-size: 16px;
    text-transform: lowercase;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  .content-address {
    font-size: 16px;
    color: $blue;
    font-weight: 400;
  }

  .content-informations {
    margin: 5px 0 0;
    font-size: 15px;
    color: $text;

    dt {
      display: inline-block;
    }

    dd {
      display: inline-block;
      margin: 0 0 0 0.5ch;
    }
  }
}

.highlight-col .highlight-slider-item {
  display: flex;
  flex-direction: column;
  height: 100%;

  .content-img {
    .etiquette-fiscalite {
      padding: 0.3em 1.4em;
    }
  }

  .content-title {
    display: inline-block;
    margin-top: 0;

    > * {
      display: inline-block;

      &:not(:first-child)::before {
        content: "-";
        display: inline-block;
        margin: 0 0.7ch;
      }
    }
  }

  .content-informations {
    display: inline-block;
    margin-top: 0;
    margin-left: 20px;

    > div {
      display: inline-block;

      &:not(:first-child)::before {
        content: "-";
        display: inline-block;
        margin: 0 0.7ch;
      }
    }
  }
}

.highlight-slider--dark {
  background-color: $blue-dark;

  .block-content-title {
    color: $white;
  }

  .slick-arrow {
    color: $white !important;
  }

  .block-content-title {
    color: $white;
  }
}

// Fith section
// Prochainement
.section-prochainement {
  padding: 30px $home-margin-h 40px;

  .slick-slider {
    .slick-list {
      margin-right: -$home-margin-h;
      margin-left: -20px;
    }

    .slick-track {
      display: flex;
    }

    .slick-slide {
      display: flex;
      flex-direction: column;
      height: auto;
      padding-right: 20px;
      padding-left: 20px;

      .section-prochainement-item {
        width: 275px !important;
      }

      > * {
        display: flex;
        height: 100%;

        > * {
          flex-grow: 1;
        }
      }
    }
  }

  .section-prochainement-item {
    width: 25%;
    position: relative;
    z-index: 0;
    float: left;
    padding: 10px 15px 15px;

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      background: $white-soft;
    }

    &:hover::after {
      opacity: 1;
    }

    .content-title {
      font-weight: 500;
      color: $blue-dark;
      font-size: 16px;
      text-transform: lowercase;
      margin-top: 0.5em;

      &:first-letter {
        text-transform: uppercase;
      }
    }

    .content-location {
      margin-bottom: 15px;
      font-size: 16px;
      text-transform: uppercase;
      color: $blue;
    }

    .content-type {
      font-weight: 300;
      font-size: 14px;
      color: $text-light;
    }

    .content-img {
      position: relative;
      display: block;
      margin: (-10px) -15px 10px;

      .content-img-etiquette {
        span {
          position: absolute;
          top: 20px;
          left: 20px;
          border-radius: 20px;
          padding: 0.3em 1.4em;
          color: $white;
          font-weight: 500;
          font-size: 12px;
          text-transform: uppercase;
          background-color: #f064a0;
        }
      }
    }
  }
}

// Six Section
// Actualités
.section-actualites {
  padding: 30px $home-margin-h 40px;

  .slick-slider {
    .slick-slide {
      .news {
        width: 275px !important;
      }
    }
  }
}

.news {
  display: flex !important;
  flex-direction: column;
  padding: 30px 15px;
  background: $white;
  color: $blue-dark;

  &__img {
    margin: (-30px) -15px 15px;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 16px;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  &__description {
    margin: 20px 0;
  }

  &__desc {
    > :first-child {
      margin-top: 0;
    }

    p {
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      color: $text;
    }
  }

  &__btn {
    margin-top: auto;
    align-items: flex-end;
    font-weight: 500;
    font-size: 15px;
    color: $blue;
    text-transform: uppercase;
    text-decoration: underline;
  }
}

// Desktop
@media (min-width: $screen-lg-min) {
  .content-login {
    .content {
      width: 100%;
    }

    .content-left {
      padding-left: $home-margin-h;
      box-sizing: border-box;

      .content-title {
        box-sizing: border-box;
      }
    }
  }
}

// Tablet & Desktop
@media (min-width: $screen-md-min) {
  .content-login {
    .mobile-header {
      display: none;
    }

    .content-left {
      padding-right: calc(32% + 20px);
      padding-left: $home-margin-h;
    }

    .content-right {
      position: absolute;
      right: 0;
      bottom: 0;
    }

    .login-system {
      padding: 50px $home-margin-h;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .section-prochainement {
    .slick-list {
      padding: 20px 0;
    }

    .section-prochainement-item {
      &::after {
        content: "";
        display: block;
        width: calc(100% + 40px);
        height: calc(100% + 40px);
        position: absolute;
        top: -20px;
        left: -20px;
        z-index: -2;
        opacity: 0;
        background: $white;
        border: 1px solid $border;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
        transition: border 0.25s ease-in-out;
      }
    }
  }
}

// Desktop
@media (min-width: $screen-md-min) {
  .content-login {
    .btn-contact {
      display: none;
    }
  }

  .section-under-first {
    .section-conteneur-wrap::before {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      outline: none;
      background-color: $blue-dark;
    }

    .slick-slide:not(.slick-current) {
      .block-content {
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
      }
    }
  }

  .section-actualites {
    .slick-slider {
      .slick-list {
        margin-right: -$home-margin-h;
        margin-left: -20px;
        padding: 20px 0;
      }

      .slick-track {
        display: flex;
      }

      .slick-slide {
        display: flex;
        flex-direction: column;
        height: auto;
        padding-right: 20px;
        padding-left: 20px;

        @media (max-width: $screen-sm-max) {
          width: 100% !important;
        }

        > * {
          display: flex;
          flex-direction: column;
          flex-grow: 1;

          > * {
            flex-grow: 1;
          }
        }
      }
    }
  }
}

// Tablette paysage
@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  .content-login {
    .login-system {
      padding-left: 40px;
      padding-right: 40px;
    }

    .content-title {
      font-size: 46px;

      * {
        font-size: 46px;
      }
    }
  }
}

// Tablette portrait
@media (max-width: $screen-sm-max) {
  .content-login {
    .content-image-wrapper {
      height: 100vh;

      .content {
        width: calc(100% - 30px);
        height: calc(100% - 64px);
        margin: 0 auto;

        .content-title {
          font-size: 32px;
          margin-top: 120px;

          * {
            font-size: 32px;
          }
        }

        .content-left.container {
          width: auto !important;
          margin: 0;
          padding: 0;
        }

        .content-right {
          position: absolute;
          width: 100%;
          height: auto;
          bottom: 0;
          background-color: transparent;

          .login-system {
            padding-bottom: 20px;

            .login-button {
              width: 100%;

              .btn-primary {
                font-size: 16px;
              }

              .btn-contact {
                position: relative;
                display: block;
                color: $white;
                font-size: 14px;
                text-transform: uppercase;
                text-decoration: underline;
                text-align: center;
                border: 0;
                padding: 0;
                background: none;
                margin: 30px auto 0;
              }
            }
          }
        }
      }
    }

    .login-title {
      display: none;
    }

    .login-button {
      > * {
        margin-top: 10px;
      }
    }
  }

  .block-content-title {
    margin-top: 0.83em;
    margin-bottom: 0.83em;
    font-size: 35px;
  }

  .block-content-button {
    position: relative;
    display: block;
    box-sizing: border-box;
  }

  .highlight-slider {
    padding: 20px 15px;

    .slick-slider {
      padding: 0;

      .slick-list {
        margin-right: -15px;
        margin-left: -6px;
      }

      .slick-slide {
        padding-right: 6px;
        padding-left: 6px;
      }
    }
  }

  .highlight-slider-item {
    display: flex;
    flex-direction: column;
    width: 275px;

    .content-title {
      font-size: 14px;
      text-transform: lowercase;

      &:first-letter {
        text-transform: uppercase;
      }
    }

    .content-address {
      font-size: 12px;
    }
  }

  .section-under-first {
    padding: 30px 15px;
    background-color: $blue-dark;

    .block-content {
      flex-direction: column-reverse;
    }

    .vinci-title {
      margin-top: 25px;
      margin-bottom: 15px;
      font-size: 28px;

      * {
        font-size: 28px;
      }
    }

    .vinci-youtube-player,
    .image-wrapper {
      width: 100%;
    }

    .block-content-text {
      padding: 0;
    }

    .btn-primary {
      max-width: none;
      padding: 14px 0;
      background-color: $white;
      font-size: 16px;
      color: $text;
      text-transform: uppercase;
      border-radius: 2px;
    }
  }

  .section-prochainement {
    padding: 20px 15px;

    .block-content-title {
      margin-bottom: 30px;
    }

    .section-prochainement-item {
      width: 100%;
    }

    .slick-slider {
      .slick-list {
        margin-right: -15px;
        margin-left: -6px;
      }

      .slick-slide {
        padding: 0 6px;
      }
    }
  }

  .section-actualites {
    padding: 20px 15px;
  }

  .news {
    margin-bottom: 20px;
  }

  .section-vip {
    background: $white-soft;

    .content {
      .content-title {
        color: $text;
      }

      .content-description {
        .number {
          font-weight: 400;
        }

        a {
          font-size: 18px;
        }
      }
    }
  }

  main[role="main"] {
    section {
      &.section-conteneur {
        &.section-vip {
          [class*="col-"] {
            padding: 0;
            border: none;
          }

          .content {
            .content-title {
              font-size: 28px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: $screen-xs-max) and (orientation: landscape) {
  body {
    .image-overlay-wrapper img {
      width: 100%;
      height: auto;
    }
  }
}
