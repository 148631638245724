@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.root {
  position: relative;

  &:only-child {
    .separator {
      display: none;
    }
  }

  .separator {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    border-bottom: 2px solid $pure-grey;
  }

  @include mobile {
    :global .container :local & {
      margin-right: -20px;
      margin-left: -20px;

      &::after {
        right: 20px;
        left: 20px;
      }
    }
  }
}

.button {
  height: 70px;
  width: 100%;
  position: relative;
  border: 0;
  border-radius: 0;
  padding: 0;
  background: none;
  font-weight: 700;
  font-size: 18px;
  text-align: inherit;
  color: inherit;

  .buttonIcon {
    position: absolute;
    top: 50%;
    right: 26px;
    transform: translateY(-50%);
    transition: $transition-duration-fast;
    transition-property: opacity, visibility;
    pointer-events: none;
  }

  &.open {
    cursor: auto;

    .buttonIcon {
      opacity: 0;
      visibility: hidden;
    }
  }

  @include mobile {
    :global .container :local & {
      padding: 0 20px;
    }
  }
}

.visual {
  @include mobile {
    margin-bottom: 20px;
  }
}

.content {
  padding-bottom: 32px;

  .title {
    font-weight: 300;
    font-size: 32px;
    line-height: 1;

    + * {
      margin-top: 14px;
    }
  }

  @include not-mobile {
    > :not(:first-child) {
      margin-top: 30px;
    }
  }

  @include mobile {
    :global .container :local & {
      padding-right: 20px;
      padding-left: 20px;
    }

    > :not(:first-child) {
      margin-top: 20px;
    }
  }
}
