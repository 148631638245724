@import "src/assets/styles/1-settings";

.root {
  align-items: center;
  display: flex;
  padding: 12px 0;

  &:last-child {
    padding-bottom: 0;
  }
}

.iconWrapper {
  align-items: center;
  background-color: $primary-blue-light;
  border-radius: 50%;
  display: flex;
  height: 40px;
  justify-content: center;
  margin: 0 16px 0 0;
  width: 40px;
}

.icon {
  color: $primary-dark-blue;
  font-size: 20px;
}

.sprite {
  height: 20px;
}

.infos {
  font-size: 16px;

  p {
    margin: 5px 0 0;
  }
}

.label {
  font-size: 16px;
  font-weight: 500;
}
