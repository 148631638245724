@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

.root {
  display: flex;
  border-radius: 8px;
  background: $pure-white;

  @include not-mobile {
    &:not(.reverse) {
      .images {
        border-top-right-radius: inherit;
        border-bottom-right-radius: inherit;
      }
    }

    &.reverse {
      flex-direction: row-reverse;

      .images {
        border-top-left-radius: inherit;
        border-bottom-left-radius: inherit;
      }
    }
  }

  @include mobile {
    flex-direction: column;

    .images {
      order: 0;
      border-top-right-radius: inherit;
      border-top-left-radius: inherit;
    }

    .content {
      order: 10;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1 0 52.4%;
  align-self: center;

  @include desktop {
    padding: 20px 40px;
  }

  @include not-desktop {
    padding: 20px;
  }

  .title {
    margin-top: 0;
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 32px;
    line-height: 1;
    color: $black2;
  }

  .date {
    margin-bottom: 23px;
    font-size: 18px;
    color: $pure-grey;
  }

  .description {
    max-width: 100%;
    font-size: 18px;
    color: $black;
  }

  .button {
    align-self: flex-start;
    min-width: 125px;
    height: 43px;
    border: 1px solid currentColor;
    border-radius: 100px;
    padding: 10px 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.1;
    text-align: center;
    color: $primary-dark-blue;
    transition: 0.25s ease-in-out;
    transition-property: border-color, background-color, color;

    &:hover {
      border-color: $primary-dark-blue;
      background: $primary-dark-blue;
      color: $pure-white;
    }
  }
}

.images {
  flex: 1 0 47.6%;
  overflow: hidden;
}
