@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

.root {
  .button {
    align-items: center;
    border: 1px solid $primary-dark-blue;
    display: flex;
    height: 48px;
    letter-spacing: unset;
    padding: 0 26px;
    text-transform: none;
    text-wrap: nowrap;

    @include mobile {
      flex-grow: 1;
      min-width: auto;
      padding: 0 16px;
    }

    &:first-child {
      border-radius: 30px 0 0 30px;
    }

    &:last-child {
      border-radius: 0 30px 30px 0;
    }

    &.buttonDisabled {
      background-color: $primary-dark-blue !important;

      .label {
        color: $pure-white;
      }
    }
  }

  .label {
    color: $primary-dark-blue;
    font-family: $vinciFontName;
    font-size: 16px;
    font-weight: 500;
    margin-top: -3px;

    @include mobileTablet {
      font-size: 14px;
      margin-top: -4px;
    }
  }

  .labelPrefix,
  .labelText {
    text-transform: uppercase;
  }

  .labelPrefix {
    font-weight: 400;
  }

  .number {
    font-size: 20px;

    @include mobileTablet {
      font-size: 18px;
    }
  }
}
