@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.notificationsHeader {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  height: 68px;
  padding: 0 16px 0 24px;

  @include mobile {
    border-bottom: 1px solid $grey-light;
  }

  .headerTitle {
    align-items: center;
    display: flex;
    justify-content: space-between;
    font-family: $vinciFontName;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    color: $pure-black;

    span {
      margin-left: 16px;
    }

    .svgIcon {
      width: 23px;
      height: 21px;
    }

    .unreadNbNotifications {
      background-color: $pink;
      border-radius: 50%;

      color: $pure-white;
      font-size: 10px;
      line-height: 12px;
      font-weight: bold;
      text-align: center;

      @include desktopTablet {
        position: absolute;
        top: 24px;
        left: 19px;
        height: 15px;
        width: 15px;
      }

      // Mobile
      @include mobileTablet {
        position: absolute;
        top: 82px;
        left: 19px;
        height: 14px;
        width: 14px;
        padding-left: 0;
      }
    }
  }

  .headerCross {
    border: none;
    font-size: 15px;
    padding: 0;

    &:hover {
      background-color: unset;
    }
  }
}

.notificationsListContainer {
  flex-grow: 1;
  overflow: hidden;

  // Mobile & portrait Tablet
  @include mobileTablet {
    height: -moz-calc(100vh - 120px);
    height: -webkit-calc(100vh - 120px);
    height: calc(100vh - 120px);
  }

  :global(.infinite-scroll-component__outerdiv) {
    height: 100%;
  }

  .infiniteScroll {
    max-height: 100%;

    // Mobile & portrait Tablet
    @include mobileTablet {
      height: -moz-calc(100vh - 120px) !important;
      height: -webkit-calc(100vh - 120px) !important;
      height: calc(100vh - 120px) !important;
    }
  }
}

ul.notificationsList {
  margin: 0;
  padding: 0;
  list-style: none;
  height: inherit;

  > li {
    margin: 0 10px;
    cursor: pointer;
  }

  > :not(:first-child) {
    border-top: 1px solid rgba($white, 0.2);
  }

  span.headerDate {
    font-size: 12px;
    line-height: 12px;
    text-align: left;
    color: #b4becb;
    margin-left: 6px;
  }
}
