@import "defaults";

//--------- Media queries ---------//
@mixin desktop() {
  @media (min-width: $breakpoint-lg-min) {
    @content;
  }
}

@mixin tablet() {
  @media (min-width: $breakpoint-sm-min) and (max-width: $breakpoint-md-max) {
    @content;
  }
}

@mixin desktopTablet() {
  @media (min-width: $breakpoint-md-min) {
    @content;
  }
}

@mixin mobileTablet() {
  @media (max-width: $breakpoint-sm-max) {
    @content;
  }
}

@mixin mobile() {
  @media (max-width: $breakpoint-xs-max) {
    @content;
  }
}

@mixin containerPadding {
  @include desktop {
    padding-left: $page-margin-x-lg;
    padding-right: $page-margin-x-lg;
  }

  @include tablet {
    padding-left: $page-margin-x-md;
    padding-right: $page-margin-x-md;
  }

  @include mobile {
    padding-left: $page-margin-x-sm;
    padding-right: $page-margin-x-sm;
  }
}

@mixin not-desktop() {
  @media (max-width: $breakpoint-md-max) {
    @content;
  }
}

@mixin revertContainerPadding {
  @include desktop {
    margin-left: -$page-margin-x-lg;
    margin-right: -$page-margin-x-lg;
  }

  @include tablet {
    margin-left: -$page-margin-x-md;
    margin-right: -$page-margin-x-md;
  }

  @include mobile {
    margin-left: -$page-margin-x-sm;
    margin-right: -$page-margin-x-sm;
  }
}

@mixin blockMargin($dir) {
  @include desktop {
    margin-#{$dir}: $block-margin-y-lg;
  }

  @include tablet {
    margin-#{$dir}: $block-margin-y-md;
  }

  @include mobile {
    margin-#{$dir}: $block-margin-y-sm;
  }
}

//---------- Functions ----------//
@function clientGradient($direction: to right) {
  @return linear-gradient($direction, $client-gradient-blue 0, $client-gradient-red 100%);
}

%underlined-link {
  padding: 0 0 0.2rem;
  border: 0;
  background: linear-gradient($cyan-light, $cyan-light) no-repeat left bottom;
  background-size: 100% 1px;
  text-decoration: none;
  color: $neutral-darker;
  transition: background-size $transition-duration-fast cubic-bezier(0.4, 0, 0.2, 1);

  &--with-icon {
    position: relative;
    margin-left: 2.1rem;
    overflow: visible;

    .icon {
      position: absolute;
      top: 50%;
      right: calc(100% + 1.5rem);
      font-size: 1.2rem;
      color: $cyan-light;
      transform: translate(50%, -50%);
    }
  }

  &--gradient {
    background-image: clientGradient(to right);

    .icon {
      color: $client-gradient-red;
      background: clientGradient(135deg);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &:hover,
  &:focus,
  &:active {
    outline: none;
    background-size: 50% 1px;
  }
}
