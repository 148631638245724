@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.root {
  background-color: $grey-light;
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 0 auto;

  &.odd {
    background-color: $white;
  }

  .kitchen {
    .kitchenCheckbox {
      align-items: center;
      margin-left: 0;
    }

    .checkboxLabel {
      color: $black;
      margin-left: 0;
    }

    .checkboxLabelTitle {
      display: flex;
      font-weight: 400;
      padding: 0 0 0 25px;
    }
  }
}

.header {
  background-color: $primary-dark-blue;
  height: $lot-detail-header-height;
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
  // Mobile & portrait Tablet
  @include mobileTablet {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 30px;
  }

  .returnBtn {
    background: none;
    border: none;
    padding: 0;

    span {
      // Mobile & portrait Tablet
      @include mobileTablet {
        display: none;
      }
    }
  }

  .returnBtn {
    align-items: center;
    color: $pure-white;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;

    left: 40px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    // Mobile & portrait Tablet
    @include mobileTablet {
      position: initial;
      transform: unset;
    }
  }

  .returnBtnIcon {
    color: $pure-white;

    font-size: 20px;
    margin-right: 6px;

    @include mobileTablet {
      font-size: 24px;
      margin: 0;
    }
  }

  .name {
    align-items: flex-end;
    color: $pure-white;
    display: flex;
    font-weight: 500;
    justify-content: space-between;
    line-height: 1;
    font-size: 26px;

    @include mobileTablet {
      font-size: 32px;
    }
  }
}

.headerSuccess {
  background: $green;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  background-color: $grey-light;

  .lotCardWrapper {
    background-color: $pure-white;
    display: flex;
    justify-content: center;
    padding: 12px 51px;

    @include mobileTablet {
      background-color: $grey-light;
      display: block;
      padding: 12px 0;
      width: 100%;
    }
  }

  .lotCard {
    width: 400px;

    @include mobileTablet {
      width: 100%;
    }
  }

  .contentHeader {
    width: 100%;
    background-color: $pure-white;
    display: flex;
    justify-content: center;

    @include mobileTablet {
      flex-direction: column-reverse;
    }

    .lotCard {
      width: 400px;

      @include mobileTablet {
        background-color: $grey-light;
        padding: 12px 0;
        width: 100%;
      }
    }

    .contract {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;

      @include mobileTablet {
        padding: 16px 0;
      }

      .contractLabel {
        font-weight: 500;
      }

      .contractDate {
        color: $pure-grey;
        font-size: 12px;
        margin: 0 0 4px;
      }

      .contractDownload {
        background: transparent;
        border: 0;
        color: $primary-blue;
        font-weight: 500;
        text-decoration: underline;
        text-transform: uppercase;
      }
    }
  }

  .contentBody {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .formContainer {
    background-color: $pure-white;
    border-radius: 4px;
    margin: 25px auto 40px;

    @include desktopTablet {
      width: 945px;
    }

    @include not-desktop {
      margin: 40px auto;
    }

    @include mobileTablet {
      margin: 0;
    }
  }

  .stepperContainer {
    div {
      justify-content: center;
    }
  }

  .promoContainer {
    margin: 25px auto 0;
    width: 100%;

    @include desktopTablet {
      width: 945px;
    }

    @include mobileTablet {
      margin: 25px auto;
      padding: 0 24px;
    }
  }
}
// TODO remove after Pinels taxes cleanup
.taxError {
  padding: 12px 24px;
  background-color: $grey-light;
  font-weight: 300;
  font-size: 40px;
  text-align: center;

  @include not-desktop {
    padding: 24px;
    font-weight: 500;
    font-size: 22px;
  }
}

.form {
  background-color: $pure-white;
  padding: 10px 42px;

  @include mobileTablet {
    padding: 24px;
  }

  .title {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 26px;
    color: $pure-black;
    padding-bottom: 20px;

    @include mobile {
      flex-direction: column;
      align-items: flex-start;
    }

    > span {
      margin-right: 10px;
    }
    .pastille {
      margin-top: 5px;
    }

    .titleTag {
      margin: 0 0 -8px 16px;
      letter-spacing: 1px;

      @include mobile {
        margin: 16px 0 0;
      }
    }

    .titleTagLabel {
      font-weight: 700;
    }

    .deposit {
      margin: 5px 0 0 10px;
    }
  }

  .titleLine {
    @include mobile {
      flex-direction: row;
    }
  }

  .separator {
    display: flex;
    flex: none;
    order: 5;
    align-self: center;
    flex-grow: 0;
    margin: 40px 0;
    border: 1px solid $grey;
  }

  .info {
    max-width: 470px;
    margin-bottom: 15px;
    font-size: 14px;
    color: $black;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: flex-start;

    @include desktopTablet {
      margin: 0 -10px;
    }

    @include mobileTablet {
      display: flex;
      flex-direction: column;
    }

    .input {
      margin: 0 10px 12px;

      > div {
        background-color: $pure-white;
      }

      // Mobile & portrait Tablet
      @include mobileTablet {
        margin: 0 0 12px;
        width: 100%;
      }
    }
  }

  .input {
    padding-bottom: 8px;
    display: flex;
    align-items: center;

    &.displayColumn {
      align-items: flex-start;
      flex-direction: column;
    }

    .subtitle {
      font-size: 16px;
      line-height: 19px;
      color: $pure-grey;
      // flex: none;
      // order: 0;
      // flex-grow: 0;
      margin: 0 20px 0 0;
    }

    .noFiscality {
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      color: $primary-dark-blue;
      margin: 0 20px;
    }

    .pastille {
      margin-right: 20px;
    }

    .ptz {
      margin-left: 20px;
    }
  }

  .inputAdditionalDocuments {
    margin-bottom: 40px;
    flex-wrap: wrap;

    .formControlLabel {
      margin: 0 16px 16px 0;
    }
  }

  .percentageFeesTips {
    flex-direction: column;
    align-items: flex-start;
    color: $black;
    p {
      margin: 0;
      padding: 0;
      font-size: 14px;
      line-height: 18px;
      &.bold {
        font-weight: 500;
      }
    }
  }

  .section {
    padding-bottom: 20px;
    color: $black;
  }
}

.noOwnership {
  font-size: 24px;
  font-weight: 500;
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding: 0 78px 10px;
  background-color: $pure-white;

  // Mobile
  @include mobileTablet {
    flex-direction: column-reverse;
    padding: 24px 24px;
  }

  .cancelButton {
    font-family: $vinciFontName;
    margin-right: 16px;
    // Mobile
    @include mobileTablet {
      width: auto;
    }
  }
  .createButton {
    font-family: $vinciFontName;
    // width: 240px;
    @include mobileTablet {
      width: auto;
      margin-bottom: 10px;
    }
  }

  &Sign {
    justify-content: flex-start;
  }
}

.lastSend {
  color: $pure-grey;
  font-style: italic;
  margin: 0;
  padding: 0 0 16px;
  text-align: center;
}

.errorFooter {
  justify-content: flex-end;
  padding: 0;

  .errorContent {
    width: 100%;
    background-color: rgba(224, 60, 49, 0.04);
    color: $red;
    padding-top: 16px;
    padding-bottom: 40px;

    @include mobileTablet {
      padding-top: 31px;
      padding-bottom: 20px;
      padding-right: 0;
    }

    .center {
      margin: auto;
      h4 {
        font-size: 22px;
        line-height: 30px;
        text-align: center;
        font-weight: 500;
        padding: 0;
        margin: 0;

        @include mobileTablet {
          padding: 0 45px 20px;
        }
      }
      p {
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        padding: 0;
        margin: 6px 0 0;
        @include mobileTablet {
          padding: 0 28px;
        }
      }

      .errorDetails {
        font-style: italic;
        margin-top: 0;
      }
    }

    .right {
      position: relative;

      .iconButton {
        position: absolute;
        top: -5px;
        right: 35px;
        @include mobileTablet {
          top: -20px;
          right: 5px;
        }

        &:hover {
          background: none;
        }
        .svgIcon {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}

.helperTextDisplay {
  padding-top: 12px;
}

.paragraph {
  margin: 32px;
}

.titleContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: transparent;

  .name {
    color: $grey-3;
    font-size: 20px;
    font-weight: 500;
    margin: 0 0 3px;
  }

  .contact {
    font-size: 14px;
    font-weight: 400;
    margin: 0 0 3px;
  }
}

.loading {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: white;
}

.loader {
  display: flex;
  justify-content: center;
  width: 100%;
}

.salesAidsItem {
  display: flex;
  margin: 0 0 15px 0;
}

.infoText {
  color: $black;
  margin: 5px 0 0;
  font-size: 14px;
  font-weight: 500;
}
