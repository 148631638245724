.container {
}

.sorter {
  display: flex;
  height: 60px;
  justify-content: flex-end;
  align-items: center;
  padding: 0 23px;
}
