@import "src/assets/styles/1-settings";

.root {
  .label {
    font-family: $vinciFontName;
    font-size: 16px;
    line-height: 19px;
    color: $black;
  }
  .error {
    font-family: $vinciFontName;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: $red;
  }
}

.iconRoot {
  background-color: unset !important;
}

.helperTextRoot {
  font-family: $vinciFontName !important;
  font-size: 16px !important;
  margin: 4px 0 0 !important;

  &.helperTextError {
    color: #dc3545 !important;
  }
}
