// Layout
$page-max-width: 128rem;

$page-margin-x-lg: 8rem;
$page-margin-x-md: 3.2rem;
$page-margin-x-sm: 2rem;

// Breakpoints
$breakpoint-xs-min: 576px;
$breakpoint-sm-min: 768px;
$breakpoint-md-min: 992px;
$breakpoint-lg-min: 1280px;
$breakpoint-xl-min: 1440px;

$breakpoint-xxs-max: $breakpoint-xs-min - 1;
$breakpoint-xs-max: $breakpoint-sm-min - 1;
$breakpoint-sm-max: $breakpoint-md-min - 1;
$breakpoint-md-max: $breakpoint-lg-min - 1;
$breakpoint-lg-max: $breakpoint-xl-min - 1;

// Layout
$block-border-radius: 1rem;
$block-margin-y-lg: 6rem;
$block-margin-y-md: 3.2rem;
$block-margin-y-sm: 2rem;

//---------- Colors ----------//
// stylelint-disable color-no-hex

$white: #ffffff;
$black: #000000;

// Primary
$cyan-dark: #23d7b0;
$cyan-light: #50e3c2;

// Secondary
$blue: #003c71;

// Neutral
$neutral-black: #1b2633;
$neutral-grey: #aaaaaa;
$neutral-darker: #536373;
$neutral-light: #d6d6d6;

// Text
$text-dark: $neutral-black;
$text-light: $neutral-grey;

// Transitions
$transition-duration-fast: 0.25s;

// Client pages
$client-gradient-blue: #3c1cc2;
$client-gradient-red: #bb236c;

// Buttons
$buttons-height: 4.5rem;
$buttons-padding-top: 0.5rem;
$buttons-padding-bottom: 0.8rem;
$buttons-hover-offset: 0.2rem;

//Shadow
$default-shadow-color: #0f498b;
$default-shadow: rgba($default-shadow-color, 0.1);

// Custom Scrollbar
$scrollbar-thickness: 5px;
$scrollbar-container-thickness: $scrollbar-thickness + 2;
