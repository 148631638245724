@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

.root {
  > :not(:first-child) {
    margin-top: 20px;
  }
}

.item {
  display: flex;
  align-items: center;

  .image {
    @include not-mobile {
      max-width: 136px;
      margin-right: 24px;
    }

    @include mobile {
      max-width: 100px;
      margin-right: 12px;
    }
  }

  .text {
    @include not-mobile {
      font-size: 18px;
    }

    @include mobile {
      font-size: 14px;
    }
  }
}
