@import "../../../common/assets/styles/main";
@import "KelQuartierSettings.module";

$map-poi-width: 20rem;

.root.vi3p {
  margin: 0;
}

.container.vi3p {
  padding: 0;
}

.poisContainer.vi3p {
  z-index: 20;
}

.poi.vi3p-search {
  display: block;

  @include desktop {
    width: 300px;
    top: 10px;
    right: 10px;
    bottom: -500px;
    padding-top: 57px;
  }

  @include tablet {
    width: 300px;
    top: 10px;
    right: 10px;
    bottom: calc(-45vh + 90px);
    padding-top: 57px;
  }

  @include mobile {
    top: 0;
    right: $page-margin-x-sm;
    bottom: calc(-100vh + 56px);
    left: $page-margin-x-sm;
  }

  &.poiClosed {
    bottom: 10px;
  }
}

.poi.vi3p-program {
  width: $map-poi-width;

  @include desktop {
    top: -738px;
    right: 25px;
    bottom: 110px;
    width: 300px;
    padding-top: 64px;
  }

  @include tablet {
    top: 0;
    right: -14.5rem;
    height: 35rem;
    width: auto;
  }

  &.poiClosed {
    bottom: 738px;
  }
}

.actionXL.vi3p {
  display: flex;
  justify-content: center;
  align-items: center;
  width: $map-poi-width;
  margin: 0;

  @include desktop {
    top: -738px;
    right: 25px;
    width: 300px;
    height: 64px;
  }

  @include tablet {
    top: 0.6rem;
    right: -13rem;
    width: auto;
  }
}

.actionXL.vi3p-search {
  @include mobile {
    display: none;
  }
}

.actionSM.vi3p {
  width: $map-poi-width;
  z-index: 20;

  @include mobileTablet {
    top: -626px;
  }
}

.button.vi3p {
  margin: 0 auto;
  height: 2.5rem;
  font-size: 0.8rem;
}

.drawer.vi3p {
  @include desktop {
    display: none;
  }
}
