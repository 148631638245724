@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.root {
  font-size: 18px;
}

.infos {
  display: flex;
  align-items: flex-start;
}

.image {
  width: 56px;
  flex: 0 0 auto;
  margin-top: 5px;
  margin-right: 20px;
}

.text {
  display: flex;
  flex-direction: column;
}

.name {
  font-weight: 500;
}

.description {
  font-size: inherit;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;

  @include mobile {
    margin: 0 -10px;
  }

  .buttonsItem {
    padding: 20px;

    @include mobile {
      padding: 10px;
    }
  }
}
