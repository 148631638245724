@import "src/assets/styles/1-settings";

.root {
  &.disabled {
    .icon {
      color: $pure-grey;
    }
  }

  &.error {
    .icon {
      color: $red;
    }
  }

  .labelRoot {
    color: $pure-grey;
    font-family: $vinciFontName;
    font-size: 16px;
    transform: translate(16px, 16px) scale(1);

    &:global(.MuiFormLabel-filled) {
      left: 2px;
      top: 12px;

      + :global(.MuiInputBase-root) .selectRoot {
        padding: 20px 16px 0;
      }
    }

    &.labelError {
      color: $red;

      > span {
        color: $red;
      }

      &.labelFocused {
        color: $red;
        transform: translate(16px, 16px) scale(1);
      }

      + :global(.MuiInputBase-root) .selectRoot {
        border-color: $red;
      }
    }

    &.labelFocused {
      color: $primary-dark-blue;
    }
  }

  .selectRoot {
    $height: 48px;
    $fontSize: 16px;
    $lineHeight: 1.1875;
    $borderWidth: 1px;

    align-items: center;
    background-color: $pure-white;
    border: $borderWidth solid $grey;
    border-radius: 6px;
    box-sizing: border-box;
    color: $primary-dark-blue;
    font-family: $vinciFontName;
    font-size: $fontSize;
    line-height: $lineHeight;
    font-weight: 500;
    height: $height;
    padding: (($height - $fontSize * $lineHeight - 2 * $borderWidth) / 2) 16px;

    &.selectDisabled {
      background-color: $white;
    }

    &.selectError {
      border-color: $red;
      color: $red;
    }

    &:focus {
      background-color: $pure-white;
      border-radius: 6px;
    }
  }

  :global(.Mui-focused) {
    .selectRoot {
      border-color: $primary-dark-blue;
    }
  }

  :global(.MuiOutlinedInput-notchedOutline) {
    border: 0;
    display: none;
    padding: 0 40px;
    top: 0;
  }

  .helperTextRoot {
    font-family: $vinciFontName;
    font-size: 16px;
    margin: 4px 0 0;

    &.helperTextError {
      color: $red;
    }
  }

  .icon {
    color: $primary-dark-blue;
    font-size: 8px;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.listRoot {
  .menuItemRoot {
    color: $pure-black;
    font-family: $vinciFontName;
    font-size: 16px;
  }

  .menuItemEmpty {
    color: $pure-grey;
  }
}
