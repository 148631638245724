*,
*::before,
*::after {
  box-sizing: inherit;
}

:root {
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: $vinci;
  background-color: $white;
  font-size: 14px;
}

a {
  text-decoration: none;
}

button {
  font: inherit;
  color: inherit;
  cursor: pointer;
}

strong {
  font-weight: 500;
}

.left {
  float: left;
}

.right {
  float: right;
}

.hidden-lg {
  display: none;
}

body.scroll-lock {
  height: 100vh;
  overflow: hidden;
}

// *******************
// GMAP z-index
.gm-style-pbc {
  z-index: 20 !important;
  pointer-events: none !important;
}

// *******************
// Datas loader
.search-loader {
  z-index: 9999;
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  animation: searchLoaderFadeIn 0.75s ease-in-out;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0.77;
    background: #fff;
  }
}

.infinite-loader {
  z-index: 9999;
  overflow: hidden;
  padding: 1em 0;
  width: 100%;

  div {
    margin: auto;
  }

  &--full-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}

.images-loader {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  background-color: $white;

  div {
    display: inline-block;
    position: absolute;
    left: 6px;
    width: 13px;
    background: $blue;
    animation: images-loader-keyframes 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;

    &:nth-child(1) {
      left: 6px;
      animation-delay: -0.24s;
    }

    &:nth-child(2) {
      left: 26px;
      animation-delay: -0.12s;
    }

    &:nth-child(3) {
      left: 45px;
      animation-delay: 0s;
    }
  }
}

@keyframes images-loader-keyframes {
  0% {
    top: 6px;
    height: 51px;
  }

  50%,
  100% {
    top: 19px;
    height: 26px;
  }
}

// *******************
// Bootstrap custom

.container,
.container-fluid {
  width: 100%;
  max-width: 100%;
  padding: 0 $grid-gap;
}

.container {
  margin-right: auto;
  margin-left: auto;

  @media screen and (max-width: $breakpoint-sm-max) {
    padding: 0 30px;
  }

  @media screen and (min-width: $breakpoint-sm-min) {
    width: 750px !important;
  }

  @media screen and (min-width: $breakpoint-md-min) {
    width: 970px !important;
  }

  @media screen and (min-width: $breakpoint-lg-min) {
    width: 1170px !important;
  }

  @media screen and (max-width: 1280px) {
    width: 97% !important;
  }

  @media screen and (min-width: 1281px) {
    width: 1280px !important;
  }
}

.row {
  margin-left: -15px;
  margin-right: -15px;

  &::before,
  &::after {
    content: " ";
    display: table;
  }
}

[class*="col-"] {
  float: left;
}

.row-noFloatting {
  [class*="col-"] {
    float: none;
    display: inline-block;
    vertical-align: top;
  }
}

// XS
@for $i from 1 through 12 {
  .col-xs-#{$i} {
    width: 100% / (12 / $i);
  }

  .col-xs-offset-#{$i} {
    margin-left: 100% / (12 / $i);
  }

  .hidden-xs {
    display: none;
  }
}

// SM
@media (min-width: $breakpoint-sm-min) {
  @for $i from 1 through 12 {
    .col-sm-#{$i} {
      width: 100% / (12 / $i);
    }

    .col-sm-offset-#{$i} {
      margin-left: 100% / (12 / $i);
    }
  }

  .hidden-sm {
    display: none;
  }
}

// MD
@media (min-width: $breakpoint-md-min) {
  @for $i from 1 through 12 {
    .col-md-#{$i} {
      width: 100% / (12 / $i);
    }

    .col-md-offset-#{$i} {
      margin-left: 100% / (12 / $i);
    }
  }

  .hidden-md {
    display: none;
  }
}

// LG
@media (min-width: $breakpoint-lg-min) {
  @for $i from 1 through 12 {
    .col-lg-#{$i} {
      width: 100% / (12 / $i);
    }

    .col-lg-offset-#{$i} {
      margin-left: 100% / (12 / $i);
    }
  }

  .hidden-lg {
    display: none;
  }
}
