@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

$button-height: 46px;

.root {
  width: 230px;
  position: fixed;
  right: 20px;
  bottom: 35px;
  z-index: 101;
  overflow: hidden;
  border-radius: $button-height;
  background: $pure-white;
  box-shadow: 0 3px 10px #00000029;
  transition: border-radius $transition-duration-fast ease-in-out;

  &.open {
    border-radius: 10px;

    .toggle {
      border-radius: 10px 10px 0 0;
    }
  }
}

.container {
  @include desktopTablet {
    display: flex;
    width: 200%;
    align-items: flex-start;
    position: relative;
    transition: transform 150ms 50ms ease-in-out, height 150ms ease-in-out;

    &.active {
      transform: translate(-50%, 0);
    }

    .section {
      width: 50%;
      flex: 1 0 auto;
    }
  }

  @include mobileTablet {
    height: 100%;
  }
}

.toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  padding: 0;
  font-weight: 500;
  font-size: 16px;
  background: $primary-blue;
  color: $pure-white;
  transition: $transition-duration-fast ease-in-out;
  transition-property: border-radius, background-color;

  &:hover,
  &:focus {
    background: $primary-dark-blue;
  }

  .icon {
    width: 1em;
    height: 1em;
    font-size: 30px;
  }

  @include desktopTablet {
    width: 100%;
    height: $button-height;
    border-radius: $button-height;
    padding: 0 20px;

    .icon {
      margin-right: 10px;
    }

    .text {
      margin-bottom: 0.2em;
    }

    &:hover {
      .icon:global {
        animation: vibrate 1.25s infinite ease-in-out;
      }
    }
  }

  @include mobileTablet {
    width: 50px;
    height: 50px;
    position: fixed;
    right: 20px;
    bottom: 17px;
    z-index: 10000;
    border: 0;
    border-radius: 100%;
    background: $primary-blue;
    font-size: 30px;
    color: $pure-white;

    .icon:global {
      animation: vibrate-spaced 10s 10s infinite ease-in-out;
    }
  }
}

.list {
  display: flex;
  flex-direction: column;

  .item {
    display: flex;
    align-items: center;
    margin: 0 16px;
    border: 0;
    padding: 16px 0;
    background: none;

    &:not(:last-child) {
      border-bottom: 1px solid $grey-light;
    }

    &::after {
      @extend %icomoon;
      content: map-get($icomoon, "chevron-down");
      font-size: 10px;
      color: $primary-dark-blue;
      transform: rotate(-90deg);
    }

    .img {
      width: 43px;
      flex: 0 0 auto;
      position: relative;
      overflow: hidden;
      margin-right: 15px;
      padding: 5px 0;

      img {
        display: block;
        width: 100%;
        position: relative;
        z-index: 10;
        border-radius: 10px 0;
      }

      &::before {
        content: "";
        height: 56.7%;
        width: 54.3%;
        position: absolute;
        top: 0;
        left: 7%;
        z-index: 0;
        border: solid $blue;
        border-width: 2px 0 0 2px;
        border-top-left-radius: 10px;
        pointer-events: none;
      }

      &::after {
        content: "";
        width: 73%;
        height: 74.4%;
        position: absolute;
        right: 10%;
        bottom: 0;
        z-index: 20;
        border: solid $primary-blue-softer;
        border-width: 0 2px 2px 0;
        border-bottom-right-radius: 10px;
        pointer-events: none;
      }
    }

    .content {
      flex: 1 0 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: $primary-dark-blue;

      &:only-child {
        align-self: center;
      }
    }

    .name {
      font-weight: 500;
      font-size: 14px;
      line-height: 1.2;
      text-align: left;
    }

    .role {
      margin-top: 0.2rem;
      font-size: 11px;
    }
  }
}

.detail {
  .return {
    display: flex;
    align-items: center;
    margin: -10px 0 0 -10px;
    padding: 10px;
    border: 0;
    background: none;
    font-weight: 500;
    font-size: 12px;
    color: $primary-blue;
    transition: color $transition-duration-fast ease-in-out;

    &::before {
      @extend %icomoon;
      content: map-get($icomoon, "chevron-down");
      margin-right: 6px;
      font-size: 8px;
      transform: rotate(90deg);
    }

    &:hover,
    &:focus {
      color: $primary-dark-blue;
    }
  }

  @include desktopTablet {
    $padding: 22px;

    position: relative;
    padding: $padding;

    .toggle {
      width: calc(100% + #{2 * $padding});
      margin: (-$padding) (-$padding) $padding (-$padding);
    }

    .item {
      &:not(.active) {
        width: calc(100% - #{2 * $padding});
        position: absolute;
        visibility: hidden;
      }
    }
  }

  @include mobileTablet {
    height: 100%;
    overflow: auto;
    padding: 0 30px;

    .item {
      padding: 30px 0;

      &:not(:last-child) {
        border-bottom: 1px solid $grey-light;
      }
    }
  }
}

.drawerHeader {
  height: 66px;
  display: flex;
  align-items: center;
  padding: 0 25px 0 40px;
  background: $primary-dark-blue;
  color: $pure-white;

  .title {
    flex: 1 0 auto;
    font-weight: 500;
    font-size: 22px;
  }

  .close {
    margin: -10px -10px -10px 0;
    border: 0;
    padding: 10px;
    background: none;
    font-size: 15px;
  }
}
