@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

@include desktopTablet {
  .root::before,
  .header::before {
    width: 1em;
    height: 1em;
    position: absolute;
    right: 5px;
    font-size: 10px;
    transform: rotate(45deg);
  }
}

.root {
  flex: 1 0 auto;
  position: relative;
  background: $white;
  border-radius: inherit;
  font-weight: 400;

  @include desktopTablet {
    width: 240px;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0px rgba(0, 0, 0, 0.14),
      0 1px 3px 0px rgba(0, 0, 0, 0.12);

    &::before {
      bottom: -0.5em;
      border-bottom-right-radius: 2px;
      background: inherit;
      box-shadow: 1px 2px 1px -1px #0003, 1px 1px 1px 0px #00000024, 1px 1px 3px -1px #0000001f;
    }

    &.top-end {
      margin-bottom: 10px;

      &::before {
        content: "";
      }
    }

    &.bottom-end {
      margin-top: 10px;

      .header::before {
        content: "";
      }
    }

    &.left-start {
      margin-right: 10px;

      .header::before {
        content: "";
        right: -5px;
        top: 10px;
      }
    }
  }
}

.header {
  display: flex;
  align-items: center;
  position: relative;
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
  padding: 10px 20px;
  background: $primary-dark-blue;
  color: $pure-white;

  @include desktopTablet {
    height: 80px;

    &::before {
      top: -0.5em;
      border-top-left-radius: 2px;
      background: inherit;
    }
  }

  @include mobileTablet {
    height: 100px;
  }
}

.headerTitle {
  margin: 0;
  font-weight: 500;
  font-size: 20px;

  @include mobileTablet {
    font-size: 32px;
  }
}

.close {
  position: absolute;
  top: 8px;
  right: 12px;
  border: 0;
  padding: 0;
  background: none;
  font-size: 15px;

  @include mobileTablet {
    font-size: 23px;
  }
}

.section {
  overflow: hidden;
  padding: 20px 16px;

  &:not(:first-child) {
    border-top: 1px solid $grey-light;
  }
}

.sectionTitle {
  margin: 0 0 16px;
  font-weight: 500;
  font-size: 18px;
  line-height: 1;
}

.sectionContent {
  padding-left: 8px;
  line-height: 1.25;
  color: $black;

  .footnote {
    display: block;
  }
}

.priceIcon {
  margin-right: 12px;
  font-size: 16px;
  color: $pure-grey;
}

.loader {
  display: flex;
  justify-content: center;
}
