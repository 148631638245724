.vinci-youtube-player {
  width: 640px;
  position: relative;
  overflow: hidden;
  background: $black;

  &::before {
    content: "";
    display: block;
    padding-top: 56.25%;
  }

  iframe {
    width: 100%;
    height: 100%;
  }

  .vinci-youtube-preview {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 10;
    transform: translateY(-50%);
    transition: $transition-duration-fast ease-in-out;
    transition-property: opacity, visibility;

    @supports (object-fit: cover) {
      height: 100%;
      top: 0;
      object-fit: cover;
      object-position: center center;
      transform: none;
    }
  }

  &.started {
    .vinci-youtube-preview {
      opacity: 0;
      visibility: hidden;
    }
  }

  &.playing {
    .vinci-youtube-overlay {
      opacity: 0;
      visibility: hidden;
    }
  }
}

.vinci-youtube-overlay,
.vinci-youtube-video {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.vinci-youtube-overlay {
  z-index: 20;
  padding: 0;
  border: 0;
  background: rgba(0, 0, 0, 0.5);
  font-size: 0;
  color: $pure-white;
  transition: 0.25s ease-in-out;
  transition-property: opacity, visibility;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 16px;
    cursor: pointer;
  }

  &::before {
    border-radius: 100%;
    border: 1px solid;
    width: 75px;
    height: 75px;
    transform: translate(-50%, -50%);
  }

  &::after {
    border-left: 1em solid currentColor;
    border-top: 0.6em solid transparent;
    border-bottom: 0.6em solid transparent;
    border-radius: 10%;
    transform: translate(-30%, -50%);
  }

  &:hover {
    &::before {
      background: $pure-white;
    }

    &:after {
      border-left: 1em solid $text;
    }
  }
}

@media (max-width: $screen-sm-max) {
  .vinci-youtube-overlay {
    font-size: 21px;
  }
}
