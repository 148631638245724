@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.root {
  z-index: 2000 !important;

  .paper {
    background-color: transparent;
    box-shadow: none;
    height: 74vh;
    overflow: visible;
    width: 85vw;
  }

  .close {
    background: none;
    border: 0;
    fill: white;
    margin: 0;
    padding: 0;
    position: fixed;
    right: 24px;
    top: 24px;
  }

  .svgIcon {
    height: 24px;
    width: 24px;
  }

  .video {
    margin: auto 0;
    width: 100%;
  }
}
