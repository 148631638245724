@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.list {
  list-style: none;
  margin: 8px 0 0;
  padding: 0;
}

.listItem {
  font-size: 14px;
  margin: 6px 0;
  padding: 0 0 0 10px;
  position: relative;

  &:before {
    background-color: $grey;
    border-radius: 50%;
    content: "";
    height: 4px;
    left: 0;
    position: absolute;
    top: 8px;
    width: 4px;
  }
}

.value {
  color: $primary-dark-blue;
  font-weight: 500;
}

.pricesItem {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px;

  @include mobileTablet {
    flex-direction: column;
  }
}

.gridCol {
  flex: 0 0 auto;
  padding: 0 16px;
  width: 50%;

  @include not-desktop {
    min-width: 220px;
    width: auto;
  }

  @include mobileTablet {
    min-width: 0;
    width: 100%;
  }
}

.availabilities {
  margin: 8px 0 0;
}

.label {
  color: $pure-grey;
  font-size: 12px;
}

a.label {
  display: block;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.right {
  text-align: right;
}
