@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

.root {
  position: relative;

  .button {
    border: 1px solid $primary-blue;
    border-radius: 6px;
    padding: 10px 14px;

    .icon {
      fill: $pure-white;
      height: 21px;
      margin: 0 10px 0 0;
      width: 21px;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: $primary-blue-light;
      border-color: $primary-dark-blue;
      color: $primary-dark-blue;

      .icon {
        fill: $primary-dark-blue;
      }
    }

    &.disabled {
      background-color: $grey-light;
      border-color: $grey-light;
      color: $black;
      opacity: 1;

      .icon {
        fill: $black;
      }
    }

    &.hiddenLabel {
      .icon {
        margin: 0;
      }

      .labelText {
        display: none;
      }
    }
  }
}

.popin {
  background-color: $pure-white;
  border: 1px solid $grey;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(15, 73, 139, 0.104988);
  padding: 16px;
  position: absolute;
  right: 10px;
  top: calc(100% + 10px);
  width: 370px;
  z-index: 500;

  @include mobile {
    left: -10px;
    width: calc(100vw - 12px);
  }
}

.popinClose {
  background: none;
  border: none;
  color: $primary-dark-blue;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 16px;
  top: 11px;
}

.popinTitle {
  color: $pure-black;
  font-size: 20px;
  font-weight: 500;
}

.popinText {
  color: $pure-grey;
  font-size: 16px;
  font-weight: 500;
  margin: 7px 0 0 0;
}

.popinButtonWrapper {
  border-top: 1px solid $grey;
  margin: 12px 0 0;
  padding: 11px 0 0;
}

.popinBlocked,
.popinError {
  background-color: $pure-white;
  border: 1px solid $grey;
  border-radius: 12px;
  bottom: 100%;
  box-shadow: 0 4px 15px rgba(15, 73, 139, 0.104988);
  left: 50%;
  padding: 24px 48px 24px 22px;
  position: absolute;
  transform: translateX(-50%);
  width: 410px;
  z-index: 500;

  @include mobile {
    left: 0;
    transform: none;
    width: calc(100vw - 12px);
  }
}

.popinBlockedContent {
  align-items: flex-start;
  display: flex;
}

.popinBlockedContentIcon {
  height: 18px;
  margin: 3px 0 0;
  width: 18px;
}

.popinBlockedContentInfos {
  color: $black;
  padding-left: 8px;
}

.popinBlockedContentTextLarge {
  font-size: 18px;
  font-weight: 500;
}

.popinBlockedContentText {
  font-style: italic;
  font-size: 12px;
}

.popinError {
  padding: 22px 20px 22px 20px;
  width: 300px;
}

.popinErrorContent {
  color: $black;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

.popinErrorContentIcon {
  height: 18px;
  margin: -10px 10px 0 0;
  width: 18px;
}
