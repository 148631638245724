@import "../../../assets/styles/defaults";
@import "../../../assets/styles/tools";
@import "DrawerV4.module";
@import "DrawerVI3P.module";

.header {
  display: flex;
}

.title {
  flex-grow: 1;
  font-weight: 500;
  color: $blue;

  @include desktop {
    font-size: 5rem;
  }

  @include tablet {
    font-size: 4rem;
  }

  @include mobile {
    font-size: 3rem;
  }
}

.close {
  margin-left: 2rem;
  box-shadow: none;
}

.icon {
  display: block;
  margin-right: 2.4rem;
  font-size: 2.4rem;
  color: $cyan-light;
}

.root {
  font-size: 1.6rem;
  line-height: 1.1875;
  //font-family: $vinci;
  text-decoration: none;
  cursor: pointer;

  // standard style
  display: inline-flex;
  height: $buttons-height;
  justify-content: center;
  align-items: center;
  padding: $buttons-padding-top 3rem $buttons-padding-bottom;
  border: 0;
  border-radius: $buttons-height;
  background: none;
  font-weight: 500;
  color: inherit;
  transition: 0.1s ease-in-out;
  transition-property: padding-top, padding-bottom, border-color, background, color, box-shadow;

  &:focus {
    outline: none;
  }

  &:not(.withIcon):not(.link) {
    &:hover,
    &:focus,
    &:active {
      box-shadow: 0 10px 20px 0 $default-shadow;
    }

    &:hover:not(:active),
    &:focus:not(:active) {
      padding-top: $buttons-padding-top + $buttons-hover-offset;
      padding-bottom: $buttons-padding-bottom + $buttons-hover-offset;
    }
  }

  &[disabled] {
    pointer-events: none;

    > .text {
      color: $black;
      opacity: 0.4;
    }
  }

  :global {
    &.underlined-link {
      @extend %underlined-link;
    }
  }
}

button.round {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.875em;
  height: 2.875em;
  min-width: auto;
  min-height: auto;
  padding: 0.5rem;
  border-radius: 100%;
  box-shadow: 0 7px 17px 0 rgba($blue, 0.11);
  font-size: 1.6rem;

  .icon {
    margin-right: 0;
    font-size: 1.0625em;
  }
}

button.border {
  border: 1px solid $neutral-light;

  &:active {
    border-color: rgba($blue, 0.5);
  }
}
