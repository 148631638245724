@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

$infoWidthDesktop: 45%;

.root {
  .paper {
    display: flex;

    @include not-mobile {
      flex-direction: row;
      width: 840px;
      border-radius: 10px;
    }

    @include mobile {
      width: 100%;
      max-width: none;
      height: 100%;
      max-height: none;
      margin: 0;
      border-radius: 0;
    }
  }
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  padding: 20px;
  background: none;
  font-size: 15px;

  @include not-mobile {
    color: $blue-dark;
  }

  @include mobile {
    color: $pure-white;
  }
}

.info {
  border-bottom-right-radius: 150px;
  background: $blue-dark;
  color: $pure-white;

  @include not-mobile {
    width: $infoWidthDesktop;
    flex: 0 0 auto;
    padding: 90px 35px 50px;
  }

  @include mobile {
    padding: 72px 100px 20px 20px;
  }

  .label {
    @extend %program-label;
    position: absolute;
    top: 22px;
    left: 0;
    z-index: 10;
  }
}

.title {
  font-weight: 500;
  line-height: 1.15;

  @include not-mobile {
    margin-bottom: 10px;
    font-size: 28px;
  }

  @include mobile {
    margin-bottom: 10px;
    font-size: 26px;
  }
}

.location {
  @include not-mobile {
    margin-bottom: 28px;
    font-size: 18px;
  }

  @include mobile {
    margin-bottom: 20px;
    font-size: 17px;
  }
}

.pictos {
  display: flex;
  align-items: center;

  .lots {
    height: 30px;
    border-radius: 30px;
    padding: 5px 15px;
    background: $pure-white;
    font-weight: 500;
    font-size: 13px;
    color: $blue-dark;

    &:not(:first-child) {
      margin-left: auto;
    }
  }

  @include not-mobile {
    gap: 15px;
    margin-bottom: 35px;

    .picto {
      width: 50px;
      height: 50px;
    }
  }

  @include mobile {
    gap: 10px;
    margin-bottom: 25px;

    .picto {
      width: 40px;
      height: 40px;
    }
  }
}

.price {
  font-weight: 500;
  font-size: 18px;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
  margin: 20px 0 0;
  padding-top: 20px;

  &::before {
    content: "";
    width: 40px;
    height: 2px;
    position: absolute;
    top: 0;
    left: 0;
    background: $blue-light;
  }

  dt {
    display: inline;

    &::after {
      content: ' :';
      white-space: nowrap;
    }
  }
  dd {
    display: inline;
    margin: 0;
    padding-left: 1ch;
    font-weight: 500;
  }

  @include mobile {
    font-size: 14px;
  }
}

.description {
  .text {
    line-height: 1.2;
    font-size: 14px;
    color: $black;
  }

  @include not-mobile {
    width: (100% - $infoWidthDesktop);
    padding: 60px 50px 40px 60px;
  }

  @include mobile {
    display: flex;
    flex-direction: column;
    padding: 25px 20px;

    .text {
      order: 10;
    }
  }
}

.medias {
  @include not-mobile {
    margin-top: 30px;

    .slider {
      width: 30px;
      margin-left: 15px;
    }

    .selected {
      width: 100%;
      max-height: none;
      border-radius: 8px;
    }

    .slide {
      img {
        height: 20px;
        border: 1px solid transparent;
        border-radius: 3px;
      }

      &.active {
        img {
          border-color: $blue-dark
        }
      }
    }
  }

  @include mobile {
    $gutter: 15px;

    order: 0;
    margin-left: (-$gutter / 2);
    margin-bottom: 15px;

    :global {
      .slick-list {
        overflow: visible;
      }

      .slick-track {
        display: flex;

        .slick-slide {
          align-self: stretch;
          height: auto;

          > div {
            height: 100%;
          }
        }
      }
    }

    .media {
      padding: 0 ($gutter / 2);

      img {
        width: 240px;
        border-radius: 8px;
      }
    }
  }
}

.login {
  order: 20;

  .text {
    margin-bottom: 15px;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: $blue-dark;
  }

  .buttons {
    display: flex;
    gap: 15px;
  }

  @include not-mobile {
    margin-top: 28px;

    .buttons {
      justify-content: center;
      align-items: center;

      .link {
        height: 38px;
        font-size: 13px;
      }
    }
  }

  @include mobile {
    margin-top: 25px;

    .buttons {
      flex-direction: column;
    }
  }
}
