.root {
  position: relative;
  z-index: 0;

  &.placeholder {
    .image {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
