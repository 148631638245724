@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";
@import "src/assets/styles/variables";

.root {
  background-color: $blue;
  border-radius: 8px 8px 0 0;
  color: $pure-white;
  font-size: 22px;
  padding: 1px 24px 3px;
  text-align: center;
}

.rte {
  * {
    margin: 0;
  }
}
