@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.root {
  padding: 20px 24px;

  @include mobile {
    padding: 20px 0;
  }
}

.content {
  padding: 24px 0;
}

.withBg {
  .content {
    background-color: $white;
  }
}

.title {
  align-items: center;
  color: $primary-dark-blue;
  display: flex;
  justify-content: center;
  margin: 0 0 24px 0;

  @include mobile {
    flex-direction: column;
    margin: 0;
  }
}

.icon {
  margin: 0 20px 0 0;

  @include mobile {
    margin: 0;
  }
}

.titleIcon {
  font-size: 28px;
}

.titleSvgIcon {
  height: 28px;
  width: 28px;
}

.titleLabel {
  font-size: 32px;
  font-weight: 500;
}
