@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

$datesGutter: 8px;

.root {
  border-radius: 6px;

  &:empty {
    display: none;
  }
}

.top {
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
}

.dates {
  margin: 0;
  padding: 0 $program-booster-padding 6px;
  font-weight: 500;
  font-size: 18px;
  line-height: 1;
  color: $white;
}

.date {
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
}

.dateValue {
  margin-left: 10px;
  text-transform: uppercase;
}

.button {
  width: calc(100% - #{$program-booster-padding * 2});
  margin: 0 $program-booster-padding 24px;

  &:hover,
  &:focus,
  &:active {
    background: $white !important;
    color: $primary-dark-blue !important;
  }
}

.infos {
  background: $program-booster-background;
}

.programCardButtonWrapper {
  background: $program-booster-background;
}

.programCardButton {
  background: rgba($white, 0.1);
}

.actionsWrapper {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
  background: $program-booster-background;
}

.actions {
  padding: 0 $program-booster-padding;
}

.separator {
  height: 1px;
  padding: 12px 24px 10px;
  width: 100%;

  .separatorContent {
    background-color: $pure-white;
    height: 1px;
    width: 100%;
  }
}

.top {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  width: 100%;
}
