@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

$items-gutter: 12px;
$activeOutlineWidth: 2px;

.root {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  @include mobile {
    flex-direction: column;
  }
}

.activeMediaContainer {
  align-self: center;
  position: relative;
  overflow: hidden;

  @include mobile {
    width: 100%;
  }

  :global(.vinci-youtube-player) {
    @include mobile {
      width: 100%;
    }
  }
}

.activeMedia {
  display: block;
  max-height: 370px;
}

.galleryIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border: 1px solid $pure-grey;
  border-radius: 100%;
  background: $pure-white;
  font-size: 12px;
  color: $primary-dark-blue;
  transition: $transition-duration-fast ease-in-out;
  transition-property: border-color, background-color, color;
}

.galleryBtn {
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  outline: none;
  padding: 1rem;
  background: none;
  z-index: 10;

  &:hover,
  &:focus,
  &:active {
    .galleryIcon {
      border-color: transparent;
      background: $primary-dark-blue;
      color: $white;
    }
  }
}

.slider {
  $arrow-dim: 32px;
  $arrow-font-size: 12px;
  $arrow-margin: 5px;

  display: flex;
  gap: 12px;

  @include not-mobile {
    flex: 0 0 auto;
    width: 80px;
    flex-direction: column;
    margin-left: 32px;
  }

  @include mobile {
    margin: 16px;
  }

  &.noSlider {
    @include mobile {
      display: flex;
      justify-content: center;
      margin-right: (-$items-gutter / 2);
      margin-left: (-$items-gutter / 2);

      .slide {
        flex: 0 1 auto;
        width: calc(25% - #{$items-gutter});
      }
    }
  }

  :global {
    .slick-list {
      @include not-mobile {
        padding: 0 $activeOutlineWidth;
      }

      @include mobile {
        padding: $activeOutlineWidth 0;
      }
    }

    .slick-vertical {
      padding: 35px 0;
    }

    .slick-track {
      @include not-mobile {
        flex-direction: column;
      }
    }

    .slick-slide {
      @include not-mobile {
        width: auto !important; // stylelint-disable-line declaration-no-important
      }
    }

    .slick-arrow {
      width: $arrow-dim;
      height: $arrow-dim;
      padding: 0;
      border: 1px solid $primary-dark-blue;
      border-radius: 100%;
      background: $pure-white;
      font-size: $arrow-font-size;
      color: $primary-dark-blue;
      transition: $transition-duration-fast ease-in-out;
      transition-property: opacity, visibility, border-color, background-color, color;
      margin: 0;

      @include not-mobile {
        margin: 0 auto;
        top: auto;
        right: auto;
        left: 50%;
        transform: translateX(-50%);
      }

      @include mobile {
        position: absolute;
        top: 50%;
      }

      &:hover,
      &:focus,
      &:active {
        background: $primary-dark-blue;
        color: $pure-white;
      }

      &.slick-disabled {
        opacity: 0;
        visibility: hidden;
      }

      &:before {
        display: none;
      }
    }

    .slick-prev {
      @include not-mobile {
        margin-bottom: $arrow-margin;
        transform: translateX(-50%) rotate(180deg);
        top: 0;
      }

      @include mobile {
        right: 100%;
        margin-right: $arrow-margin;
        transform: translate(0, -50%) rotate(90deg);
      }
    }

    .slick-next {
      @include not-mobile {
        bottom: 0;
      }

      @include mobile {
        left: 100%;
        margin-left: 0.5rem;
        transform: translate(0, -50%) rotate(-90deg);
      }
    }

    @include mobile {
      .slick-initialized .slick-slide > * {
        display: flex;
        align-items: stretch;
        flex-grow: 1;
      }
    }

    .slick-dots {
      bottom: auto;
      margin-top: 10px;
      margin-right: 0;

      li {
        margin: 0;

        &.slick-active {
          button {
            &:before {
              opacity: 1;
              color: $primary-dark-blue;
            }
          }
        }

        button {
          &:before {
            font-size: 10px;
          }
        }
      }
    }
  }
}

.slide {
  width: 100%;
  border: 0;
  padding: 0;
  overflow: hidden;
  background: none;

  img {
    display: block;
    width: 100%;

    @supports (object-fit: cover) {
      height: 60px;
      object-fit: cover;

      @include mobile {
        height: 50px;
      }
    }
  }
}
