#tc_privacy_catsVendorsInject {
  #tc_priv_masterCat {
    .tc_priv_catName {
      background-color: $blue-dark !important;
      color: $white;
    }
    .tc_priv_catDesc {
      color: black;
      font-weight: normal;
    }
  }

  #tc_priv_masterVend {
    .tc_priv_vendName {
      color: $blue-dark;
      text-align: center;
    }
  }
}
