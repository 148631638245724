@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

.root {
  display: block;
  padding: 4px 24px;
}

.top {
  display: flex;
}

.loading {
  display: flex;
  justify-content: center;
  padding: 24px;

  &:empty::before {
    content: "";
    display: block;
    height: 24px;
  }
}

.image {
  flex: 0 0 auto;
  height: 88px;
  margin: 8px 20px 0 0;
  position: relative;
  width: 88px;

  @include mobileTablet {
    margin-right: 14px;
  }
}

.imgContainer {
  border-radius: 10px;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;

  .img {
    display: block;
    height: 100%;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
  }
}

.new {
  background-color: $pure-white;
  border: 1px solid $primary-blue;
  color: $primary-blue;
  font-size: 10px;
  font-weight: 500;
  left: -4px;
  line-height: 1;
  padding: 4px 8px;
  position: absolute;
  text-transform: uppercase;
  top: 10px;
  width: 66px;
}

.pictos {
  align-items: center;
  bottom: 4px;
  display: flex;
  flex-wrap: wrap-reverse;
  left: 1px;
  position: absolute;
  z-index: 2;
}

.pictosItem {
  padding: 0 2px;

  img {
    display: block;
  }

  .price {
    height: 15px;
  }

  .vi4you,
  .kitchen {
    height: 20px;
    width: 20px;
  }
}

.kitchen {
  align-items: center;
  background-color: $red2;
  border-radius: 50%;
  color: $red2;
  display: flex;
  height: 30px;
  justify-content: center;
  width: 30px;
}

.kitchenIcon {
  fill: currentColor;
}

.infos {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
}

.program {
  &:hover,
  &:focus,
  &:active {
    .name,
    .location {
      text-decoration: underline;
    }
  }
}

.name {
  color: $pure-black;
  font-size: 16px;
  font-weight: 500;
}

.location {
  color: $primary-blue;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}

.pastilles {
  display: flex;
  margin-top: 5px;

  > :not(:first-child) {
    margin-left: 10px;
  }

  .pastille {
    align-self: flex-start;
  }
}

.list {
  margin: 0;
  padding: 0;
}

.listItem {
  align-items: center;
  color: $pure-black;
  font-size: 14px;
  display: flex;
  list-style-type: none;
  margin: 10px 0;

  .svgIcon {
    color: $pure-grey;
    font-size: 13px;
    height: 15px;
    margin-right: 6px;
    width: 15px;
  }
}

.highlight {
  color: $primary-dark-blue;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
}

.details {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  min-height: 50px;
  padding: 0 0 5px;
  width: 100%;
}

.detailItem {
  align-items: center;
  display: flex;
}

.detailItemIcon {
  color: $pure-grey;
  font-size: 13px;
  margin: 0 6px 0 0;
}

.detailItemSvgIcon {
  height: 13px;
  margin: 0 6px 0 0;
  width: 13px;
}

.detailLabel {
  color: $primary-dark-blue;
  font-size: 16px;
  font-weight: 500;
}

.lotNumber {
  color: $primary-blue;
  text-decoration: underline;
  text-transform: uppercase;

  &:hover,
  &:focus,
  &:active {
    color: $primary-dark-blue;
  }
}
