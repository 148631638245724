@import "assets/styles/1-settings";
@import "assets/styles/2-tools";
@import "assets/styles/variables";

.root {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border: 0;
  border-radius: 10px;
  padding: 0;
  background: none;
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  color: $black;
}

.info {
  display: flex;
  flex-direction: column;
  order: 10;
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
  padding: 22px 30px;
  background: $pure-white;
}

.location {
  font-weight: 500;
  font-size: 16px;
  color: $blue-dark;
}

.name {
  margin-bottom: 15px;
}

.price {
  .number {
    font-weight: 500;
    color: $blue-dark;
  }
}

.delivery {
}

.top,
.image {
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
}

.top {
  order: 0;
  flex: 1 0 0;
  position: relative;
  z-index: 0;
  padding-top: 52.5%;
  background: $pure-white;

  .new,
  .preview {
    @extend %program-label;
    position: absolute;
    top: 22px;
    left: 0;
    z-index: 10;
  }

  .picto {
    width: 50px;
    height: 50px;
    position: absolute;
    bottom: 20px;
    left: 20px;
    z-index: 10;
  }

  .image {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;

    @supports (object-fit: cover) {
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }
}
