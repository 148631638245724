@import "assets/styles/1-settings";

.root {
  font-family: $vinciFontName;
  text-transform: none;
  text-decoration: none;
  box-shadow: none;

  &.containedPrimary {
    background-color: $primary-blue;
    border-radius: 40px;
    color: $pure-white;
    font-family: $vinciFontName;
    padding: 13px 30px;
    text-transform: none;
    //min-width: 237px;

    &:hover,
    &:focus,
    &:active {
      background-color: $primary-dark-blue;
      box-shadow: none;
    }

    &[disabled] {
      background-color: $primary-blue;
      pointer-events: none;
      opacity: 0.3;
      color: $pure-white;
      text-transform: none;
    }
  }

  &.containedSecondary {
    background-color: $primary-blue-light;
    border-radius: 40px;
    font-family: $vinciFontName;
    padding: 13px 30px;
    color: $primary-blue;
    box-shadow: none;
    text-transform: none;
    //min-width: 237px;

    &:hover,
    &:focus,
    &:active {
      background-color: $primary-blue-light;
      color: $primary-dark-blue;
      box-shadow: none;
    }

    &[disabled] {
      background-color: $primary-blue-light;
      color: $primary-blue;
      pointer-events: none;
      opacity: 0.5;
      text-transform: none;
    }
  }

  .icon {
    color: inherit;
    margin: 0 10px 0 0;
  }

  &.colorInherit {
    border: 1px solid $grey;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: $pure-white;

    text-transform: none;
    font-family: $vinciFontName;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    color: $primary-dark-blue;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0 8px;

    &[disabled] {
      color: $pure-grey;
    }
  }

  &.outlinedColorSecondary {
    color: $primary-dark-blue;
    background-color: $primary-blue-light;
    border: 1px solid $primary-blue-light;
    border-radius: 6px;
    font-family: $vinciFontName;
    font-size: 16px;
    padding: 6px 25px;
    text-transform: none;

    &:hover,
    &:focus,
    &:active {
      color: $primary-dark-blue;
      background-color: $primary-blue-light;
      border: 1px solid $primary-dark-blue;
    }

    &[disabled] {
      color: $black;
      background-color: $grey-light;
      border: none;
      border-radius: 6px;
    }

    .iconSprite {
      height: 16px;
      margin: 0 10px 0 0;
      width: 16px;
    }
  }

  &.text {
    background: none;
    border: 0;
    box-sizing: border-box;
    color: $pure-black;
    font-weight: 400;
    letter-spacing: 0;
    margin: 0;
    padding: 0;

    &:hover {
      background: none;
    }

    .labelText {
      display: flex;
      flex-direction: column;

      &::after {
        background-color: $primary-blue;
        content: "";
        display: block;
        height: 1px;
        margin: 3px 0 0;
        transition: 300ms;
        width: 100%;
      }

      &:hover::after {
        width: 36%;
      }
    }

    .iconSprite {
      height: 18px;
      margin: 0 8px 0 0;
      width: 18px;
    }
  }
}

.colorPrimary {
  color: $pure-white;
}

.colorSecondary {
  color: $primary-blue;
}

.loading {
  opacity: 1 !important;
}

.loader {
  margin: -2px 0;

  &.colorPrimary {
    color: $pure-white;
  }

  &.colorSecondary {
    color: $primary-blue;
  }

  &.withLabel {
    margin-right: 8px;
  }
}

.outlinedWithText {
  background-color: $white;
  color: $primary-dark-blue;
  border-radius: 8px;
  font-family: $vinciFontName;
  padding: 0 1rem 0 1rem;
  min-width: 191px;

  &:hover,
  &:focus,
  &:active {
    border: 1px solid $primary-dark-blue;
    background-color: $white;
  }
}

.outlined {
  background-color: $white;
  color: $primary-dark-blue;
  border-radius: 6px;

  max-height: 42px;
  min-height: 42px;
  max-width: 42px;
  min-width: 42px;
  &:hover {
    background-color: $primary-blue-light;
    border: 1.5px solid rgba(5, 117, 230, 0.4);
    color: $primary-dark-blue;
    box-shadow: none;
  }
}

.outlinedColorPrimary.selectorWeight {
  text-align: left !important;
  min-width: 191px;
  border-radius: 4px;
  background-color: $pure-white;
  border: 1.5px solid $grey;

  &:hover {
    background-color: $pure-white;
    border: 1.5px solid rgba(5, 117, 230, 0.4);
    color: $primary-dark-blue;
    box-shadow: none;
  }

  .loader {
    &.colorPrimary {
      color: $primary-blue;
    }
  }
}

.outlinedColorPrimaryDropdown {
  text-align: left !important;
  min-width: 191px;
  padding: 0 0 0 0.5rem;
  border-radius: 4px;
  background-color: $pure-white;
  border: 1.5px solid $grey-light;
  font-family: $vinciFontName;

  &:hover,
  &:focus,
  &:active {
    background-color: $white; // ou pure-white
    border: 1.5px solid $grey-light;
    color: $primary-dark-blue;
    box-shadow: none;
  }
}

.label {
}

.labelAction {
  justify-content: left;
}

.iconDropdown {
  margin-left: auto;
  margin-right: 1rem;
}

.isActive.selectorWeight {
  background-color: $primary-dark-blue !important;
  color: $white !important;
  border-radius: 4px 4px 0px 0px;
  &:hover,
  &:focus,
  &:active {
    background-color: $primary-dark-blue !important;
    color: $white !important;
  }
}

.isActiveIconDropdown {
  color: $white !important;
}

.disabledIconSaveAction {
  color: $black;
}

.dropdownReturn {
  transform: rotate(180deg);
}
