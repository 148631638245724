@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

.paper {
  display: flex;
  flex-direction: column;

  @include desktop {
    width: 650px;
  }

  @include tablet {
    width: 480px;
    height: auto;
    top: $appbar-height-desktop + 2px;
    bottom: 0;
  }

  @include mobileTablet {
    height: auto;
    top: $appbar-height-mobile + 2px;
    bottom: 0;
  }
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  height: 80px;
  position: relative;
  background: $primary-dark-blue;
  color: $pure-white;

  .title {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 26px;

    .icon {
      display: block;
      width: 1em;
      height: 1em;
      margin: 0.2em 10px 0 0;
      font-size: 20px;
    }
  }

  .close,
  .reset {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: 0;
    padding: 10px;
    background: none;
    font-size: 20px;
  }

  .close {
    left: 10px;

    > * {
      display: block;
    }
  }

  .reset {
    right: 20px;

    .icon {
      display: block;
      width: 1em;
      height: 1em;
    }
  }
}

.body {
  flex: 1 1 0;
  width: 100%;
  overflow: auto;

  .container {
    width: 100%;
    margin: 0 auto;

    @include desktop {
      max-width: 375px;
    }

    @include tablet {
      max-width: 330px;
    }
  }

  .section {
    padding: 24px 24px 16px;

    &:not(:first-child) {
      border-top: 1px solid $grey-light;
    }

    .title {
      margin-bottom: 16px;
      font-weight: 500;
      font-size: 15px;
      line-height: 1.2;
      color: $pure-black;
    }
  }

  .value {
    display: flex;
    flex-direction: row-reverse;

    .checkbox {
      margin-left: 10px;
    }

    .label {
      display: flex;
      align-items: center;
      margin-left: 0;
    }

    .pastille {
      font-size: 50px;
    }
  }

  .range {
    padding: 0;
  }
}

.footer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex: 0 0 auto;
  height: 80px;
  border-top: 1px solid $grey-light;
  padding: 10px;

  .switcher {
    visibility: visible;
  }

  .saveSearch {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 6px;
    padding: 10px 14px;
    background: $primary-blue;
    font-weight: 500;
    font-size: 16px;
    color: $pure-white;
    transition: $transition-duration-fast ease-in-out;
    transition-property: border-color, background-color, color;

    .icon {
      width: 1em;
      height: 1em;
      margin-right: 10px;
      font-size: 21px;
      fill: currentColor;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: $primary-blue-light;
      border-color: currentColor;
      color: $primary-dark-blue;
    }
  }
}
