@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";
@import "src/assets/styles/variables";

.root {
  position: relative;
}

.lotsListHeader {
  overflow: hidden;
  position: sticky;
  top: $programMenuAnchorsHeight;
  width: 100%;
  z-index: 1;
}

.sorter {
  display: flex;
  justify-content: flex-end;
  margin-top: -40px;
  padding: 0 24px;
}
