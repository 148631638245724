@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.root {
  height: 100%;
  position: relative;
  width: 100%;

  @include not-desktop {
    padding: 0;
  }

  .expand {
    background: none;
    border: 0;
    height: 100%;
    left: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    text-indent: -99999px;
    top: 0;
    width: 100%;

    &:hover {
      &:before {
        background-size: 15px;
        height: 30px;
        right: 7px;
        top: 7px;
        width: 30px;
      }
    }

    &:before {
      background: rgba(0, 0, 0, 0.4) url(/images/slideshow-picto.png) no-repeat center center;
      background-size: 10px;
      border-radius: 50%;
      content: "";
      height: 22px;
      position: absolute;
      right: 11px;
      transition: all 0.1s;
      top: 11px;
      width: 22px;
    }
  }

  &.noImage {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.image {
  border-radius: 4px;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;

  @include not-desktop {
    border-radius: 0;
  }

  .imageMain {
    height: 100%;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;

    @include not-desktop {
      height: auto;
      min-height: 0;
      min-width: 0;
      width: 100%;
    }
  }
}

.infosPictos {
  align-items: center;
  bottom: 4px;
  display: flex;
  flex-wrap: wrap-reverse;
  left: 6px;
  position: absolute;

  @include not-desktop {
    margin: 0 0 6px 15px;
  }

  &.alone {
    position: static;
  }
}

.infosPictosItem {
  align-items: center;
  display: flex;
  padding: 2px 6px;
}

.new {
  background-color: $pure-white;
  border: 1px solid $primary-blue;
  color: $primary-blue;
  display: inline-block;
  font-size: 10px;
  font-weight: 500;
  left: -4px;
  padding: 3px 10px;
  position: absolute;
  text-transform: uppercase;
  top: 11px;

  @include not-desktop {
    left: 7px;
    top: 7px;
  }
}
