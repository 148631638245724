@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

.button {
  position: relative;
  border: 0;
  padding: 0;
  background: none;

  &:disabled {
    cursor: auto;
  }

  .cta[data-label] {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 20px;
    z-index: 10;

    &::after {
      content: attr(data-label);
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid $primary-dark-blue;
      border-radius: 1000px;
      background: $pure-white;
      font-size: 16px;
      line-height: 1;
      color: $primary-dark-blue;
      transition: $transition-duration-fast ease-in-out;
      transition-property: background-color, color;

      @include desktop {
        padding: 11px 30px 15px;
      }

      @include not-desktop {
        padding: 7px 20px 11px;
      }
    }
  }

  &:hover, &:focus {
    .cta::after {
      background: $primary-dark-blue;
      color: $pure-white;
    }
  }
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

.dialog {
  background: rgba($pure-black, 0.8);
  height: 100vh;
  z-index: 2000 !important;
}

.paper {
  background: transparent;
  box-shadow: none;
  overflow: visible !important;

  :global {
    .slick-track {
      align-items: center;
    }
  }
}

.close {
  background: none;
  border: 0;
  color: $pure-white;
  font-size: 26px;
  line-height: 1;
  padding: 0;
  position: fixed;
  right: 30px;
  top: 30px;
  z-index: 10;
}

.slide {
  .image {
    width: 100%;
  }

  .text {
    color: $pure-white;
  }
}
