// Fonts
$vinciFontName: "Vinci Sans";
$vinci: $vinciFontName, arial, sans-serif;
$vinciSerifFontName: "Vinci Serif";
$vinciSerif: $vinciSerifFontName, serif;
$vinciExpandedFontName: "Vinci Sans Expanded";
$vinciExpanded: $vinciExpandedFontName, $vinciFontName, arial, sans-serif;

// Variables
$appbar-height-desktop: 80px;
$appbar-height-mobile: 56px;

//---------- Colors ----------//
// stylelint-disable color-no-hex

$purple: #890c58;
$blue: #0463c4;
$blue-dark: #003868;
$blue-light: #0773df;

// Primary
$primary-dark-blue: #003c71;
$primary-blue: #0575e6;
$primary-blue-soft: #cce6ff;
$primary-blue-softer: #deefff;
$primary-blue-light: #f8faff;
$blue-dark-cerulean: #004489;
$royal-blue: #3370de;
$cyan-dark: #23d7b0;
$cyan-light: #50e3c2;

// Secondary
$secondary-blue: #3c4a9a;
$secondary-cyan: #4fbfe2;
$yellow-pale: #f6d486;
$pink: #fe607d;
$orange: #f08218;
$red: #e03c31;
$red2: #d85450;
$green: #0fa669;
$gold: #cc8a00;
$yellow: #cdaa04;

// Neutral
$pure-black: #1b2633;
$black: #646464;
$black2: #070707;
$black3: #353535;
$grey-blue: #596779;
$pure-grey: #aaaaaa;
$grey: #d6d6d6;
$grey-2: #e3e5e8;
$grey-3: #333;
$header-grey: #c4c4c4;
$grey-light: #f3f3f3;
$grey-light-2: #7e7e7e;
$grey-light-3: #9d9d9d;
$grey-light-4: #f5f5f5;
$pure-white: #ffffff;
$white: #fafafa;

// Breakpoints
$breakpoint-xs-min: 576px;
$breakpoint-sm-min: 768px;
$breakpoint-md-min: 992px;
$breakpoint-lg-min: 1280px;
$breakpoint-xl-min: 1450px;
$breakpoint-xxl-min: 1600px;

$breakpoint-xxs-max: $breakpoint-xs-min - 1;
$breakpoint-xs-max: $breakpoint-sm-min - 1;
$breakpoint-sm-max: $breakpoint-md-min - 1;
$breakpoint-md-max: $breakpoint-lg-min - 1;
$breakpoint-lg-max: $breakpoint-xl-min - 1;

// Icomoon Glyphs
$icomoon: (
  more: "\e757",
  criterias: "\e756",
  kitchen: "\e705",
  contract-signed: "\e719",
  contract-signed-vinci: "\e71d",
  call: "\e701",
  mail: "\e703",
  map-plan: "\e760",
  send-documents: "\e761",
  edit: "\e708",
  house: "\e706",
  kitchen-pack: "\e70d",
  label: "\e707",
  share: "\e732",
  failed: "\e721",
  rent: "\e727",
  user: "\e755",
  user-short: "\e905",
  add-to-folder: "\e74d",
  arrow-left: "\e715",
  booklet: "\e745",
  calendar: "\e72d",
  cancel: "\e73c",
  check-circle-vi3p: "\e729",
  checked: "\e909",
  chevron-down: "\e712",
  compare: "\e800",
  cross: "\e714",
  cross-bold: "\e900",
  description: "\e728",
  digital-signing: "\e735",
  display: "\e72e",
  download: "\e73b",
  download-documents: "\e725",
  euro: "\e72c",
  folder: "\e709",
  generate-contract: "\e750",
  home: "\e801",
  interlocutor: "\e731",
  location: "\e726",
  lock: "\e72a",
  lot-card: "\e74a",
  marker: "\e704",
  marker-home: "\e718",
  marker-light: "\e702",
  minus: "\e716",
  my-folders: "\e752",
  my-research: "\e700",
  notifications: "\e754",
  option: "\e742",
  pdf: "\e734",
  percent: "\e72b",
  plans: "\e72f",
  plus: "\e717",
  power-off: "\e711",
  pre-book: "\e740",
  program-card: "\e748",
  rectangle: "\e713",
  reservation-contract: "\e74f",
  reset: "\ef0a",
  save: "\e74c",
  search: "\e747",
  see: "\e733",
  send: "\e739",
  trash: "\e730",
  wallet: "\e724",
  itineraire: "\e901",
  show: "\e902",
  hide: "\e903",
  price-euro: "\e904",
  check-circle-bg: "\e906",
  idea: "\e907",
  load: "\e908",
  send-demand: "\e90a",
  contact: "\e90e",
);

// Transitions
$transition-duration-fast: 0.25s;
$transition-duration-medium: 0.5s;

// UI Variables
// Search page
$search-bg-color: $white;
$search-mixed-results-width: 57%;
$search-program-desired-width: 360px;
$search-program-grid-gap: 20px;
$search-program-grid-padding-x: (
  desktop: 50px,
  tablet: 30px,
  mobile: 10px,
);
$search-result-promo-border-width: 4px;

// Program Banner
$program-booster-tab-height: 72px;
$program-booster-padding: 24px;
$program-booster-background: desaturate(lighten($primary-dark-blue, 8), 34);
$program-booster-selected-tab-background: $pure-white;
$program-documents-mobile-padding-x: 16px;

// Lot
$lot-detail-header-height: 100px;

// Report
$report-detail-padding-sm: 20px;
$report-detail-padding-md: 40px;

// Notification Dot
$notification-dot-dim: (
  xl: 1.5em,
  sm: 1.4em,
);
