@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.root {
  display: flex;
  margin: 0 -8px;

  @include not-desktop {
    flex-direction: column;
    margin: 0;
  }

  @include mobileTablet {
    margin: 0;
  }
}

.col {
  flex: 0 0 auto;
  padding: 0 8px;

  @include not-desktop {
    padding: 4px 0;
  }

  @include mobileTablet {
    padding: 8px 0;
    position: relative;

    &:first-child {
      padding-top: 0;
    }
  }

  > div {
    height: 100%;
  }
}

.colInfos {
  width: 25%;

  @include not-desktop {
    width: 71.5vw;
  }

  @include mobileTablet {
    width: 100%;
  }
}

.colAdditionalInfos {
  width: 30%;

  @include not-desktop {
    width: 71.5vw;
  }

  @include mobileTablet {
    width: 100%;
  }
}

.colFinancialInfos {
  width: 30%;

  @include not-desktop {
    width: 71.5vw;
  }

  @include mobileTablet {
    width: 100%;
  }
}

.colHousingInfos {
  flex-grow: 1;
}

.displayActivity {
  @include not-desktop {
    .col:not(.colImage) {
      width: 100%;
    }
  }
}

.imageWrapper {
  padding: 0 0 0 6px;
  position: relative;

  @include not-desktop {
    padding: 0;
  }

  .expand {
    background: none;
    border: 0;
    height: 100%;
    left: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    text-indent: -99999px;
    top: 0;
    width: 100%;

    &:hover {
      &:before {
        background-size: 15px;
        height: 30px;
        right: 7px;
        top: 7px;
        width: 30px;
      }
    }

    &:before {
      background: rgba(0, 0, 0, 0.4) url(/images/slideshow-picto.png) no-repeat center center;
      background-size: 10px;
      border-radius: 50%;
      content: "";
      height: 22px;
      position: absolute;
      right: 11px;
      transition: all 0.1s;
      top: 11px;
      width: 22px;
    }
  }
}

.image {
  border-radius: 4px;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;

  @include not-desktop {
    border-radius: 0;
  }

  .imageMain {
    height: 100%;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;

    @include not-desktop {
      height: auto;
      min-height: 0;
      min-width: 0;
      width: 100%;
    }
  }
}

.infos {
  background-color: $white;
  border-radius: 4px;
  padding: 16px 24px;

  .odd & {
    background-color: $pure-white;
  }

  > .list {
    margin-top: 14px;
  }
}

.infosTitle {
  color: $primary-dark-blue;
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 8px;
  padding: 0 0 6px;
  position: relative;

  @include mobileTablet {
    padding-right: 40px;
  }

  &:after {
    background-color: $primary-dark-blue;
    bottom: 0;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    width: 50px;
  }
}

.new {
  background-color: $pure-white;
  border: 1px solid $primary-blue;
  color: $primary-blue;
  display: inline-block;
  font-size: 10px;
  font-weight: 500;
  left: 0;
  padding: 3px 10px;
  position: absolute;
  text-transform: uppercase;
  top: 11px;

  @include not-desktop {
    left: 7px;
    top: 7px;
  }
}

.infosPictos {
  align-items: center;
  bottom: 4px;
  display: flex;
  flex-wrap: wrap-reverse;
  left: 6px;
  position: absolute;

  @include not-desktop {
    margin: 0 0 6px 15px;
  }
}

.infosPictosItem {
  align-items: center;
  display: flex;
  padding: 2px 6px;
}
