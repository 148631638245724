@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

.root {
  display: flex;
  justify-content: center;
  align-items: center;

  > * {
    margin: 0 7px;

    @include desktop-xl {
      margin: 0 14px;
    }
  }

  > :first-child {
    margin-left: 0;
  }

  > :last-child {
    margin-right: 0;
  }
}

.reset {
  background: none;
  border: 0;
  outline: none;
  padding: 5px;
  font-size: 20px;
  color: $primary-dark-blue;

  .icon {
    display: block;
    width: 1em;
    height: 1em;
  }
}

.panelBtn {
  display: flex;
  border: 1px solid $grey;
  border-radius: 8px;
  padding: 15px 14px;
  background: $pure-white;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.2;
  color: $pure-black;
  transition: $transition-duration-fast ease-in-out;
  transition-property: border-color, background-color, color;

  .icon {
    display: block;
    width: 1em;
    height: 1em;
    margin: 0.1em 5px 0 0;
    color: $primary-dark-blue;
  }

  &:hover,
  &:focus,
  &:active {
    border-color: transparentize($primary-blue, 0.6);
    background: $primary-blue-light;
    color: $primary-dark-blue;
  }
}

.button {
  flex: 1 0 auto;
  border: 1px solid $grey;
  border-radius: 6px;
  padding: 0 10px 0.1em;
  background: $pure-white;
  font-weight: 700;
  font-size: 10px;
  line-height: 1.2;
  text-transform: uppercase;
  color: $black3;
  transition: $transition-duration-fast ease-in-out;
  transition-property: border-color, background-color, color;

  &:hover,
  &:focus,
  &:active {
    border-color: transparentize($primary-blue, 0.6);
    background-color: $primary-blue-light;
  }

  &.active {
    border-color: $grey;
    background-color: $primary-dark-blue;
    color: $pure-white;

    &:hover,
    &:focus,
    &:active {
      border-color: $pure-grey;
      background-color: $grey;
      color: $black3;
    }
  }
}

.circle {
  border: 0;
  padding: 0;
  outline: 0;
  background: none;
  font-size: 50px;

  svg {
    display: block;
    width: 1em;
    height: 1em;
  }

  path {
    transition: $transition-duration-fast ease-in-out;
    transition-property: fill;
  }

  /* Handle Colors */
  --filter-icon-background-color: #{$pure-white};
  --filter-icon-circle-color: #{$grey};
  --filter-icon-text-color: #{$black};
  --filter-icon-accent-color: #{$grey};

  &:hover,
  &:focus,
  &:active {
    --filter-icon-background-color: #{$primary-blue-light};
    --filter-icon-circle-color: #{transparentize($primary-blue, 0.6)};
    --filter-icon-text-color: #{$primary-dark-blue};
    --filter-icon-accent-color: #{transparentize($primary-blue, 0.6)};
  }

  &.active {
    --filter-icon-background-color: #{$primary-dark-blue};
    --filter-icon-circle-color: #{transparentize($primary-blue, 0.6)};
    --filter-icon-text-color: #{$pure-white};
    --filter-icon-accent-color: #{transparentize($primary-blue, 0.6)};

    &:hover,
    &:focus,
    &:active {
      --filter-icon-background-color: #{$grey};
      --filter-icon-circle-color: #{$pure-grey};
      --filter-icon-text-color: #{$black};
      --filter-icon-accent-color: #{$pure-grey};
    }
  }
}

.group {
  display: flex;
  align-items: center;

  &:not(.vertical) > * {
    margin: 0 5px;

    @include desktop-xl {
      margin: 0 7px;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &.vertical {
    $gutter: 6px;

    flex: 0 0 auto;
    flex-direction: column;
    align-self: stretch;
    align-items: stretch;

    > * {
      margin: ($gutter / 2) 0;
    }

    > :first-child {
      margin-top: 0;
    }

    > :last-child {
      margin-bottom: 0;
    }
  }
}

.compact {
  display: flex;
  height: 50px;

  .item {
    position: relative;
    z-index: 0;
    padding: 10px;
    border: 1px solid $grey;
    background: $pure-white;
    font-weight: 500;
    font-size: 14px;
    white-space: nowrap;
    color: $black3;
    transition: $transition-duration-fast ease-in-out;
    transition-property: background-color, border-color, color;

    &:first-child {
      padding-left: 26px;
      border-radius: 50px 0 0 50px;
    }

    &:not(:first-child) {
      margin-left: -1px;
    }

    &:last-child {
      padding-right: 26px;
      border-radius: 0 50px 50px 0;
    }

    &:hover,
    &:focus,
    &:active {
      z-index: 5;
      background: $primary-blue-light;
      border-color: transparentize($primary-blue, 0.6);
    }

    &.active {
      z-index: 10;
      border-color: $grey;
      background: $primary-dark-blue;
      color: $pure-white;

      &:hover,
      &:focus,
      &:active {
        border-color: $grey;
        background: $pure-grey;
        color: $black3;
      }
    }
  }
}
