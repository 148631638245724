@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

$gutter: 10px;

.root {
  display: flex;
  align-items: flex-end;
  margin: 20px (-$gutter / 2) 0;

  > * {
    margin: 0 ($gutter / 2);
  }
}

.picto {
  width: 50px;
  height: 50px;
}
