@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.root {
  flex: 1 0 auto;
  padding: 40px 24px;
  background: $pure-white;
}

.loading {
  display: flex;
  flex: 1 0 auto;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: rgba($black2, 0.3);

  @include mobile {
    min-height: 50vh;
  }
}
