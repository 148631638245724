.image-overlay-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  img {
    position: absolute;
    width: auto;
    height: 100%;
    left: 50%;
    top: 0;
    transform: translateX(-50%);

    @supports (object-fit: cover) {
      width: 100%;
      height: 100%;
      left: 0;
      object-fit: cover;
      object-position: top center;
      transform: none;
    }
  }

  .image-overlay-component {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $black;
  }
}

@media (max-width: $screen-sm-max) {
  .image-overlay-wrapper {
    img {
      height: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.ico-alert-blanc {
  margin-left: 16px;
  top: 3px;
}

@keyframes vibrate {
  0% {
    transform: rotate(0);
  }
  10% {
    transform: rotate(-10deg);
  }
  20% {
    transform: rotate(10deg);
  }
  30% {
    transform: rotate(-10deg);
  }
  40% {
    transform: rotate(10deg);
  }
  50% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}
/* Vibrate 10 times slower */
@keyframes vibrate-spaced {
  0% {
    transform: rotate(0);
  }
  1% {
    transform: rotate(-10deg);
  }
  2% {
    transform: rotate(10deg);
  }
  3% {
    transform: rotate(-10deg);
  }
  4% {
    transform: rotate(10deg);
  }
  5% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}
