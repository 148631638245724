@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.root {
  padding: 24px;

  @include desktopTablet {
    padding: 24px 54px 24px 70px;
  }

  @include mobileTablet {
    padding-top: 0;
  }

  .disconnectIconBtn {
    font-family: $vinciFontName;
    font-weight: 500;
    height: 45px;
    letter-spacing: unset;
    padding: 0 58px;
    text-transform: none;
  }
}

.text {
  font-size: 16px;
  margin: 0 0 16px;
}

.panelHeader {
  font-family: $vinciExpanded;

  @include desktopTablet {
    padding: 18px 0 24px;
    margin: 0 40px 0 70px;
  }
}

.modifyLink {
  height: 40px;
  font-family: $vinciExpanded !important;
  letter-spacing: unset !important;
  padding: 0 32px !important;
  color: $cyan-light !important;

  &:hover {
    color: $cyan-dark !important;
  }

  @include mobileTablet {
    display: none;
  }
}

.grid {
  display: flex;
  flex-wrap: wrap;
  gap: 25px 40px;
}

.gridItem {
  flex: 0 0 auto;
  width: 47.6%;

  @include mobileTablet {
    width: 100%;
  }
}

.gridItemFull {
  flex: 0 0 auto;
  width: 100%;
}

.separator {
  flex: 0 0 auto;
  width: 100%;
}

.disconnectWrapper {
  display: flex;
  padding: 12px 0 24px;

  @include mobileTablet {
    justify-content: center;
  }
}

.bottom {
  background-color: $pure-white;
  border-top: 1px solid $grey-light;
  margin: 0 -24px;
  padding: 28px 24px 47px 24px;

  &.isSticky {
    bottom: 0;
    left: 0;
    margin: 0;
    position: fixed;
    width: 100%;
  }

  .modifyLink {
    width: 100%;

    @include mobileTablet {
      display: flex;
    }
  }
}
