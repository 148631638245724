@import "src/assets/styles/1-settings";

.tagWrapper {
  position: relative;
  z-index: 0;

  &:hover .tagSee.tagRevertHover {
    visibility: hidden;
  }
}

.tagSee {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  visibility: hidden;

  .chip {
    cursor: pointer;
  }

  .tagSeeIcon {
    font-size: 16px !important;
    margin-left: 0 !important;
    margin-right: 3px;
  }

  .tagSeeLabel {
    padding: 0 !important;
  }

  &.tagSeeHover {
    visibility: visible;
  }
}

.popper.selectorWeight {
  position: relative;
  padding: 16px;
  overflow: visible;
  box-shadow: 0 4px 4px rgba($pure-black, 0.25);

  .popperClose {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    border: 0;
    background: none;
    font-size: 8px;
    line-height: 1;
  }

  &.top {
    top: -10px;

    &::after {
      top: 100%;
      left: 50%;
      transform: translate(-50%, -50%) translateY(-3px) rotate(45deg);
    }
  }

  &.bottom {
    top: 10px;

    &::after {
      bottom: 100%;
      left: 50%;
      transform: translate(-50%, 50%) translateY(3px) rotate(45deg);
    }
  }
}
