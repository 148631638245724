@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.panelContent {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  box-shadow: 0 4px 15px rgba(15, 73, 139, 0.104988);
  display: flex;
  justify-content: center;
  padding: 27px 50px;
  position: relative;

  @include mobile {
    border-bottom: 1px solid $grey-light;
    box-shadow: none;
    flex: 0 0 auto;
    height: 68px;
    justify-content: space-between;
    padding: 0 16px 0 24px;
  }
}

.title {
  align-items: center;
  color: $pure-black;
  display: flex;
  font-size: 18px;
  font-weight: 500;
}

.svgIcon {
  height: 20px;
  margin: 0 28px 0 0;
  width: 20px;

  @include mobile {
    margin-right: 16px;
  }
}

.close {
  color: $pure-black;
  font-size: 15px;
  margin: 0;
  padding: 0;

  @include not-mobile {
    position: absolute;
    right: 19px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.noResults {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 46px 20px 0 20px;

  @include mobile {
    margin-top: 21px;
  }

  p {
    color: $pure-black;
    font-size: 18px;
    margin: 0 0 28px 0;
    max-width: 534px;
    text-align: center;
    width: 100%;

    @include mobile {
      margin-bottom: 16px;
    }
  }
}

.noResultsLink {
  .noResultsLinkLabel {
    font-size: 16px;
  }
}

.content {
  flex-grow: 1;

  @include mobileTablet {
    overflow-y: auto;
  }
}

.searchItem {
  padding: 27px 60px 27px 46px;
  border-bottom: 1px solid $grey;

  @include mobile {
    padding: 20px 16px 20px 20px;
  }
}

.scroll {
  height: 100%;
}

.scrollContent {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.loading {
  padding: 24px;
  text-align: center;
}
