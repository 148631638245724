@import "assets/styles/1-settings";
@import "assets/styles/2-tools";
@import "assets/styles/variables";

.root {
  background: $blue-dark;
  color: $pure-white;
}

.loaderContainer {
  display: flex;
  justify-content: center;
}

.loader {
  margin: 0 auto;
  color: $pure-white;
}

.title {
  margin: 0;
  font-weight: 500;
  line-height: 1.1;
}

.login {
  display: flex;
  flex-direction: column;

  .text {
    font-weight: 300;
    line-height: 1.1;
    text-align: center;
  }

  .buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
}

@include not-mobile {
  .root {
    padding: 70px $home-margin-h;
  }

  .title {
    margin-bottom: 60px;
    font-size: 40px;
  }

  .list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
    margin-bottom: 50px;
  }

  .login {
    .text {
      margin-bottom: 25px;
      font-size: 26px;
    }
  }
}

@include mobile {
  $gutter: 20px;
  $slideWidth: 270px;

  .root {
    padding: 35px 20px;

    :global {
      .slick-list {
        overflow: visible;
      }

      .slick-track {
        display: flex;

        .slick-slide {
          align-self: stretch;
          height: auto;

          > div {
            height: 100%;
          }
        }
      }
    }
  }

  .title {
    margin-bottom: 30px;
    font-size: 23px;
    text-align: center;
  }

  .list {
    .program {
      width: ($slideWidth + $gutter);
      height: 100%;
      padding: 0 ($gutter / 2);
    }

    .dots {
      margin-top: 25px;
    }
  }

  .login {
    margin-top: 35px;

    .text {
      margin-bottom: 20px;
      font-size: 20px;
    }
  }

  .buttons {
    flex-direction: column;
  }
}
