.paperWidthLg.vi3p {
  max-width: 50rem !important; // stylelint-disable-line declaration-no-important
}

.dialogContentText.vi3p {
  font-size: 1rem !important; // stylelint-disable-line declaration-no-important
  line-height: 1.2rem !important; // stylelint-disable-line declaration-no-important
}

.titre.vi3p {
  font-size: 1.2rem;
}

.button.vi3p {
  font-size: 0.8rem !important; // stylelint-disable-line declaration-no-important
  padding-left: 1.5rem !important; // stylelint-disable-line declaration-no-important
  padding-right: 1.5rem !important; // stylelint-disable-line declaration-no-important
  margin-bottom: 1.2rem !important; // stylelint-disable-line declaration-no-important
}

.paperScrollPaper.vi3p {
  max-height: calc(100% - 120px);
}
