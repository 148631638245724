@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.header {
  align-items: center;
  background-color: $primary-dark-blue;
  display: flex;
  justify-content: center;
  padding: 33px 0;
  position: relative;

  @include mobileTablet {
    justify-content: flex-start;
    padding: 74px 24px 0;
  }
}

.returnBtn {
  align-items: center;
  background: none;
  border: 0;
  color: $pure-white;
  display: flex;
  font-family: $vinciFontName;
  font-size: 12px;
  font-weight: 500;
  left: 24px;
  margin: 0;
  padding: 0;
  position: absolute;
  text-transform: uppercase;
  top: 50%;
  transform: translateY(-50%);

  @include mobileTablet {
    top: 28px;
    transform: none;

    span {
      display: none;
    }
  }
}

.returnBtnIcon {
  font-size: 14px;
  margin: 0 6px 0 0;

  @include mobileTablet {
    font-size: 24px;
  }
}

.title {
  align-items: center;
  color: $pure-white;
  display: flex;
  font-family: $vinciFontName;
  font-size: 26px;
  font-weight: 500;
}

.titleIcon {
  font-size: 24px;
  margin: 0 10px 0 0;

  @include mobileTablet {
    display: none;
  }
}

.buttons {
  display: flex;
  margin: 0 -4px;
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);

  @include mobileTablet {
    top: 16px;
    transform: none;
  }
}

.buttonsItem {
  margin: 0 4px;

  .button {
    background-color: $pure-white;
    height: 42px;
    max-width: none;
    min-width: auto;
    padding: 0;
    width: 50px;

    &:hover {
      background-color: $primary-blue-light;
      border: 2px solid rgba(5, 117, 230, 0.4);
    }
  }

  .buttonSvgIcon {
    height: 20px;
    margin: 0;
    width: 20px;
  }

  .buttonIcon {
    font-size: 20px;
    margin: 0;
  }

  .deleteBtn {
    .buttonIcon {
      color: $pink;
    }
  }
}

.tooltip {
  max-width: 200px;
  text-align: center;
}

.content {
  margin: 0 0 32px;
}
