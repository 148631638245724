@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.drawerRoot {
  .drawerPaper {
    width: 100%;
  }

  .drawerContainer {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    overflow: auto;
  }
}

.displayNone {
  display: none;
}

.paperRoot {
  height: 100%;
  padding-top: $appbar-height-desktop;

  @include mobileTablet {
    padding-top: $appbar-height-mobile;
  }
}
