.root {
  display: flex;

  .picto {
    width: 45px;
    height: 45px;
  }

  > * {
    margin-right: 10px;
  }
}
