@import "assets/styles/1-settings";

.popin {
  .position {
    align-items: center;

    .positionButton {
      margin: -10px;
      border: 0;
      padding: 10px;
      background: none;
    }

    .positionIcon {
      width: 1em;
      height: 1em;
      margin-left: 5px;
      font-size: 16px;
      color: $primary-dark-blue;
    }
  }
}
