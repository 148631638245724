.btn-back {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0;
  border: 0;
  outline: none;
  background: none;
  text-decoration: none;
  font-size: 1.1em;
  color: $white;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: $blue;
  }

  &:before {
    content: "";
    width: 12px;
    height: 10px;
    margin-top: 0.1em;
    margin-right: 0.5em;
    background: url("/images/arrow-left_white.png");
  }
}

.btn-primary {
  height: $btn-height;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  font-family: inherit;
  line-height: 1;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  white-space: nowrap;
  padding: 5px 30px;
  color: $white;
  border: 1px solid $blue;
  border-radius: 2px;
  background-color: $blue;
  text-transform: uppercase;
  transition: all 0.35s ease-in-out;

  &:hover {
    background: $white;
    color: $blue;
    text-decoration: none;
  }

  &.btn-reverse {
    font-weight: 500;
    border: 1px solid $white;
    color: $blue;
    background-color: $white;

    &:hover {
      color: $white;
      background-color: $blue;
    }
  }

  &.btn-opacity {
    border: 1px solid $white;
    color: $white;
    opacity: 0.85;

    &:hover {
      opacity: 1;
      color: $blue;
      background-color: $white;
    }
  }

  &.btn-normal {
    width: 100%;
    max-width: 166px;
    border: 1px solid #cecece;
    color: $text;
    text-transform: uppercase;
    background-color: transparent;

    &:hover {
      color: $white;
      background-color: $blue;
    }
  }

  &.btn-optionner,
  &.btn-prereserver {
    &.active {
      opacity: 0.35;

      &:hover {
        background-color: $white;
        color: $blue;
      }
    }
  }

  &.btn-white-border {
    border: 1px solid white;
  }

  &.btn-transparent {
    background-color: transparent;
  }

  &:disabled {
    color: $black;
    opacity: 0.5;
    background-color: $grey;
    cursor: auto;
    pointer-events: none;
  }

  .infinite-loader {
    width: auto;
    margin: 0 10px;
    padding: 0;
  }
}

.btn-read-more {
  position: relative;
  display: block;
  height: 40px;
  line-height: 40px;
  background-color: $blue;
  width: 208px;
  margin: 10px auto;
  color: white;
  border-radius: 2px;
  text-align: center;
}

.btn-more {
  position: relative;
  width: 40px;
  height: 40px;
  vertical-align: top;
  margin: 7px auto 0;
  padding: 0;
  border: 0;
  outline: none;
  background: url("/images/new_images/icon_optionplus.png") no-repeat center center;
  cursor: pointer;

  &__wrapper {
    position: relative;
  }

  &.active {
    background: url("/images/new_images/icon_optionplus_white.png") no-repeat center center $blue;
    z-index: 150;

    & + .list-item-actions-childs,
    .list-item-actions-childs {
      position: absolute;
      top: 40px;
      right: 0;
      padding: 10px;
      width: 140px;
      background-color: $blue;
      border-radius: 2px;
      z-index: 1;
    }
  }

  &:hover {
    color: rgba(0, 0, 0, 0.25);
  }
}

.list-item-actions-action {
  display: block;
  color: $white;
  font-size: 1em;
  padding: 8px;
  border: 0;
  outline: none;
  border-radius: 2px;
  background: none;
  text-align: left;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  transition-property: background, color;

  &:hover {
    color: $blue;
    background: $white;
  }
}

.btn-plan {
  background-image: url("/images/Fill_1.png");
  width: 34px;
  height: 34px;
  background-repeat: no-repeat;
}

.btn-without-basic-style {
  border: none;
  background: none;
  text-align: left;
}

// Mobile & portrait tablet
@media (max-width: $screen-sm-max) {
  .btn-primary {
    width: 100%;
    padding: 12px 15px;
    font-size: 14px;
  }
}
