@import "src/assets/styles/1-settings";

.root {
  border-bottom: 1px solid $grey-light;
  padding: 40px 0;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border: 0;
    padding-bottom: 16px;
  }
}

.title {
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 12px;
}
