@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

.root {
  position: relative;
  border-radius: 10px;

  @include not-mobile {
    display: flex;

    &:first-child {
      height: 300px;

      .title {
        align-items: flex-end;
        position: absolute;
        top: 50%;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10;
        border-top-left-radius: 0;
        border-bottom-right-radius: inherit;
        padding: 0 35px 35px;
        background: none;
        font-size: 36px;
      }

      .picture {
        width: 100%;
        height: 100%;
        border-top-left-radius: inherit;
        border-bottom-left-radius: inherit;

        &::before {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 5;
          background: linear-gradient(to top, $blue-dark 0%, transparent 50%);
          mix-blend-mode: multiply;
          pointer-events: none;
        }
      }
    }

    &:nth-child(2) {
      flex-direction: column-reverse;
      align-items: stretch;

      .title {
        height: 92px;
        flex: 0 0 auto;
        border-top-left-radius: 0;
        border-bottom-right-radius: inherit;
        font-size: 20px;
      }

      .picture {
        width: 100%;
        height: 100%;
        flex: 1 0 0;
        border-top-left-radius: inherit;
        border-bottom-right-radius: 0;

        img {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
        }
      }
    }
  }

  @include mobile {
    display: flex;
    flex-direction: column-reverse;
  }
}

.title {
  margin: 0;
  background: $blue-dark;
  font-weight: 500;
  color: $pure-white;

  @include not-mobile {
    display: flex;
    align-items: center;
    flex: 1 1 50%;
    margin: 0;
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
    padding: 18px 18px calc(18px + 0.2em); // Vinci Sans font compensation
    font-weight: 500;
    font-size: 16px;
  }

  @include mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 0 auto;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    padding: 20px 20px calc(20px + 0.2em);
    background: $blue-dark;
    font-size: 18px;
    line-height: 1.2;
    text-align: center;
  }
}

.picture {
  img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: inherit;

    @supports (object-fit: cover) {
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }

  @include not-mobile {
    flex: 1 1 50%;
    height: 100%;
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
    overflow: hidden;
  }

  @include mobile {
    height: 170px;
    flex: 0 0 auto;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }
}

