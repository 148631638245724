@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.root {
  background-color: $primary-dark-blue;
  padding: 20px 24px;

  @include desktopTablet {
    display: none;
  }
}

.title {
  color: $pure-white;
  font-size: 32px;
  font-weight: 500;
  margin: 0;
  padding: 0;
}
