@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

$gutter: 20px;
$active-border-width: 3px;
$arrow-dim: 30px;
$arrow-font-size: 14px;

.root {
  display: flex;
  flex-direction: column;
}

.activeMedia {
  .media {
    display: none;
    height: auto;
    width: 100%;

    &.shown {
      display: block;
    }
  }

  &.video {
    position: relative;
    padding-top: 56.25%;

    .media {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      color: $pure-black;

      &.shown {
        display: flex;
      }
    }
  }
}

.medias {
  margin: $gutter (-$gutter / 2) 0;

  &:not(.slider) {
    display: flex;

    .mediaButton {
      flex: 0 0 25%;
      margin: 0 10px;
    }
  }

  &.slider {
    @include not-mobile {
      margin-right: $arrow-dim;
      margin-left: $arrow-dim;
    }

    @include mobile {
      max-width: calc(100% + #{$gutter});
    }
  }

  :global {
    .slick-list {
      padding: $active-border-width 0;
    }

    .slick-slide {
      padding: 0 10px;

      img {
        max-height: none;
      }
    }

    .slick-arrow {
      width: $arrow-dim;
      height: $arrow-dim;
      position: absolute;
      top: 50%;
      border: 0;
      border-radius: 100%;
      padding: 0;
      background: $pure-white;
      font-size: $arrow-font-size;
      color: $primary-dark-blue;
      transition: $transition-duration-fast;
      transition-property: opacity;

      &:before {
        display: none;
      }

      &:hover,
      &:focus,
      &:active {
        color: $pure-black;
      }

      &.slick-disabled {
        pointer-events: none;
        opacity: 0.3;
      }
    }

    .slick-prev {
      right: 100%;
      transform: translateY(-50%) rotate(90deg);
    }

    .slick-next {
      left: 100%;
      transform: translateY(-50%) rotate(-90deg);
    }

    .slick-dots {
      bottom: auto;
      margin-right: 0;
      position: relative;

      li {
        margin: 0;

        &.slick-active {
          button {
            &:before {
              color: $primary-dark-blue;
              opacity: 1;
            }
          }
        }

        button {
          &:before {
            color: $primary-dark-blue;
            font-size: 10px;
            opacity: 0.3;
          }
        }
      }
    }
  }
}

.mediaButton {
  border: 0;
  padding: 0;
  background: none;

  @include not-mobile {
    height: 80px;
  }

  @include mobile {
    height: 50px;
  }

  &.active {
    box-shadow: 0 0 0 $active-border-width $primary-dark-blue;
  }

  &.video {
    @extend %youtube-thumbnail;
  }
}

.mediaButtonImage {
  width: 100%;

  @supports (object-fit: cover) {
    height: 100%;
    object-fit: cover;
  }
}
