@import "src/assets/styles/1-settings";

.root {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  text-align: center;
}

.programLink {
  &:hover {
    text-decoration: none;

    .name {
      text-decoration: underline;
    }

    .location {
      color: $primary-dark-blue;
    }
  }
}

.name {
  color: $pure-black;
  font-size: 16px;
  font-weight: 500;
}

.location {
  color: $primary-blue;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}

.lotLink {
  color: $primary-blue;
  font-size: 16px;
  font-weight: 500;
  text-decoration: underline;
  text-transform: uppercase;

  &:hover {
    color: $primary-dark-blue;
  }
}
