@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.date {
  font-weight: 500;
}

.lot {
  font-weight: 500;
}

.price {
  font-weight: 500;
}

.surface {
  font-weight: 500;
}

button.lot {
  background: none;
  border: none;
  padding: 0;
  &:hover {
    text-decoration: underline;
    color: $primary-dark-blue;
  }
  &:focus,
  &:focus-visible {
    background: none;
    border: none;
  }

  @include mobileTablet {
    text-decoration: none;
    color: $pure-black;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
  }
}

.btnPlan {
  color: $primary-blue;
  font-size: 24px;
  border: none;
  background-color: unset;
  @include desktop {
    color: $black;
    &:hover {
      color: $primary-blue;
    }
  }
  @include mobileTablet {
    $THICK_CLICK: 5px;

    display: block;
    padding: $THICK_CLICK;
    margin: (-$THICK_CLICK) (-$THICK_CLICK - 3px) (-$THICK_CLICK) (-$THICK_CLICK);
  }
}

.planTypo {
  font-size: 20px;
}

.btnPlanIcon {
  display: block;
}

a.programContainer {
  display: block;
  font-weight: 500;

  &:hover {
    text-decoration: underline;
    color: $primary-dark-blue;
  }
  .programName,
  .programLocation {
    display: block;
  }

  .programName {
    color: $pure-black;

    &:hover {
      color: $primary-dark-blue;
    }

    @include mobileTablet {
      text-decoration: none;
      color: $pure-black;
      font-weight: 500;
    }
  }

  .programLocation {
    text-transform: uppercase;
    color: $primary-blue;

    &:hover {
      color: $primary-dark-blue;
    }
  }
}

.tax {
  color: $primary-blue;

  span.pastille {
    @include not-desktop {
      font-size: 14px !important;
      line-height: 16px !important;
      text-transform: uppercase;
      padding: 6px 10px;
    }
  }
}

.status {
  text-align: center;

  .statusTop {
    display: flex;
    justify-content: space-between;

    .alertBtn {
      min-width: 0;
      padding: 6px;
      margin: -6px;
      border: 0;
      background: unset;

      &:hover {
        .addAlert {
          display: none;
        }
        .addAlertHover {
          display: initial;
        }
      }

      .alertIcon {
        font-size: 18px;
        width: 16px;
        height: 18px;
        color: $black;
      }

      .addAlertHover {
        display: none;
      }
    }
  }

  .statutEndDateContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    @include tablet-landscape {
      position: absolute;
      left: 50%;
      right: 50%;
    }

    .statutEndDate {
      margin-top: -0.3em;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      font-variant: small-caps;
      white-space: nowrap;
      color: $pure-black;
      padding-right: 8px;
    }

    &:not(:empty) {
      padding-top: 6px;
    }
  }
  span.pastille {
    @include not-desktop {
      font-size: 14px !important;
      line-height: 16px !important;
      text-transform: uppercase;
    }
  }
}

.pastilleChipRoot {
  vertical-align: middle !important;

  @include not-desktop {
    height: 26px !important;
  }
}

.pastilleIcon {
  font-size: 11px !important;
}

div.controlledPrice {
  span:nth-child(1) {
    display: block;
  }
  span:nth-child(2) {
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
    text-transform: uppercase;
    color: #1b2633;
  }
}

.tooltip {
  text-transform: uppercase;
  color: $white;
  background-color: $pure-black;
  font-family: $vinciFontName;
  border-radius: 2px;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  padding: 8px;
  margin-bottom: 8px;
}

.arrowTooltip {
  color: $pure-black;
}

.fiscalityIcon {
  height: 50px;
  width: 50px;
}
