@import "assets/styles/1-settings";

.root {
  list-style: none;
  margin: 8px 0 0;
  padding: 0;
}

.item {
  font-size: 14px;
  margin: 6px 0;
  padding: 0 0 0 10px;
  position: relative;

  &:before {
    background-color: $grey;
    border-radius: 50%;
    content: "";
    height: 4px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
  }
}
