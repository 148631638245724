@import "assets/styles/1-settings";

.itemContent {
  align-items: center;
  display: flex;
  font-weight: 400;
  line-height: 1.2;

  > * {
    margin: 0 4px 0 0;
  }
}

.value {
  align-items: center;
  color: $primary-dark-blue;
  display: flex;
  font-weight: 500;
  white-space: nowrap;
}
