.paperWidthLg.v4 {
  max-width: 76rem !important; // stylelint-disable-line declaration-no-important
}

.dialogContentText.v4 {
  font-size: 1.6rem !important; // stylelint-disable-line declaration-no-important
  line-height: 1.8rem !important; // stylelint-disable-line declaration-no-important
}

.titre.v4 {
  font-size: 1.8rem;
}

.button.v4 {
  font-size: 1.3rem !important; // stylelint-disable-line declaration-no-important
  padding-left: 2rem !important; // stylelint-disable-line declaration-no-important
  padding-right: 2rem !important; // stylelint-disable-line declaration-no-important
  margin-bottom: 2.5rem !important; // stylelint-disable-line declaration-no-important
}
