@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1 0 auto;
  background-color: $white;

  .tabs {
    background-color: $pure-white;
    box-shadow: 0 4px 15px 0 rgba(15, 73, 139, 0.05);
    padding: 12px;
    position: relative;

    @include mobileTablet {
      background-color: $primary-dark-blue;
      padding: 10px 0;
    }
  }

  .tabsIndicator {
    display: none;
  }

  .tabRoot {
    align-items: center;
    border-radius: 40px;
    color: $primary-dark-blue;
    display: flex;
    font-family: $vinciFontName;
    font-size: 16px;
    font-weight: 500;
    height: 56px;
    justify-content: center;
    margin: 0 15px;
    min-width: 216px;
    opacity: 1;
    padding: 0;
    text-transform: none;

    @include mobileTablet {
      background-color: transparent;
      border-radius: 4px;
      color: $white;
      height: 36px;
      margin: 0 9px;
      min-width: auto;
      opacity: 0.46;
      padding: 0 12px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .tabRootProfile {
    align-items: center;
    border-radius: 40px;
    color: $primary-dark-blue;
    display: flex;
    font-family: $vinciExpanded;
    font-size: 16px;
    font-weight: 500;
    height: 56px;
    justify-content: center;
    margin: 0 15px;
    min-width: 216px;
    opacity: 1;
    padding: 0;
    text-transform: none;

    @include mobileTablet {
      background-color: transparent;
      border-radius: 4px;
      color: $white;
      height: 36px;
      margin: 0 9px;
      min-width: auto;
      opacity: 0.46;
      padding: 0 12px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .tabSelected {
    background-color: $primary-dark-blue;
    color: $pure-white;

    @include mobileTablet {
      background-color: rgba(255, 255, 255, 0.16);
      opacity: 1;
    }
  }

  .tabSelectedProfile {
    background-color: $blue-dark-cerulean;
    color: $pure-white;

    @include mobileTablet {
      background-color: rgba(255, 255, 255, 0.16);
      opacity: 1;
    }
  }

  .tabsScrollable {
    padding: 0 24px;
  }
}

.sticky {
  z-index: 10;
}

.tabPanel {
  flex-direction: column;
  flex: 1 0 auto;

  &:not([hidden]) {
    display: flex;
  }

  > * {
    flex: 1 0 auto;
  }
}
