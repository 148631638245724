@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

$widths: (
  desktop: 70vw,
  tablet: 93vw,
  mobile: 100vw,
);

.root {
  @include desktopTablet {
    width: map-get($widths, desktop);
    margin-right: auto;
    margin-left: auto;
  }

  @include tablet-portrait {
    width: map-get($widths, tablet);
    margin-right: auto;
    margin-left: auto;
  }

  @include mobile {
    width: map-get($widths, mobile);
    margin-right: auto;
    margin-left: auto;
  }

  :global {
    .slick-slide {
      flex: 1 0 100%;
      opacity: 0;
      transition: opacity 0.25s;

      &.slick-active {
        opacity: 1;
      }

      > div {
        height: 100%;
        justify-content: center;
        align-items: center;
      }

      @include desktopTablet {
        max-width: map-get($widths, desktop);
      }

      @include tablet-portrait {
        max-width: map-get($widths, tablet);
      }

      @include mobile {
        max-width: map-get($widths, mobile);
      }

      .vinci-youtube-player {
        @include desktopTablet {
          width: map-get($widths, desktop);
        }

        @include tablet-portrait {
          width: map-get($widths, tablet);
        }

        @include mobile {
          width: map-get($widths, mobile);
        }
      }
    }

    .slick-list,
    .slick-track {
      @include desktopTablet {
        max-height: 80vh;
      }

      @include tablet-portrait {
        max-height: 60vh;
      }

      @include mobile {
        max-height: 60vh;
      }
    }

    .slick-track {
      display: flex;
    }

    .slick-arrow {
      background: $pure-white;
      border-color: $primary-dark-blue;

      &:hover,
      &:focus,
      &:active {
        background: $primary-dark-blue;

        i {
          color: $pure-white;
        }
      }

      i {
        color: $primary-dark-blue;
        font-size: 24px;
      }

      &:before {
        display: none;
      }
    }

    .slick-dots {
      bottom: auto;
      margin-top: 20px;
      margin-right: 0;

      li {
        margin: 0;

        &.slick-active {
          button {
            &:before {
              color: $primary-dark-blue;
              opacity: 1;
            }
          }
        }

        button {
          &:before {
            color: $pure-white;
            font-size: 10px;
            opacity: 1;
          }
        }
      }
    }
  }
}

.mini {
  display: flex;
  align-items: center;
  margin: 10px auto;

  @include mobile {
    display: none;
  }
}

.miniBtn {
  padding: 0;
  border: 0;
  background: none;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 10px;
  }

  :global(.videoWrapper) {
    @extend %youtube-thumbnail;
  }

  img {
    width: auto;
    height: 50px;
  }
}

.active {
  color: $white;
  margin-top: 14px;
  text-align: center;
  font-size: 18px;

  @include mobile {
    display: none;
  }
}
