@import "assets/styles/1-settings";

.buttons {
  display: flex;
  flex-direction: column;
  margin: -8px 0;
  position: relative;
  width: 100%;

  &.odd {
    .button {
      background-color: $pure-white;
    }

    .buttonDropdown {
      background-color: $white;
    }
  }

  .buttonsGroup {
    display: flex;
    flex-direction: column;
    margin: 6px 0;
    width: 100%;
  }

  .buttonWrapper {
    margin: 2px 0;
  }

  .button {
    background-color: $white;
    border-color: $grey-light;
    height: 35px;
    justify-content: start;
    letter-spacing: unset;
    margin: 0;
    min-width: 0;
    width: 100%;

    &:first-child {
      margin: 0;
    }
  }

  .buttonLabel {
    color: $primary-dark-blue;
    font-family: $vinciFontName;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    text-transform: none;
  }

  .buttonSvgIcon {
    height: 18px;
    margin: 0 10px 0 0;
    width: 18px;
  }
}
