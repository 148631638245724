@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.paper {
  background-color: transparent;
  box-shadow: none;
}

.popin {
  background-color: $pure-white;
  border: 1px solid $grey;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(15, 73, 139, 0.104988);
  padding: 18px 18px 27px 18px;
  margin: 0 0 0 10px;
  position: relative;
  width: 420px;

  @include mobile {
    margin-left: 0;
    margin-top: $appbar-height-mobile;
    width: 100vw;
  }
}

.popinClose {
  background: none;
  border: none;
  color: $pure-black;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 18px;
  top: 18px;
}

.popinTitle {
  align-items: center;
  color: $primary-dark-blue;
  display: flex;
  font-size: 20px;
  font-weight: 500;
}

.popinTitleIcon {
  font-size: 24px;
  margin: 5px 8px 0 0;
}

.popinButtonWrapper {
  text-align: center;
  margin: 22px 0 0;
}

.popinButton {
  background-color: $primary-blue;
  border: 1px solid $primary-blue;
  border-radius: 30px;
  color: $pure-white;
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  padding: 10px 18px 13px;
  text-transform: uppercase;

  &:hover {
    background-color: $grey-light-4;
    border: 1px solid $primary-dark-blue;
    color: $primary-dark-blue;
  }

  span {
    font-size: 16px;
  }
}
