@import "assets/styles/1-settings";
@import "assets/styles/2-tools";
@import "assets/styles/variables";

$gutter: (
  desktop: 40px,
  mobile: 20px,
);
$mobileSlideWidth: 245px;

.root {
  display: flex;
  flex-direction: column;

  :global {
    .slick-list {
      overflow: visible;
    }

    .slick-track {
      display: flex;

      .slick-slide {
        align-self: stretch;
        height: auto;

        > div {
          height: 100%;
        }
      }
    }
  }

  @include not-mobile {
    padding: 70px $home-margin-h;
  }

  @include mobile {
    padding: 35px (map-get($gutter, mobile) / 2);
  }

  .unfold {
    align-self: center;
    height: 45px;
    margin-top: 40px;
    font-size: 15px;
  }
}

.title {
  margin: 0;
  font-weight: 500;
  color: $blue-dark;

  @include not-mobile {
    margin-bottom: 60px;
    font-size: 40px;
    line-height: 1.25;
  }

  @include mobile {
    padding: 0 20px;
    font-size: 23px;
    line-height: 1.1;
    text-align: center;
  }
}

.list {
  @include not-mobile {
    $gutter: 40px;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: $gutter;

    > :first-child {
      grid-column-end: span 2;
    }
  }

  @include mobile {
    margin-top: 30px;

    .dots {
      margin: 25px 0 0;
    }
  }
}

.item {
  @include mobile {
    width: ($mobileSlideWidth + map-get($gutter, mobile));
    height: 100%;
    padding: 0 (map-get($gutter, mobile) / 2);
  }
}
