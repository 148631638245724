@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.toggle {
  align-items: center;
  background: none;
  border: 2px solid $primary-dark-blue;
  border-radius: 50%;
  color: $primary-blue;
  display: flex;
  font-size: 18px;
  font-weight: 500;
  height: 22px;
  justify-content: center;
  line-height: 1;
  width: 22px;

  &:hover {
    background-color: $primary-blue-light;
  }
}

.content {
  background-color: $pure-white;
  border: 1px solid $grey;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(15, 73, 139, 0.104988);
  padding: 10px 16px;
  position: relative;
}

.close {
  background: none;
  border: none;
  color: $primary-dark-blue;
  font-size: 14px;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 10px;
  top: 10px;
}
