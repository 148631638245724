.tab {
  display: flex;
  align-items: center;

  .tabLoader {
    margin-left: 10px;
    color: inherit;
  }
}

.stepsSummary {
  max-width: 790px;
  margin: 0 auto 34px;
}

.stepsSummaryLarge {
  max-width: 890px;
}
