@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.bgLayer {
  background-color: transparent;
  border: 0;
  height: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  position: fixed;
  text-indent: -99999px;
  top: 0;
  width: 100%;
  z-index: 149;
}
