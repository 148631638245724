@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.root {
  background-color: $white;
  display: flex;
  justify-content: center;
  padding: 27px 0;

  @include mobileTablet {
    padding: 0;
  }
}

.panel {
  background-color: $pure-white;
  border: 1px solid $grey-light;
  border-radius: 4px;
  max-width: 961px;
  width: 100%;
}

.header {
  align-items: center;
  border-bottom: 1px solid $grey-light;
  display: flex;
  justify-content: space-between;
  margin: 0 24px;
  padding: 24px 0;

  @include mobileTablet {
    border: 0;
    padding-bottom: 12px;
  }
}

.title {
  color: $pure-black;
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  padding: 0;
}
