@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";
@import "src/assets/styles/variables";

.lotsListHeader {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: sticky;
  top: $programMenuAnchorsHeight;
  width: 100%;
  z-index: 1;
}
