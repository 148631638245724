@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.root {
  position: relative;
}

.header {
  align-items: center;
  background-color: $grey-light;
  border-radius: 8px 8px 0 0;
  display: flex;
  font-size: 18px;
  font-weight: 500;
  justify-content: center;
  padding: 24px 16px;

  @include mobileTablet {
    border-radius: 0;
  }
}

.titleIcon {
  margin: 0 16px 0 0;
}

.lots {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 -12px;

  @include tablet-landscape {
    margin: 0 -24px;
  }

  @include mobileTablet {
    flex-direction: column;
    width: 100%;
  }
}

.lot {
  flex: 0 0 auto;
  padding: 0 12px;

  @include desktopTablet {
    width: 33.33%;
  }

  @include tablet-landscape {
    max-width: 360px;
    padding: 0;
  }

  @include mobileTablet {
    max-width: 360px;
    width: 100%;
  }
}

.buttons {
  display: flex;
  justify-content: center;
  margin: 0 -4px;

  @include desktopTablet {
    position: absolute;
    right: 24px;
    top: 16px;
  }

  @include mobileTablet {
    padding: 16px 0;
  }
}

.buttonsItem {
  margin: 0 4px;

  .button {
    background-color: $pure-white;
    height: 42px;
    max-width: none;
    min-width: auto;
    padding: 0;
    width: 50px;

    @include mobileTablet {
      width: 109px;
    }

    &:hover {
      background-color: $primary-blue-light;
      border: 2px solid rgba(5, 117, 230, 0.4);
    }
  }

  .buttonIcon {
    font-size: 20px;
    margin: 0;
  }

  .deleteBtn {
    &:hover {
      border-color: $pink;
      color: $pink;
    }

    .buttonIcon {
      color: $pink;
    }
  }
}
