@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.list {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @include mobile {
    justify-content: center;
    padding: 16px 12px;
  }
}

.item {
  display: flex;
  width: 40px;
  justify-content: center;
  align-items: center;
  position: relative;

  @include not-mobile {
    &:not(:first-child) {
      &::before {
        content: "";
        display: block;
        height: 100%;
        width: 1px;
        position: absolute;
        top: 50%;
        left: 0;
        background: $grey-light;
        transform: translateY(-50%);
      }
    }
  }

  @include mobile {
    flex-grow: 1;
    margin: 0 4px;
    width: auto;
  }
}

.button {
  background: none;

  // Tablet & Desktop
  @include not-mobile {
    border: 0;
    padding: 5px;
  }

  // Mobile
  @include mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 42px;
    border: 2px solid $grey;
    border-radius: 6px;
    padding: 0;
  }
}

.icon {
  width: 24px;
  height: 24px;
}

.modalText {
  text-align: center;
}

div.modalPaper {
  @include desktopTablet {
    max-width: 800px;
  }
}

div.modalScrollPaper {
  align-items: center !important;
}
