@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.partenairesContainer {
  @include desktopTablet {
    margin: 60px 15vh;
  }

  padding: 5px 35px;
  height: auto;
  font-family: $vinci;
  font-size: 16px;
  background: $grey-light;
  border-radius: 4px;

  h2 {
    font-weight: 300;
    text-align: center;
    line-height: 1.25;
    font-size: 28px;
    &:nth-child(1) {
      margin-bottom: 0;
    }
    &:nth-child(2) {
      margin-top: 0;
    }
  }

  h4 {
    text-decoration: underline;
    font-size: 18px;
  }

  h5 {
    font-size: 16px;
    font-weight: normal;
    text-decoration: underline;
  }

  .button {
    border: none;
    text-decoration: underline;
    color: $primary-blue;
  }
}
