@import "src/assets/styles/1-settings";

.content {
  display: flex;
  justify-content: center;
  padding: 10px 0;
}

.programLink {
  &:hover {
    .programName,
    .programLocation {
      color: $primary-dark-blue;
      text-decoration: underline;
    }
  }
}

.programName {
  color: $pure-black;
  font-size: 16px;
  font-weight: 500;
}

.programLocation {
  color: $primary-blue;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
}

.lotLink {
  color: $primary-blue;
  font-size: 16px;
  font-weight: 500;
  text-decoration: underline;
  text-transform: uppercase;

  &:hover {
    color: $primary-dark-blue;
  }
}

.value {
  color: $pure-black;
  font-size: 21px;
  font-weight: 500;
}

.status {
  padding: 6px 0;
}
