@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.loading {
  padding: 60px;
  text-align: center;
}

.table {
  border-collapse: collapse;
  width: 100%;

  :global(.MuiChip-root) {
    display: flex;
  }

  tr {
    border-bottom: 1px solid $grey;
    height: 53px;

    &:last-child {
      border: 0;
    }
  }

  th {
    font-size: 14px;
    font-weight: 400;
    padding: 0 60px;
    text-align: left;
    text-transform: uppercase;
    width: 20%;

    @include tablet-landscape {
      padding: 0 30px;
    }
  }

  td {
    padding: 0 60px;
    text-align: center;

    @include tablet-landscape {
      padding: 0 30px;
    }
  }
}
