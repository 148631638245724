@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

.root {
  display: flex;
  justify-content: center;
  align-items: center;
}

.item {
  padding: 0 8px;

  .button {
    background-color: transparent;
    border-width: 2px;
    height: 42px;
    min-width: auto;
    padding: 0;
    width: 42px;

    @include desktop-xxl {
      width: 50px;
    }

    @include mobileTablet {
      max-width: none;
      width: 55px;
    }

    &:hover {
      background-color: $primary-blue-light;
      border: 2px solid rgba(5, 117, 230, 0.4);
    }
  }

  .icon {
    color: $primary-dark-blue;
    font-size: 18px;
    margin: 0;
  }

  .svg {
    margin: 0;
    height: 20px;
    width: 20px;
  }
}
