@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.root {
  align-items: center;
  border-bottom: 2px solid $grey-light;
  display: flex;
  justify-content: space-between;
  padding: 10px 14px 10px 20px;

  .button {
    background-color: transparent;
    border-width: 1px;
    color: $primary-dark-blue;
    font-family: $vinciFontName;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: unset;
    height: 42px;
    max-height: none;
    max-width: none;
    min-width: auto;
    padding: 0;
    text-transform: none;
    width: 51px;

    &:hover {
      background-color: $primary-blue-light;
      border: 1px solid rgba(5, 117, 230, 0.4);
    }
  }

  .buttonSvgIcon {
    margin: 0;
    height: 20px;
    width: 20px;
  }
}

.docInfos {
  margin: 0 10px 0 0;
  text-align: left;
}

.nature {
  color: $pure-black;
  font-size: 14px;
}

.infos {
  color: $black;
  font-size: 14px;
  font-style: italic;
  margin: 5px 0 0;
}
