@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

.root {
  display: flex;
  flex-grow: 1;
  border-radius: 24px;
  box-shadow: 0 5px 21px rgba(15, 73, 139, 0.1);

  .autocomplete {
    .textFieldRoot {
      .input {
        border-right: 0;
        border-radius: 24px 0 0 24px;
        padding-left: 50px;
      }
    }

    .icon {
      left: 20px;
    }
  }
}

.item {
  align-items: center;
  display: flex;
  flex: 0 0 auto;

  &.auto {
    flex: 0;
  }

  &.location {
    flex: 1 0 auto;
    width: 10%;

    @include not-desktop {
      width: 45%;
    }
  }

  &.budget {
    width: 135px;
  }

  &.delivery {
    width: 150px;
  }

  .label {
    width: calc(100% - 30px);
    left: 15px;
    padding-right: 0;
    text-align: center;
    transform: translateY(-50%);

    &.focused {
      transform: translateY(-50%);
    }
  }
}

.filterPaper {
  padding: 16px;
}

.rangePopover {
  .rangeMenuList {
    margin: 0;
  }
}

.rangeMenuHeader {
  border-right: 0 !important;
  border-radius: 0 !important;
}

.rangeMenuHeaderLastEl {
  border-radius:  0 24px 24px 0 !important;
}
