@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.root {
  background-color: $orange;
  color: $pure-white;
}

.text {
  display: block;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;

  p {
    margin: 0;
  }
}

.vertical {
  height: 100%;
  position: relative;
  width: 100%;

  .text {
    text-align: center;
    left: 50%;
    position: absolute;
    line-height: 1;
    letter-spacing: -0.5px;
    top: 50%;
    transform: translate(-50%, -50%) rotate(180deg);
    writing-mode: vertical-rl;
  }
}
