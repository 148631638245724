@import "../common/assets/styles/defaults";
@import "../common/assets/styles/tools";
@import "BlocAlerteModalVI3P.module";
@import "BlocAlerteModalV4.module";

.dialogContent {
  strong {
    font-weight: 700;
  }

  @include desktopTablet {
    display: flex;
  }
  @include mobileTablet {
    text-align: center;
  }
}
.dialogContentText {
  color: black !important;
  padding: 18px;

  @include mobileTablet {
    text-align: left;
  }
}

.titre {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dialogActions {
  justify-content: center !important; // stylelint-disable-line declaration-no-important
}

.button {
  border: 0.2rem solid $blue !important; // stylelint-disable-line declaration-no-important
  border-radius: 2rem !important; // stylelint-disable-line declaration-no-important
}

.img {
  object-fit: contain;
  margin: 0 15px 0 15px;
}

.noTitle {
  justify-content: flex-end;
}
