@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.root {
  background-color: $orange;
  border-radius: 6px;
  color: $pure-white;
  padding: 11px 24px;
  text-align: center;
}

.title,
.text {
  line-height: 1.2;

  p {
    margin: 0 0 10px 0;

    @include mobile {
      margin: 0 0 12px 0;
    }
  }
}

.title {
  font-size: 28px;

  @include mobile {
    font-size: 32px;
  }
}

.text {
  font-size: 24px;
}

.cta {
  .ctaBtn {
    color: $primary-dark-blue;
    font-size: 14px !important;
    padding: 4px 20px;

    &:hover {
      color: $primary-blue;
    }
  }
}
