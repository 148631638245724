@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.root {
  margin-top: 45px;

  @include mobile {
    font-size: 19px;
  }
}

.title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
}

.text {
  margin-bottom: 20px;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.5;

  h3 {
    font-weight: 500;
    font-size: 20px;
  }
}

.link {
  font-weight: 500;
  color: $blue;
  margin: 20px 0;
}

.img {
  position: relative;
  display: block;
  max-width: 100%;
  margin: 20px 0;
}
