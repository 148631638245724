@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.modalRoot {
  z-index: 1301 !important;

  .modalPaper {
    max-width: 48rem;
  }
}

.root {
  background-color: $primary-blue;
  padding: 64px 48px;
  position: relative;

  @include mobile {
    padding: 48px 32px;
  }

  .iconButton {
    color: $pure-white;
    font-size: 12px;
    position: absolute;
    top: 10px;
    right: 15px;

    @include mobile {
      top: 5px;
      right: 10px;
    }
  }
}

.text {
  color: $pure-white;
  font-size: 18px;
}
