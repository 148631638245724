@import "src/assets/styles/1-settings";

.root {
  .checkbox {
    color: $black;
    opacity: 0.3;
    padding: 0;

    &.checked {
      color: $primary-dark-blue;
      opacity: 1;
    }

    &:hover {
      background-color: unset !important;
    }
  }

  &.multiline {
    align-items: flex-start;

    .label {
      margin-left: 16px;
    }
  }
}

.label {
  color: $pure-black;
  font-family: $vinciFontName;
  font-size: 16px;
  line-height: 19px;
  margin: 0 0 0 4px;
}

.labelTitle {
  font-weight: 500;
}

.labelSubtitle {
  margin-top: 10px;
  font-size: 0.875em;
}
