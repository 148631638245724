@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.root {
  display: flex;
  margin: 0 -8px;

  @include not-desktop {
    flex-direction: column;
    margin: 0;
  }

  @include mobileTablet {
    margin: 0;
  }
}

.col {
  flex: 1 0 auto;
  padding: 0 8px;

  @include not-desktop {
    padding: 4px 0;
  }

  @include mobileTablet {
    padding: 8px 0;
    position: relative;

    &:first-child {
      padding-top: 0;
    }
  }

  > div {
    height: 100%;
  }
}

.colImage {
  width: 19.5%;
}

.colInfos {
  @include not-desktop {
    width: 71.5vw;
  }

  @include mobileTablet {
    width: 100%;
  }
}

.colAdditionalInfos {
  @include not-desktop {
    width: 71.5vw;
  }

  @include mobileTablet {
    width: 100%;
  }
}

.colFinancialInfos {
  @include not-desktop {
    width: 71.5vw;
  }

  @include mobileTablet {
    width: 100%;
  }
}

.colHousingInfos {
  flex-grow: 1;
}

.infos {
  background-color: $white;
  border-radius: 4px;
  padding: 16px 24px;

  .odd & {
    background-color: $pure-white;
  }

  > .list {
    margin-top: 14px;
  }
}

.infosTitle {
  color: $primary-dark-blue;
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 8px;
  padding: 0 0 6px;
  position: relative;

  @include mobileTablet {
    padding-right: 40px;
  }

  &:after {
    background-color: $primary-dark-blue;
    bottom: 0;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    width: 50px;
  }
}
