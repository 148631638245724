@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

.root {
  width: 100%;

  &.open {
    .textFieldRoot {
      .inputRoot {
        border-radius: 24px 24px 0 0;
      }
    }
  }

  &:not(.simple) {
    &.focused {
      .textFieldRoot {
        .inputRoot {
          background-color: $pure-white;
          border-color: $grey;
          caret-color: $primary-blue;

          @include not-desktop {
            &:hover {
              background-color: $pure-white;
              border-color: $grey;
            }
          }
        }
      }

      @include mobileTablet {
        margin: -16px;
        width: 100vw;

        .textFieldRoot {
          .inputRoot {
            border: 0;
            border-radius: 0;
            min-height: 72px;
            padding-right: 65px;
          }
        }
      }
    }
  }

  &.simple {
    &.focused {
      margin: 0;
      width: 100%;
    }

    .textFieldRoot {
      .inputRoot {
        border: 0;
        border-radius: 0;
        padding: 0 0 0 26px;
      }

      .inputAdornment {
        left: 0;
      }
    }
  }

  .textFieldRoot {
    height: 100%;

    .inputRoot {
      border: 1px solid $grey;
      border-radius: 28px;
      background: $pure-white;
      color: $primary-dark-blue;
      font-family: $vinciFontName;
      font-size: 16px;
      font-weight: 500;
      min-height: 48px;
      padding: 0 24px 0 60px;

      @include mobileTablet {
        min-height: 40px;
        padding-right: 50px;
      }

      &:after,
      &:before {
        display: none;
      }

      &:hover {
        background-color: $primary-blue-light;
        border-color: rgba(5, 117, 230, 0.4);
      }

      &.adornedEnd {
        padding-right: 0;
      }
    }

    .inputFocused {
      border-radius: 24px 24px 0 0;
    }

    .input {
      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        font-family: $vinciFontName;
        font-size: 16px;
        line-height: 19px;
        font-weight: normal;

        opacity: 1;
        color: $pure-black;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        font-family: $vinciFontName;
        font-size: 16px;
        line-height: 19px;
        font-weight: normal;

        opacity: 1;
        color: $pure-black;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        font-family: $vinciFontName;
        font-size: 16px;
        line-height: 19px;
        font-weight: normal;

        opacity: 1;
        color: $pure-black;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        font-family: $vinciFontName;
        font-size: 16px;
        line-height: 19px;
        font-weight: normal;

        opacity: 1;
        color: $pure-black;
      }
    }

    .inputAdornment {
      left: 24px;
      margin: 0 16px 0 0;
      padding: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      .svgIcon {
        width: 18px;
        height: 18px;
      }
    }

    .tag {
      margin: 4px;
    }

    .chipLimit {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 34px;
      height: 34px;
      border-radius: 100%;
      padding-bottom: 0.2em;
      background: $primary-dark-blue;
      font-size: 16px;
      color: $pure-white;
    }
  }
}

.popper {
  @include mobileTablet {
    width: calc(100vw + 6px) !important;
  }

  .paper {
    border: 1px solid $grey;
    border-top: 0;
    border-radius: 0 0 24px 24px;
    box-shadow: 0 5px 21px 0 rgba(15, 73, 139, 0.1);
    margin: 0;

    @include mobileTablet {
      border: 0;
      border-radius: 0;
    }
  }

  .noOptions {
    color: $black;
    font-family: $vinciFontName;
    font-size: 15px;
    padding: 24px;
  }

  .listbox {
    max-height: none;
    overflow: unset;
    padding: 0;
  }

  .groupLabel {
    background-color: $primary-blue-light;
    color: $primary-blue;
    font-family: $vinciFontName;
    font-size: 15px;
    font-weight: 400;
    line-height: 1;
    padding: 10px 24px;
    text-transform: uppercase;

    @include mobileTablet {
      font-size: 14px;
    }
  }

  .option {
    color: $black;
    font-family: $vinciFontName;
    font-size: 15px;
    line-height: 1;
    padding: 7px 24px 5px;

    @include mobileTablet {
      font-size: 14px;
    }
  }

  .typographyRoot {
    display: flex;
    font-family: $vinciFontName;
    font-size: 15px;
    justify-content: space-between;
    width: 100%;

    @include mobileTablet {
      font-size: 14px;
    }
  }
}

.highlight {
  color: $primary-dark-blue;
  font-weight: 500;
}

:global(.search-highlight) {
  color: $primary-dark-blue;
  font-weight: 500;
}

.wrapper {
  width: 100%;
}

.toastLink {
  border: 0;
  padding: 0;
  background: none;
  font-weight: 700;
  text-decoration: underline;
}
