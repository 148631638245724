@import "assets/styles/1-settings";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 0;
  border: 0;
  border-radius: 100%;
  background: none;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  transition: background-color 0.25s ease-in-out;

  &:not(.big) {
    width: 7.86em;
    height: 7.86em;
    padding: 0.85em 0.85em 1em;

    .circle {
      width: calc(100% - 0.83em);
      height: calc(100% - 0.83em);
    }
  }

  &.big {
    width: 7.22em;
    height: 7.22em;
    padding: 0.8em;

    .circle {
      width: 105%;
      height: 105%;
    }
  }

  &:not(.active) {
    .label {
      margin: auto 0;
    }
  }

  &.active {
    .label {
      margin: 0.2em 0 auto;
    }
  }
}

.circle,
.icon,
.label,
.countdown {
  transition: inherit;
  transition-property: color;
}

.circle {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  transform: translate(-50%, -50%);

  path {
    fill: currentColor;
  }
}

.icon {
  width: 1em;
  height: 1em;
  --sprite-static-fill: currentColor;
  margin-bottom: 0.2em;
  font-size: 1.55em;
}

.countdown {
  margin-top: 0.2em;
  font-size: 0.85em;
}
