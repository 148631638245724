@import "src/assets/styles/1-settings";

.listRoot {
  display: flex;
  flex-direction: column;

  &:nth-child(3) {
    margin-left: 18px;
  }
}

div.listItemRoot {
  align-content: center;
  justify-content: center;
  min-height: 60px;

  &:hover {
    background: none;
  }

  &:last-child {
    margin-bottom: 18px;
  }

  &:first-child {
    margin-top: 18px;
  }
}

.dividerRoot {
  background-color: $grey-light;
}

button.buttonIconMobile,
a.buttonIconMobile {
  background-color: unset;
  color: $primary-dark-blue;
  font-family: $vinciFontName;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  min-width: 180px;
  position: relative;
  text-transform: none;

  .notifs {
    left: 16px;
    position: absolute;
    top: 10px;
  }
}

.startIcon {
  width: 23px;
}

span.buttonIconMobileLabel {
  justify-content: left;
}
