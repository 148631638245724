@import "src/assets/styles/1-settings";

.root {
  align-items: center;
  display: flex;
  margin: 0 0 12px;
}

.icon {
  flex: 0 0 auto;
  height: 16px;
  margin: 0 8px 0 0;
  width: 16px;
}

.label {
  font-size: 14px;
  line-height: 1;
}

.value {
  color: $primary-dark-blue;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  margin-left: 6px;
  white-space: nowrap;
}
