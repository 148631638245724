@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

.root {
  display: grid;
  justify-items: center;
  align-items: stretch;
  gap: $search-program-grid-gap;
  padding-top: $search-result-promo-border-width;

  @include desktop {
    grid-template-columns: repeat(2, 1fr);
    padding-right: map-get($search-program-grid-padding-x, desktop);
    padding-left: map-get($search-program-grid-padding-x, desktop);

    &.isPanelExpanded {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @include desktop-xxl {
    &.isPanelExpanded {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  @include tablet {
    grid-template-columns: repeat(2, 1fr);
    padding-right: map-get($search-program-grid-padding-x, tablet);
    padding-left: map-get($search-program-grid-padding-x, tablet);
  }

  @include mobile {
    display: flex;
    flex-direction: column;
    padding-right: map-get($search-program-grid-padding-x, mobile);
    padding-left: map-get($search-program-grid-padding-x, mobile);
  }
}

.scroll {
  height: 100%;
  max-height: 100%;
  overflow: hidden;

  :global {
    .os-host {
      padding-right: 0;

      .os-scrollbar {
        right: 4px;

        .os-scrollbar-track {
          background-color: $grey;
        }
      }
    }
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  max-height: 100%;
}

.noResults {
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  span:first-child {
    color: $primary-dark-blue;
    font-size: 20px;
    font-weight: 500;
  }
  span:nth-child(2) {
    font-size: 20px;
    line-height: 31px;
    color: $pure-grey;
  }
}
