@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.root {
}

.inner {
  display: flex;
  justify-content: space-between;
  margin: 0 -10px;
}

.innerCol {
  padding: 0 10px;
}

.alertWrapper {
  align-items: center;
  margin-top: 16px;
}

.title {
  color: $pure-black;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
}

.date {
  color: $pure-grey;
  font-size: 16px;
  line-height: 1;
  margin: 7px 0 0;

  @include mobile {
    margin-top: 3px;
  }
}

.filters {
  color: $pure-grey;
  font-size: 16px;
  line-height: 1.2;
  margin: 9px 0 0;

  @include mobile {
    margin-top: 6px;
  }
}

.linkWrapper {
  margin: 11px 0 0;

  .linkLabel {
    color: $pure-black;
    font-size: 16px;
    font-weight: 500;
  }

  sup {
    align-items: center;
    background-color: $pink;
    border-radius: 50%;
    color: $pure-white;
    display: inline-flex;
    font-size: 10px;
    font-weight: 700;
    height: 15px;
    justify-content: center;
    line-height: 1;
    width: 15px;
  }
}

.alertText {
  color: $black;
  font-size: 16px;
  line-height: 1.2;
}

.buttons {
  display: flex;
  flex-direction: column;
  margin: -8px 0;
}

.buttonsItem {
  padding: 8px 0;

  .button {
    background-color: $pure-white;
    border: 1px solid $grey;
    border-radius: 4px;
    height: 42px;
    margin: 0;
    max-width: none;
    min-width: auto;
    padding: 0;
    width: 50px;

    @media (hover: hover) {
      &:hover {
        background-color: $primary-blue-light;
        border: 1px solid rgba(5, 117, 230, 0.4);
      }
    }
  }

  .buttonSvgIcon {
    height: 20px;
    margin: 0;
    width: 20px;
  }

  .buttonIcon {
    font-size: 20px;
    margin: 0;
  }

  .buttonLoader {
    color: $primary-blue;
  }

  .deleteBtn {
    .buttonIcon {
      color: $red;
    }

    @media (hover: hover) {
      &:hover {
        border-color: $red;
      }
    }
  }

  .buttonIconHover {
    display: none;
  }

  .alertBtn {
    align-items: center;
    color: $black;
    display: flex;
    justify-content: center;

    .buttonIconDefault {
      display: none;
    }

    .buttonIconHover {
      display: block;
    }

    .buttonSvgIcon {
      fill: $primary-dark-blue;
    }

    &.alertBtnOff {
      .buttonIconDefault {
        display: block;
      }

      .buttonIconHover {
        display: none;
      }

      @media (hover: hover) {
        &:hover {
          .buttonIconHover {
            fill: $primary-blue;
          }
        }
      }
    }

    @media (hover: hover) {
      &:hover {
        .buttonIconDefault {
          display: none;
        }

        .buttonIconHover {
          display: block;
        }
      }
    }
  }
}

.recipient {
  color: $black;
  font-size: 16px;

  strong {
    font-weight: 700;
  }
}
