@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.show {
  display: block;
  height: 0;
  left: 0;
  opacity: 1;
  position: absolute;
  top: 0;
  transition: all 0.1s;
  width: 0;

  .modalRoot {
    left: 50%;
    overflow: visible;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 560px;
    z-index: 2100;

    @include mobileTablet {
      width: calc(100vw - 32px);
      z-index: 3000;
    }

    .modalHeader {
      display: flex;
      justify-content: flex-end;

      .iconButton {
        padding: 26px 25px 16px 25px;

        &:hover {
          background: none;
        }

        .svgIcon {
          height: 15px;
          width: 15px;
        }
      }
    }

    .modalContent {
      margin: 0;
      padding: 0 70px 40px;

      @include mobileTablet {
        padding: 0 16px 20px;
      }

      .title {
        color: $pure-black;
        font-family: $vinciFontName;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        margin: 0 0 24px;
        text-align: center;

        @include mobileTablet {
          font-size: 22px;
        }
      }

      .form {
        margin: 0 0 32px;
      }

      .confirm {
        font-size: 16px;
        margin: 0 0 32px;
        text-align: center;
      }
    }

    .modalFooter {
      .buttons {
        display: flex;
        justify-content: center;
        width: 100%;

        @include mobileTablet {
          flex-direction: column-reverse;
        }
      }

      .buttonsItem {
        padding: 0 8px;

        @include mobileTablet {
          padding: 4px 0;
        }
      }

      .button {
        height: 45px;
        letter-spacing: unset;
        padding: 0 50px;
        white-space: nowrap;

        @include mobileTablet {
          width: 100%;
        }
      }
    }
  }

  .modalSkin {
    background: $pure-white;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(15, 73, 139, 0.104988);
    height: auto;
    position: relative;
    text-shadow: none;
    width: auto;
  }

  .modalOuter {
    position: relative;
  }
}
