.page-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

// Icomoon
.icon {
  @extend %icomoon;

  @each $name, $glyph in $icomoon {
    &--#{$name}::before {
      content: $glyph;
    }
  }
}

// Titles
.title-light {
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 44px;
  /* identical to box height, or 110% */
  text-align: center;
}

.title-regular {
  font-size: 32px;
  line-height: 36px;
  /* identical to box height, or 112% */
  text-align: center;
}

.title-medium-28 {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  /* identical to box height, or 114% */
  text-align: center;
}

.title-medium-26 {
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 30px;
  /* identical to box height, or 115% */
  text-align: center;
}

.title-medium-24 {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  /* identical to box height, or 117% */
  text-align: center;
}

.title-medium-20 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  text-align: center;
}

// Labors
.content-regular-16 {
  font-size: 16px !important;
  line-height: 20px !important;
  /* identical to box height, or 125% */
  text-align: center;
}

.content-regular-15 {
  font-size: 15px;
  line-height: 19px;
  /* identical to box height, or 127% */
  text-align: center;
}

.content-medium-18 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height, or 122% */
  text-align: center;
}

.content-medium-14 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */
  text-align: center;
}

.content-medium-8 {
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 12px;
  /* identical to box height, or 150% */
  text-align: center;
  text-transform: uppercase;
}

.content-bold {
  font-size: 10px;
  line-height: 14px;
  /* identical to box height, or 140% */
  text-align: center;
  text-transform: uppercase;
}

// Structures
.checkmark-list {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 16px;

  li {
    $lineHeight: 1.25;

    position: relative;
    padding-left: 26px;
    line-height: 1.25;

    &:not(:first-child) {
      margin-top: 15px;
    }

    &::before {
      @extend %icomoon;
      content: map-get($icomoon, checked);
      position: absolute;
      top: 0.2em;
      left: 0;
      font-size: 16px;
      color: $green;
    }

    .footnote {
      display: block;
      margin-top: 3px;
      font-size: 12px;
    }
  }
}

.sr-only {
  position: absolute;
  width: 0.1rem;
  height: 0.1rem;
  padding: 0;
  margin: -0.1rem;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

// Buttons
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border: 1px solid transparent;
  border-radius: 50px;
  padding: 0 25px 0.2em;
  background: $blue-light;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  color: $pure-white;
  transition: $transition-duration-fast;
  transition-property: background-color, color, border-color;

  .icon {
    margin-right: 7px;
    margin-bottom: -0.1em;
    font-size: 12px;
  }

  &:hover,
  &:focus,
  &:active {
    border-color: #cecece;
    background: $pure-white;
    color: $blue-light;
  }

  &.reverse {
    border-color: #cecece;
    background: $pure-white;
    color: $blue-light;

    &:hover,
    &:focus,
    &:active {
      background: $blue-light;
      border-color: transparent;
      color: $pure-white;
    }
  }

  &.transparent {
    border-color: $pure-white;
    background: transparent;
    color: $pure-white;

    &:hover,
    &:focus,
    &:active {
      border-color: transparent;
      background: $pure-white;
      color: $blue-dark;
    }
  }

  &.soft {
    border: 0;
    background: $primary-blue-softer;
    color: $blue-light;

    &:hover,
    &:focus,
    &:active {
      background: $primary-blue-soft;
    }
  }

  &.borderBlue {
    border-color: $blue-dark;
    background: transparent;
    color: $blue-dark;

    &:hover, &:focus, &:active {
      background: $blue-dark;
      color: $pure-white;
    }
  }

  &.hoverTransparent {
    &:hover,
    &:focus,
    &:active {
      border-color: $pure-white;
      background: transparent;
      color: $pure-white;
    }
  }

  &.hoverBorderBlue {
    &:hover,
    &:focus,
    &:active {
      border-color: $blue-dark;
      background: transparent;
      color: $blue-dark;
    }
  }
}

.slider-dots {
  display: flex !important;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;

  .slick-active button::before {
    background: currentColor;
  }

  button {
    display: block;
    border: 0;
    padding: 4px;
    background: none;
    font-size: 0;

    &::before {
      content: "";
      display: block;
      width: 1em;
      height: 1em;
      border: 1px solid currentColor;
      border-radius: 100%;
      background: transparent;
      font-size: 6px;
      color: $blue-dark;
      transition: background-color $transition-duration-fast ease-in-out;
    }
  }

  &.white {
    button::before {
      color: $pure-white;
    }
  }
}
