@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.dialogRoot.selectorWeight {
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  @include mobile {
    position: fixed !important;
  }
}

.dialogScrollPaper.selectorWeight {
  align-items: flex-start;
}

.dialogPaper.selectorWeight {
  border-radius: 12px;
  box-shadow: 0 4px 4px rgba($pure-black, 0.25);

  @include mobile {
    margin: 56px 0 0 0;
  }
}

.closeButton {
  width: 2.333em;
  height: 2.333em;
  position: absolute;
  top: 10px;
  right: 10px;
  border: 0;
  padding: 10px;
  background: none;
  font-size: 15px;
  line-height: 1;
  transition: color 0.25s;

  &:hover,
  &:focus,
  &:active {
    outline: none;
    color: $primary-blue;
  }
}

.container {
  border-radius: inherit;
  padding: 70px;
  background: rgba($red, 0.04);
  text-align: center;
  color: $pure-black;

  @include mobile {
    padding: 56px 16px 32px;
  }
}

.title {
  font-weight: 500;
  font-size: 36px;
  line-height: 1;

  @include mobile {
    font-size: 22px;
  }
}

.subtitle {
  font-size: 18px;
  line-height: 1.1;
  margin: 0 auto;
  max-width: 360px;

  &:not(:first-child) {
    margin-top: 20px;
  }
}

.additionalTitle {
  font-size: 20px;
  font-style: italic;
  font-weight: 300;
}

.reload {
  padding: 60px;

  .title {
    font-size: 48px;

    @include mobile {
      font-size: 26px;
    }
  }

  .reloadLabel {
    font-weight: 500;
    font-size: 36px;
    margin: 20px 0;

    @include mobile {
      font-size: 22px;
    }
  }

  .reloadButton {
    background: none;
    border: 0;
    color: $primary-blue;
    font-weight: 500;
    margin: 0;
    padding: 0;
    text-decoration: underline;
    text-transform: lowercase;

    &:hover {
      text-decoration: none;
    }
  }
}
