@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

.modalRoot {
  .modalPaper {
    max-width: 580px;

    .modalFooter {
      .modalButton {
        @include not-mobile {
          width: auto;
        }
      }
    }
  }

  .modalInner {
    padding: 30px 75px 35px;

    .modalContent {
      text-align: left;
    }
  }
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 25px;

  .icon {
    margin-right: 10px;
    font-size: 18px;
  }

  .title {
    margin: 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.2;
  }
}

.program {
  margin-bottom: 20px;

  .name {
    margin-bottom: 6px;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.4;
    text-transform: uppercase;
    color: $primary-dark-blue;
  }

  .info {
    display: flex;
    align-items: center;
  }

  .pastille {
    margin-right: 4px;
  }

  .location {
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    color: $primary-blue;
  }
}

.subtext {
  font-size: 16px;
  color: $pure-grey;
}
