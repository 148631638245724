.header.vi3p {
  margin-top: 2.6rem;
  justify-content: flex-end;
  h2 {
    display: none;
  }

  button {
    &:focus {
      padding: 0 !important; // stylelint-disable-line declaration-no-important
    }
    color: #003c71;
    background: #ffffff;
    box-shadow: 4px 0px 15px rgba(15, 73, 139, 0.104988);
    border-radius: 8px;
    max-width: 48px;
    min-width: 48px;
    max-height: 48px;
    min-height: 48px;
  }
}

i.icon.vi3p {
  margin-right: -1.8rem;
  font-size: 16px !important; // stylelint-disable-line declaration-no-important
  color: #646464;
}

.root.vi3p {
  width: 1.8em;
  height: 1.55em;
  margin-top: 2rem;
  margin-right: -0.8rem;
}
