@import "assets/styles/1-settings";
@import "assets/styles/2-tools";
@import "assets/styles/variables";

$gutter: (
  desktop: 70px,
  mobile: 22px,
);
$desktopNbItems: 5;
$mobileSlideWidth: 200px;

.root {
  background: $blue-dark;

  @include not-mobile {
    padding: 70px ($home-margin-h - (map-get($gutter, desktop) / 2));
  }

  @include mobile {
    padding: 35px (20px - (map-get($gutter, mobile) / 2));

    :global {
      .slick-list {
        overflow: visible;
      }
    }
  }
}

.header {
  @include not-mobile {
    display: flex;
    align-items: flex-end;
    margin: 0 (map-get($gutter, desktop) / 2) 60px;
  }

  @include mobile {
    margin: 0 (map-get($gutter, mobile) / 2) 30px;
  }
}

.text {
  flex: 1 0 auto;
  line-height: 1.25;
  color: $pure-white;

  @include mobile {
    text-align: center;
  }
}

.title {
  font-weight: 500;

  @include not-mobile {
    margin: 0 0 20px;
    font-size: 40px;
  }

  @include mobile {
    margin: 0 0 15px;
    font-size: 23px;
  }
}

.subtitle {
  font-weight: 300;

  @include not-mobile {
    font-size: 28px;
  }

  @include mobile {
    font-size: 20px;
  }
}

.buttons {
  display: flex;
  gap: 10px;
  flex: 0 0 auto;

  .button {
    width: 35px;
    height: 35px;
    border: 1px solid;
    border-radius: 100%;
    padding: 0.1em 0 0;
    background: none;
    font-size: 14px;
    color: $pure-white;
  }

  .prev {
    transform: rotate(90deg);
  }

  .next {
    transform: rotate(-90deg);
  }

  @include mobile {
    display: none;
  }
}

.slider {
  :global {
    .slick-track {
      display: flex;
    }

    .slick-slide {
      height: auto;

      > div {
        height: 100%;
      }
    }
  }

  .contact {
    height: 100%;

    @include not-mobile {
      width: calc(#{100vw / $desktopNbItems} - #{$home-margin-h / $desktopNbItems});
      padding: 0 (map-get($gutter, desktop) / 2);
    }

    @include mobile {
      width: ($mobileSlideWidth + map-get($gutter, mobile));
      padding: 0 (map-get($gutter, mobile) / 2);
    }
  }
}
