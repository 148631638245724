@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

$track-thickness: 4px;
$thumb-diameter: 20px;

.root {
  overflow: hidden;

  .sliderRoot {
    padding: 8px 0;
  }

  .sliderRail {
    color: $grey;
    height: 4px;
  }

  .sliderTrack {
    color: $primary-blue;
    height: $track-thickness;
  }

  .sliderThumb {
    border: 3px solid $primary-blue;
    border-radius: 50%;
    color: $pure-white;
    height: $thumb-diameter;
    margin-left: -($thumb-diameter / 2);
    margin-top: -(($thumb-diameter - $track-thickness) / 2);
    width: $thumb-diameter;
  }

  .helperTextRoot {
    font-family: $vinciFontName;
    font-size: 12px;
  }

  .helperTextError {
    color: $red;
  }
}

.slider {
  margin: 0 0 24px;
  padding: 15px ($thumb-diameter / 2) 0;
}

.grid {
  $gutter: 24px;

  display: flex;
  margin: 0 (-$gutter / 2);

  .column {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    margin: 0 $gutter / 2;
  }
}

.subLabel {
  font-size: 14px;
  letter-spacing: 0;
  white-space: nowrap;

  @include mobileTablet {
    font-size: 12px;
  }
}
