@import "src/assets/styles/1-settings";

.root {
  height: inherit;
  max-height: inherit;
  outline: none;
  overflow: hidden;

  :global {
    .os-host {
      height: inherit;
      max-height: inherit;
      padding: 0 10px 0 0;

      &.os-host-scrollbar-vertical-hidden {
        padding: 0;

        .os-content {
          padding-right: 0 !important;
        }
      }

      .os-content {
        padding-right: 10px !important;
      }

      .os-scrollbar {
        padding: 0;

        .os-scrollbar-track {
          background: $grey-light;
          border-radius: 5px;

          .os-scrollbar-handle {
            background: $primary-dark-blue;
            border-radius: 5px;

            &.active {
              background: $primary-dark-blue;
            }

            &:hover,
            &:focus {
              background: $primary-dark-blue;
            }
          }
        }
      }

      .os-scrollbar-vertical {
        width: 10px;
      }
    }
  }
}
