@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

.root {
  font-weight: 700;
  font-size: 36px;
  line-height: 1;
  text-transform: uppercase;
  color: $pure-white;

  @include mobileTablet {
    font-size: 16px;
  }
}
