@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

button.lot {
  background: none;
  border: none;
  color: $primary-blue;
  font-weight: 500;
  padding: 0;
  text-decoration: underline;

  &:hover {
    color: $primary-dark-blue;
    text-decoration: none;
  }
  &:focus,
  &:focus-visible {
    background: none;
    border: none;
  }

  // Mobile & portrait Tablet
  @include mobileTablet {
    text-decoration: none;
    color: $black2;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
  }
}

a.programContainer {
  display: block;

  &:hover {
    text-decoration: underline;
    color: $primary-dark-blue;
  }
  .programName,
  .programLocation {
    display: block;
  }

  .programName {
    color: $pure-black;

    &:hover {
      color: $primary-dark-blue;
    }

    @include mobileTablet {
      text-decoration: none;
      color: $pure-black;
      font-weight: 500;
    }
  }

  .programLocation {
    text-transform: uppercase;
    color: $primary-blue;

    &:hover {
      color: $primary-dark-blue;
    }
  }
}

.programReports {
  margin-left: 15px;
  font-size: 24px;

  .icon {
    width: 1em;
    height: 1em;
  }
}
