@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.root {
  align-items: center;
  background-color: $primary-dark-blue;
  border: 1px solid $primary-dark-blue;
  border-radius: 17px;
  color: $pure-white;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  height: 34px;
  padding: 0 14px;

  &:hover {
    background-color: $primary-blue-light;
    border-color: rgba(5, 117, 230, 0.4);

    .label,
    .icon {
      color: $primary-dark-blue;
    }
  }

  &.disabled {
    background-color: $pure-white;
    border-color: $grey;

    .label {
      color: $primary-dark-blue;
    }

    .icon {
      color: $pure-white;
    }
  }

  &:hover {
    background-color: $primary-blue-light;
    border-color: rgba(5, 117, 230, 0.4);

    .label,
    .icon {
      color: $pure-black;
    }
  }
}

.label {
  margin: 0 10px 0 0;
}

.icon {
  color: $pure-white;
  font-size: 12px;
}
