@import "assets/styles/1-settings";

.root {
  .inner {
    visibility: visible;
  }

  .content {
    font-size: 16px;
    text-align: left;
    line-height: 1.2;
    color: $black;

    .title {
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 1.2;
      text-align: left;

      .icon {
        width: 1em;
        height: 1em;
        margin-right: 15px;
        font-size: 28px;
        color: $primary-dark-blue;
      }
    }
  }

  .footer {
    .confirm {
      flex: 1 0 auto;
    }
  }
}

.locationContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .label {
    margin-bottom: 5px;
  }
}

.location {
  .input {
    $height: 46px;

    display: flex;
    align-items: center;
    width: 100%;
    height: $height;
    border-radius: $height / 2;
    border: 1px solid $grey;
    padding: 16px 27px;
    font-weight: 500;
    font-size: 16px;
    color: $primary-dark-blue;
    box-sizing: border-box;
    transition: 0.25s ease-in-out;
    transition-property: border-bottom-right-radius, border-bottom-left-radius;

    &::placeholder {
      opacity: 1;
      color: $primary-dark-blue;
    }

    &.open {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.error {
  margin-top: 10px;
  color: $red2;
}
