@import "src/assets/styles/1-settings";

.button {
  > span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    padding: 0 0 2px;
    border: 2px solid currentColor;
    border-radius: 100%;
    background: none;
    font-weight: 500;
    color: $pure-grey;
  }
}

.drawer {
}

.paper.selectorWeight {
  box-shadow: none;
  visibility: visible;
}
