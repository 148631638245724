@import "src/assets/styles/1-settings";

.card {
  background-color: $grey-light;
  margin: 0 0 4px;

  &:last-child {
    margin: 0;
  }
}
