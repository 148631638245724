@import "assets/styles/1-settings";

.popper {
  z-index: 3000 !important;

  .paper {
    border: 1px solid $grey;
    border-top: 0;
    border-radius: 0 0 24px 24px;
    box-shadow: 0 5px 21px 0 rgba(15, 73, 139, 0.1);
    margin: 0;
  }
}
