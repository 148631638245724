@import "~react-scrollbar/dist/css/scrollArea.css";
@import "../defaults";

.scrollarea {
  .scrollarea-content {
    touch-action: inherit;
  }

  .scrollbar-container {
    border-radius: $scrollbar-container-thickness;

    .scrollbar {
      border-radius: $scrollbar-thickness;
    }

    &.vertical {
      width: $scrollbar-container-thickness;

      .scrollbar {
        width: $scrollbar-thickness;
        margin-right: auto;
        margin-left: auto;
      }
    }

    &.horizontal {
      height: $scrollbar-container-thickness;

      .scrollbar {
        height: $scrollbar-thickness;
        margin-top: auto;
        margin-bottom: auto;
      }
    }
  }
}

.scrollarea--white {
  .scrollbar-container {
    &.vertical {
      .scrollbar {
        background: $white;
      }
    }

    &.horizontal {
      .scrollbar {
        background: $white;
      }
    }
  }
}
