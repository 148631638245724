@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

.root {
  display: flex;
  height: 100%;
  flex: 1 0 auto;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
}

.panelWrapper {
  display: flex;
  flex: 1 0 auto;
  position: relative;

  @include mobileTablet {
    height: calc(100vh - #{$appbar-height-mobile});
  }

  &.isDragged {
    bottom: 0;
    left: 0;
    position: fixed;
  }
}

.panel,
.map {
  height: 100%;
  position: absolute;
  top: 0;
}

.map {
  background-color: $grey;
  right: 0;
  z-index: 1;

  @include tablet-landscape {
    height: calc(45vh - #{$appbar-height-desktop});
    width: 100%;
  }

  @include mobileTablet {
    position: relative;
    width: 100%;
  }

  :global {
    .gm-style-iw-d {
      max-height: none !important;
    }
  }
}

.panel {
  display: flex;
  flex-direction: column;
  left: 0;
  transition: width 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  z-index: 2;

  @include not-mobile {
    background-color: $white;
  }

  @include mobile {
    background-color: $pure-white;
  }

  @include tablet-landscape {
    bottom: 0;
    height: 55vh;
    top: auto;
    width: 100%;
  }

  @include mobileTablet {
    border-radius: 24px 24px 0 0;
    bottom: 0;
    max-height: calc(100vh - 133px);
    top: auto;
    width: 100%;
  }

  .panelExpanded & {
    width: 100%;

    @include tablet-landscape {
      height: calc(100vh - 80px);
      transition: height 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }

  &.panelMin {
    height: 156px;
  }

  &.panelMedium {
    height: 64%;
  }

  &.panelFull {
    height: calc(100% - 77px);
  }

  &.panelNull {
    height: 0;
  }
}

@include desktop {
  $nbItems: 2;
  $panelWidth: $search-mixed-results-width;
  $panelMinWidth: $search-program-desired-width * $nbItems + $search-program-grid-gap *
    ($nbItems - 1) + 2 * map-get($search-program-grid-padding-x, desktop);

  .panel {
    width: $panelWidth;
    min-width: $panelMinWidth;
  }
  .map {
    width: calc(100% - #{$panelWidth});
    max-width: calc(100% - #{$panelMinWidth});
  }
}

.panelHeader {
  @include mobileTablet {
    padding: 0 16px 16px;
  }

  .panelHeaderInner {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 16px 0 0 0;
  }
}

.panelHeaderTop {
  background-color: $white;
  border-bottom: 1px solid $grey-light;
  padding: 8px 24px;

  @include desktop {
    .panelExpanded & {
      align-items: center;
      display: flex;
      margin: 0 -4px;
    }
  }
}

.panelHeaderBottom {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 16px 24px;
  position: relative;

  @include desktop {
    justify-content: center;
  }

  @media (min-width: $breakpoint-lg-min) and (max-width: 1350px) {
    padding: 16px;
  }
}

.listSwitcherContainer {
  margin-left: auto;
  margin-right: 6vw;
}

.listSwitcherContainerExp {
  margin-right: 1vw;
}

.saveSearchBtn {
  margin-right: 16vw;
}

.saveSearchBtnExp {
  margin-right: auto;
  text-wrap: nowrap;
}

.panelContent {
  flex-grow: 1;
  overflow: hidden;
  position: relative;

  @include mobileTablet {
    overflow-y: auto;
  }

  &.panelContentLocked {
    overflow: hidden;
  }
}

.panelContentHideLayer {
  background: rgba(5, 34, 65, 0.6);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1000;
}

.panelScroll {
  height: 100%;
  max-height: 100%;
}

.closeMapBtn {
  align-items: center;
  background-color: $pure-white;
  border: 0;
  border-radius: 8px;
  box-shadow: 4px 0 15px 0 rgba(15, 73, 139, 0.1);
  color: $black;
  display: flex;
  font-size: 16px;
  height: 46px;
  justify-content: center;
  left: 30px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 30px;
  width: 46px;
  z-index: 1;

  &:hover {
    color: $primary-dark-blue;
  }

  @include tablet-landscape {
    left: 40px;
    top: 20px;
  }
}

.mapAutocomplete {
  width: 420px;
  position: absolute;
  top: 30px;
  left: 76px;
  z-index: 1;

  @include tablet-landscape {
    top: 20px;
    left: 100px;
  }
}

.showMapBtn {
  align-items: center;
  background-color: transparent;
  border: 0;
  color: $black;
  display: flex;
  font-size: 16px;
  justify-content: center;
  margin: 0;
  padding: 0;

  &:hover {
    color: $primary-dark-blue;

    .showMapBtnIcon {
      color: $primary-dark-blue;
    }
  }
}

.showMapBtnIcon {
  color: $primary-blue;
  font-size: 20px;
  margin: 0 5px 0 0;
}

.dragBtn {
  background-color: transparent;
  border: 0;
  height: 36px;
  line-height: 36px;
  margin: 0;
  padding: 16px;
  position: relative;
  text-indent: -99999px;
  width: 100%;

  &:focus {
    &:before {
      opacity: 0.2;
    }
  }

  &:before {
    background-color: $black2;
    content: "";
    border-radius: 4px;
    height: 4px;
    left: 50%;
    opacity: 0.08;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 48px;
  }
}

.location {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  position: relative;

  @include desktop {
    .panelExpanded & {
      padding: 8px 4px;
      width: 50%;
    }
  }

  @include tablet {
    width: 420px;

    .autocomplete .container .input {
      padding-right: 50px;
    }
  }

  &.onMap {
    @include not-desktop {
      position: absolute;
      z-index: 1;
    }

    @include tablet-landscape {
      top: 20px;
      left: 100px;
      padding: 0;
    }

    @include tablet-portrait {
      top: 20px;
      left: 20px;
    }

    @include mobile {
      width: 100%;
      top: 0;
      left: 0;
      padding: 16px;
    }
  }

  .mapFull & {
    display: none;
  }
}

.filters {
  align-items: center;
  display: flex;
  padding: 8px 0;

  @include desktop {
    .panelExpanded & {
      padding: 0 4px;
      width: 50%;
    }
  }
}

.criterias {
  background-color: $white;
  border-bottom: 1px solid $grey-light;
  display: none;
  margin-top: -8px;
  padding: 0 24px 8px;

  &.visible {
    display: block;
  }
}

.hide {
  display: none;
}

.moreCriteriasBtn {
  align-items: center;
  background: none;
  border: 0;
  border-left: 1px solid $grey-light;
  display: flex;
  height: 34px;
  padding: 0 8px;
  position: absolute;

  @include tablet {
    width: 50px;
    height: calc(100% - 2px);
    right: 0;
    top: 1px;
  }

  @include mobile {
    right: 24px;
    top: 19px;
  }
}

.moreCriteriasBtnIcon {
  color: $pure-black;
  font-size: 18px;
}

.sortButton {
  font-size: 16px;
}

.sortWrapper {
  margin: 0 0 0 20px;
}
