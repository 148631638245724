@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $pure-white;
}

.imageContainer {
  width: 100%;
  margin-bottom: 25px;
}

.image {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 10px;

  @include mobile {
    margin-bottom: 20px;
  }
}

.name {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  position: relative;
  margin-bottom: 10px;
  padding-bottom: 12px;
  font-weight: 500;
  font-size: 18px;
  text-align: center;

  &::after {
    content: "";
    width: 32px;
    height: 1px;
    position: absolute;
    left: 50%;
    bottom: 0;
    background: $blue-light;
    transform: translateX(-50%);
  }
}

.job {
  $lineHeight: 1.1;

  display: flex;
  align-items: center;
  min-height: 2em * $lineHeight;
  font-size: 12px;
  line-height: $lineHeight;

  .picto {
    display: block;
    flex: 0 0 auto;
    margin-right: 10px;
  }

  .text {
    flex: 1 0 0;
    font-weight: 300;
    color: $header-grey;
  }
}

.phone {
  margin-top: 22px;
  font-size: 13px;

  &:global(.button) {
    height: 35px;
  }

  & + .email {
    margin-top: 12px;
  }
}

.email {
  margin-top: 22px;
  font-weight: 500;
  font-size: 13px;
  color: inherit;
  background: linear-gradient(to right, $blue-light, $blue-light) left bottom no-repeat;
  background-size: 100% 2px;
}
