@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";
@import "src/assets/styles/variables";

.lotsListWrapper {
  position: relative;
}

.lotsListHeader {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: sticky;
  top: $programMenuAnchorsHeight;
  width: 100%;
  z-index: 1;
}

.tableHeader {
  border-radius: 8px 8px 0 0;
  display: flex;

  &.noBorderRadius {
    border-radius: 0;
  }
}

.minHeight {
  min-height: 52px;
}
