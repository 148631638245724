@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

.root {
  border-collapse: separate;
  border-spacing: 0 10px;
  color: $pure-black;
  font-size: 14px;
  width: 100%;

  &.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
  }

  a {
    color: $pure-black;
    display: block;
    padding: 15px;
  }

  thead {
    @include mobileTablet {
      display: none;
    }
  }

  thead,
  tr,
  th {
    text-align: left;
    padding: 5px 15px;
  }

  tbody tr {
    background: $white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    border-radius: 2px;
    transition: all 0.2s ease-in-out;

    td {
      border: none;
      transition: all 0.2s ease-in-out;
    }

    td:hover {
      transform: scale(1.02);
    }

    td:first-child {
      font-weight: 500;
    }
  }

  tbody tr:hover {
    transform: scale(1.02);

    a {
      color: $blue;
    }
  }
}
