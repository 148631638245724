@import "assets/styles/1-settings";

.btnWrapper {
  padding: 0 5px;

  &:first-child {
    padding-left: 0;
  }

  .button {
    background-color: $pure-white;
    border-color: $grey;
    border-radius: 8px;
    height: 43px;
    justify-content: start;
    letter-spacing: unset;
    min-width: 0;
    padding: 10px 18px;

    &.hiddenLabel {
      .icon {
        margin: 0;
      }

      .labelText {
        display: none;
      }
    }
  }

  .label {
    color: $primary-dark-blue;
    font-family: $vinciFontName;
    font-size: 15px;
    font-weight: 400;
    text-transform: none;
    white-space: nowrap;

    .content {
      display: flex;
      flex-direction: column;

      &.disabled {
        color: $pure-grey;
      }

      .top {
        line-height: 1.2;
      }

      .sub {
        font-size: 8px;
        line-height: 1;
        text-transform: uppercase;
      }
    }
  }

  .icon {
    height: 22px;
    margin: 0 10px 0 0;
    width: 22px;
  }
}
