@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.root {
  padding: 24px;

  @include mobileTablet {
    padding-top: 12px;
  }
}

.text {
  font-size: 16px;
  margin: 0;
}
