@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

.root {
  border-radius: 6px;
  display: flex;
  position: relative;

  @include not-desktop {
    flex-direction: column;
    width: 100%;

    .mediaContainer {
      order: 0;
    }

    .content {
      order: 10;
    }
  }

  .cta {
    background: $white;
    color: $primary-dark-blue;

    &:hover {
      background: $primary-dark-blue;
      color: $white;
    }
  }
}

.content {
  flex: 1 0 0;
  order: 10;

  @include desktopTablet {
    padding: 35px 50px;

    &:not(:only-child) {
      max-width: 50%;
    }
  }

  @include mobileTablet {
    padding: 20px;
  }
}

.text {
  &:not(:first-child) {
    margin-top: 20px;
  }
}

.mediaContainer {
  margin: 0;
  padding: 0;
  overflow: hidden;

  @include not-desktop {
    border-top-right-radius: inherit;
    border-top-left-radius: inherit;
  }

  @include desktopTablet {
    position: absolute;
    top: 0;
    bottom: 0;
  }

  @include mobileTablet {
    position: relative;
    padding-top: 56.25%;

    & > :first-child {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .image {
    width: 100%;
    height: auto;

    @supports (object-fit: cover) {
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &.videoContainer {
    :global {
      .vinci-youtube-player {
        width: 100%;
        height: 100%;
      }
    }
  }
}

// MODIFIERS
.left {
  @include desktopTablet {
    flex-direction: row-reverse;

    .mediaContainer {
      right: 55%;
      left: 0;
      border-top-left-radius: inherit;
      border-bottom-left-radius: inherit;
    }
  }
}

.right {
  @include desktopTablet {
    flex-direction: row;

    .mediaContainer {
      right: 0;
      left: 55%;
      border-top-right-radius: inherit;
      border-bottom-right-radius: inherit;
    }
  }
}
