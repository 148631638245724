// stylelint-disable color-no-hex
// Neutral
$neutral-black: #1b2633;
$neutral-darker: #536373;

// Semantic
$semantic-grey: #e3e4ec;

// Text
$text-dark: $neutral-black;
