@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

$gutter: 3.6rem;

.root {
  display: flex;
  text-align: initial;
}

.items {
  @include not-mobile {
    width: calc(50% - #{$gutter});
    margin-right: ($gutter / 2);
  }

  @include mobile {
    width: 100%;
  }
}

.medias {
  @include not-mobile {
    width: 50%;
    margin-left: ($gutter / 2);
  }

  @include mobile {
    width: 100%;
  }
}
