@import "src/assets/styles/1-settings";
.attachment {
  input {
    display: block;
    position: absolute;
    clip: rect(0, 0, 0, 0);
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .label {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    margin: 0 0 16px;
  }

  .title {
    .subtitle {
      color: $pure-grey;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.dragZone {
  background: #ffffff;

  border: 1px dashed $primary-dark-blue;
  box-sizing: border-box;
  border-radius: 5px;

  width: 270px;
  height: auto;
  min-height: 270px;

  margin-top: 5px;

  canvas {
    width: 100% !important;
    height: auto !important;
    margin: 64px 0 0;
  }
}

.icon {
  color: $grey;
  font-size: 60px;
  padding: 10px;
}

.fileInformations {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
}

.fileInformationsText {
  color: $pure-grey;
  display: flex;
  flex-direction: column;
  font-style: italic;
  font-size: 14px;
  line-height: 19px;
}

.howTo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.howToText {
  font-size: 16px;
  margin: 0 0 5px;
}

.canvas {
  width: 168px;
  height: 238px;
  margin: 16px 48px 16px 48px;
}

.clearButton {
  outline: none;
  border: none;
  background: none;

  position: absolute;
  padding-top: 15px;
  padding-left: 234px;
}

:global(.react-pdf__Page__textContent) {
  height: auto !important;
  width: 100% !important;
}
