@import "src/assets/styles/1-settings";

.root {
  align-items: center;
  background: $grey;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  text-align: center;

  &.absolute {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
