@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

.info {
  display: flex;
  justify-content: center;
  align-items: center;

  @include mobileTablet {
    padding: 0 10px;
  }
}

.img {
  padding: 7px 0;
  flex: 0 0 auto;
  position: relative;
  overflow: hidden;
  margin-right: 15px;

  @include desktopTablet {
    width: 67px;
  }

  @include mobileTablet {
    width: 85px;
  }

  img {
    display: block;
    width: 100%;
    position: relative;
    z-index: 10;
    border-radius: 10px 0;
  }

  &::before {
    content: "";
    height: 56.7%;
    width: 54.3%;
    position: absolute;
    top: 0;
    left: 7%;
    z-index: 0;
    border: solid $blue;
    border-width: 2px 0 0 2px;
    border-top-left-radius: 10px;
    pointer-events: none;
  }

  &::after {
    content: "";
    width: 73%;
    height: 74.4%;
    position: absolute;
    right: 10%;
    bottom: 0;
    z-index: 20;
    border: solid $primary-blue-softer;
    border-width: 0 2px 2px 0;
    border-bottom-right-radius: 10px;
    pointer-events: none;
  }
}

.content {
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: $primary-dark-blue;

  &:only-child {
    align-self: center;
  }
}

.name {
  line-height: 1.2;
  font-weight: 500;

  @include desktopTablet {
    font-size: 14px;
  }

  @include mobileTablet {
    font-size: 18px;
  }
}

.role {
  @include desktopTablet {
    margin-top: 0.2rem;
    font-size: 11px;
  }

  @include mobileTablet {
    font-size: 14px;
  }
}

.contact {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 35px;
  border-radius: 35px;
  padding-bottom: 0.1em;
  transition: background-color $transition-duration-fast ease-in-out;
  font-weight: 500;
  font-size: 14px;

  .icon {
    width: 1em;
    height: 1em;
    margin-right: 5px;
    margin-bottom: -0.1em;
  }
}

.email {
  background: $primary-blue-softer;
  color: $primary-blue;

  .icon {
    font-size: 20px;
  }

  &:not(:first-child) {
    margin-top: 10px;
  }

  @include desktopTablet {
    &:hover {
      background-color: $primary-blue-soft;

      .icon:global {
        animation: vibrate 1.25s infinite ease-in-out;
      }
    }
  }

  @include mobileTablet {
    &:focus {
      background-color: $primary-blue-soft;
    }

    .icon:global {
      animation: vibrate-spaced 6s 3s infinite ease-in-out;
    }
  }
}

.phone {
  display: flex;
  align-items: center;
  align-self: center;
  font-weight: 500;
  font-size: 15px;
  color: $primary-dark-blue;

  .icon {
    margin-right: 5px;
    border-radius: 100%;
  }

  @include desktopTablet {
    .icon {
      width: 1.5em;
      height: 1.5em;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.25em;
      background: $primary-blue-softer;
      font-size: 22px;
      color: $primary-dark-blue;
    }
  }

  @include mobileTablet {
    width: 100%;
    background: $primary-blue;
    color: $pure-white;

    &:focus {
      background-color: $primary-dark-blue;
    }

    .icon {
      font-size: 24px;

      &:global {
        animation: vibrate-spaced 6s infinite ease-in-out;
      }
    }
  }
}
