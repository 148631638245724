@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

$listGutter: 16px;

.list {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0 (-$listGutter / 2);
  padding: 0;

  @include mobile {
    flex-direction: column;
    margin: 0;
  }
}

.item {
  align-items: center;
  color: $green;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 0 ($listGutter / 2);
  width: 20%;

  @include mobile {
    flex-direction: row;
    max-width: none;
    padding: ($listGutter / 2);
    width: 100%;
  }

  .itemTag {
    flex: 0 0 auto;
    margin-bottom: 8px;
    width: 85px;

    @include mobile {
      margin: 0;
    }

    .chip {
      height: 30px;
      width: 100%;
    }

    .itemTagLabel {
      font-size: 14px;
      font-weight: 500;
    }
  }

  .itemIconWrapper {
    display: flex;
    flex: 0 0 auto;
    justify-content: center;
    margin-bottom: 8px;
    width: 60px;

    @include mobile {
      margin: 0;
    }
  }

  .itemIcon {
    font-size: 22px;
    height: 1em;
    width: 1em;
  }

  svg.itemIcon {
    fill: currentColor;
  }

  .itemTitle {
    font-size: 16px;
    line-height: 1;
    text-align: center;

    @include mobile {
      flex-grow: 1;
    }
  }
}

.currentItem {
  color: $primary-dark-blue;

  .itemTitle {
    font-weight: 500;
  }
}

.currentItem ~ .item {
  color: $grey;

  .itemTitle {
    font-weight: 500;
  }
}
