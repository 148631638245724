@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

.root {
  width: $search-program-desired-width;
  max-width: 100%;
  position: relative;
  z-index: 0;
  border-radius: 8px;
  background: $pure-white;
  transition: background-color $transition-duration-fast,
    z-index $transition-duration-fast steps(2, jump-start);

  &.active {
    background-color: $grey-light;
  }

  .content {
    position: relative;
    z-index: 20;
    border-radius: inherit;
    color: inherit;
  }

  &.promo {
    outline: $search-result-promo-border-width solid var(--promo-bg-color, $orange);

    .buttons {
      width: calc(100% + #{2 * $search-result-promo-border-width});
      left: -$search-result-promo-border-width;
      border: solid var(--promo-bg-color, $orange);
      border-width: 0 $search-result-promo-border-width $search-result-promo-border-width;
    }
  }

  &:hover {
    z-index: 20;
    background-color: $grey-light;
    transition: background-color $transition-duration-fast,
      z-index $transition-duration-fast step-start;

    .buttons {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.thumbnail {
  height: 230px;
  position: relative;
  z-index: 10;
}

.infos {
  position: relative;
  z-index: 0;
}

.buttons {
  width: 100%;
  height: 90px;
  position: absolute;
  left: 0;
  top: calc(100% - 15px);
  z-index: 10;
  background: inherit;
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
  visibility: hidden;
  opacity: 0;
  transform: translateY(-30px);
  transition: $transition-duration-fast;
  transition-property: visibility, opacity, transform;
}
