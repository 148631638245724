@import "assets/styles/1-settings";

.tabs {
  position: relative;
  z-index: 10;
  overflow: visible !important;

  .scroller {
    overflow: visible !important;
    border-radius: inherit;
  }

  .flexContainer {
    border-radius: inherit;
  }

  .indicator {
    display: none;
  }

  .tab {
    letter-spacing: unset;
    text-transform: none;
  }
}

.tab {
  height: $program-booster-tab-height;
  position: relative;
  z-index: 0;
  background: rgba($black, 0.8);
  transition: 0.2s;
  transition-property: background-color, color;

  &:first-child {
    border-top-left-radius: inherit;
  }

  &:last-child {
    border-top-right-radius: inherit;
  }

  &.selectorWeight {
    overflow: visible;
    font-weight: 400;
    font-size: 24px;
    font-family: inherit;
    color: $pure-white;
  }

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    border-radius: inherit;
    background: transparent;
    transition: inherit;
    transition-property: background-color;
  }

  &::after {
    content: "";
    width: 16px;
    height: 16px;
    position: absolute;
    top: 100%;
    left: 50%;
    z-index: -2;
    border-bottom-right-radius: 7px;
    transform: translate(-50%, -105%) rotate(45deg);
    transition: 0.2s ease-in-out;
    transition-property: background-color, transform;
  }

  &.selected {
    &.selectorWeight {
      background: $program-booster-selected-tab-background;
      font-weight: 500;
      color: $primary-dark-blue;

      &::before {
        background: $program-booster-selected-tab-background;
      }

      &::after {
        background: $program-booster-selected-tab-background;
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
  }
}

.pricesWrapper {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  z-index: 0;
  background: $program-booster-background;
  color: $pure-white;

  &:not([hidden]) {
    display: flex;
  }
}

.prices {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 20px $program-booster-padding 0;
  width: 100%;

  span {
    display: block;
  }
}

.pricesAmount {
  font-weight: 500;
  font-size: 18px;
}

.pricesSub {
  font-size: 12px;
}

.lots {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  color: $pure-white;
  text-transform: uppercase;
}

.addToFolderButton {
  background: none;
  border: 0;
  margin: 0;
  padding: 0;

  .addToFolderButtonIcon {
    font-size: 24px;
  }
}
