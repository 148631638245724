@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

.root {
  .labelRoot {
    color: $pure-grey;
    font-family: $vinciFontName;
    font-size: 16px;
    transform: translate(16px, 16px) scale(1);

    &.labelFocused {
      color: $primary-dark-blue;
    }

    &.labelFocused,
    &:global(.MuiFormLabel-filled),
    &:global(.MuiInputLabel-filled) {
      transform: translate(16px, 6px) scale(0.75);
    }

    &.multilineLabel {
      transform: translate(16px, 21px) scale(1);

      &.labelFocused,
      &:global(.MuiFormLabel-filled),
      &:global(.MuiInputLabel-filled) {
        transform: translate(16px, 6px) scale(0.75);
      }
    }

    &.labelError {
      color: $red;
    }
  }

  .inputRoot {
    border: 1px solid $grey;
    border-radius: 6px;
    color: $primary-dark-blue;

    &.inputFocused {
      border-color: $primary-dark-blue;
    }

    &.inputDisabled {
      background-color: $white;
      color: $primary-dark-blue;

      .input {
        height: 100%;
        padding: 13px 16px;
      }

      .notchedOutline {
        display: block;
      }

      .endAdornment {
        background-color: transparent;
        height: 100%;
      }

      .endAdornmentIcon {
        color: $black;
      }
    }

    &.inputAdornedEnd {
      padding-right: 0;
    }

    &.inputError {
      border-color: $red;
      color: $red;

      .endAdornment {
        color: $red;
      }
    }

    &.inputSuccess {
      border-color: $green;
    }

    &.inputRootDate {
      &.inputDisabled {
        .endAdornmentIcon {
          color: $grey;
        }
      }

      .endAdornmentIcon {
        color: $primary-dark-blue;
      }
    }
  }

  .inputRootDate,
  .inputRootNumber {
    &.inputFocused {
      border-color: $grey;
    }
  }

  .input {
    box-sizing: border-box;
    font-family: $vinciFontName;
    font-size: 16px;
    font-weight: 500;
    height: 46px;
    padding: 10px 16px 0;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    &[type="number"] {
      -moz-appearance: textfield;
    }
  }

  .notchedOutline {
    border: 0;
    display: none;
    padding: 0 40px;
    top: 0;

    legend {
      color: $primary-dark-blue;
      font-family: $vinciFontName;
      font-size: 16px;
      padding: 14px 0 0;
      visibility: visible;

      span {
        padding: 0;
      }
    }

    &Invisible {
      legend {
        visibility: hidden;
      }
    }
  }

  .endAdornment {
    background-color: $grey-light;
    border-radius: 0 6px 6px 0;
    color: $primary-dark-blue;
    flex: 0 0 auto;
    font-family: $vinciFontName;
    font-size: 22px;
    font-weight: 500;
    height: 48px;
    justify-content: center;
    max-height: none;
    padding: 0;
    width: 46px;

    sup {
      font-size: 12px;
      position: relative;
      top: -3px;
    }
  }

  .endAdornmentIcon {
    color: $primary-dark-blue;
  }

  .helperTextRoot {
    font-family: $vinciFontName;
    font-size: 16px;
    margin: 4px 0 0;

    &.helperTextError {
      color: $red;
    }
  }

  &.horizontal {
    align-items: center;
    display: flex;
    flex-direction: row;

    .labelRoot {
      display: flex;
      flex-direction: column;
      margin-right: 8px;
      margin-bottom: 5px;
      position: relative;
      transform: none;
    }

    .input {
      color: $pure-black;
      font-size: 14px;
      padding: 0 9px;
    }

    .endAdornment {
      width: auto;
      margin-left: 0;
      padding: 0 10px;
      font-size: 20px;
      font-weight: 400;
    }
  }
}
