.root.vi3p {
  height: 1em;
  width: 1em;
}

.checked.vi3p {
  &::before {
    height: 0.6em;
    width: 0.6em;
  }
}

.label.vi3p {
  padding: 0.6rem;
  font-size: 0.9rem;
}
