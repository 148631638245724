@import "src/assets/styles/1-settings";

.selectVinci {
  width: 21em;
  border: 1px solid $primary-dark-blue;
  background-color: $white;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);

  .selectVinciItem {
    display: block;
    padding: 12px 24px 12px 28px;
    border: 0;
    outline: none;
    background: none;
    letter-spacing: 1px;
    text-transform: uppercase;
    cursor: pointer;
    width: 100%;
    left: 25px;
    text-align: left;

    &:after {
      position: absolute;
      content: "";
      width: 84%;
      height: 1px;
      margin-top: 31px;
      left: 25px;
      text-align: center;
      background-color: #979797;
      opacity: 0.42;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    &:hover {
      color: $white;
      background-color: $primary-dark-blue;
    }
  }

  &.bottom-start,
  &.bottom-end,
  &.bottom {
    margin-top: 5px;
  }

  &.top-start,
  &.top-end,
  &.top {
    margin-bottom: 5px;
  }
}
