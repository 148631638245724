@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

.root {
  position: relative;
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;

  &.hover {
    .slider {
      :global {
        .slick-arrow:not(.slick-disabled) {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}

.promo {
  width: calc(100% + #{2 * $search-result-promo-border-width});
  position: absolute;
  top: -1px;
  left: -$search-result-promo-border-width;
  z-index: 20;
  background-color: $orange;
  border-radius: inherit;
  color: $pure-white;
  padding: 11px 11px 12px;

  .text {
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 12px;
    line-height: 1.2;
    overflow: hidden;

    * {
      margin: 0;
    }
  }
}

.images {
  height: 100%;
  border-radius: inherit;
  overflow: hidden;

  .slider {
    :global {
      .slick-arrow {
        visibility: hidden;
        opacity: 0;
        transition-property: opacity, visibility, background-color, color;
      }
    }
  }

  .img {
    display: block;
    max-width: 100%;
    height: 100%;

    @supports (object-fit: cover) {
      width: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }

  .dots {
    width: 130px;
    bottom: 7px;

    :global {
      .slick-slide {
        width: 26px;
      }
    }

    .dot {
      width: 5px;
      height: 5px;
      transition: background-color $transition-duration-fast ease-in-out;

      &.current {
        background-color: $primary-dark-blue;
      }
    }
  }
}

.label {
  position: absolute;
  top: 22px;
  left: 0;
}

.link {
  height: 100%;
}

.pictos {
  position: absolute;
  right: 8px;
  bottom: 6px;
  left: 8px;
  z-index: 10;
}
