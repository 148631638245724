@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon {
  height: 60px;
  width: 60px;
}

.infosLink {
  background: none;
  border: 0;
  color: $primary-dark-blue;
  font-family: $vinciFontName;
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  outline: none;
  padding: 0;

  &::after {
    background-color: $primary-blue;
    content: "";
    display: block;
    height: 1px;
    transition: 300ms;
    width: 100%;
  }

  &:hover::after {
    width: 36%;
  }
}
