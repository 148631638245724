@import "../1-settings";
@import "../variables";

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.page-layout {
  display: flex;
  flex-direction: column;
}

.page-container {
  max-width: #{1280px - $sidebar-width};
  margin: 0 auto;
}

main[role="main"] {
  display: flex;
  flex-direction: column;

  // This needs to be specified, as IE11 doesn't support natively the <main> tag.
  flex-grow: 1;
  min-width: 0;

  // This is SUPER important so the sliders on the disconnected homepage don't expand to a bazillion pixels wide
  position: relative;
  z-index: 100;
  min-height: calc(100vh - 80px);

  //background: $white-soft
  //box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06)

  // ******************************************
  // Main Wrapper for special page
  .main-wrapper {
    .main-col {
      display: inline-block;
      vertical-align: top;
      width: calc(100% - 400px);
    }

    .right-col {
      position: fixed;
      display: inline-block;
      vertical-align: top;
      width: 400px;
    }
  }

  // ******************************************
  // Section & class which are on
  section {
    &.section-conteneur {
      position: relative;

      &.section-prochainement {
        background-color: $white;
        overflow: hidden !important;
      }

      &.section-actualites {
        background-color: $white-soft;
        overflow: hidden !important;
      }

      &.section-discover {
        background-color: $white;
      }

      &.section-grey {
        margin-top: 2em;
        margin-bottom: 2em;
        background-color: $white-soft;
      }

      &.section-mentions-legales {
        padding-top: 2em;
        padding-bottom: 2em;
      }

      .section-conteneur-wrap {
        &.panel-white {
          background-color: $white;
          margin-left: 48px;
        }

        &.panel-criteres {
          padding: 1em $grid-gap;
          margin-bottom: 0;

          .container {
            padding: 0;
          }
        }

        &.panel-criteres-advanced {
          padding-right: 3em;
        }

        &.panel-discover {
          overflow: hidden;
        }

        &.panel-research {
          background: $white;
        }
      }
    }
  }
}

body.page-programme,
body.page-lot {
  main[role="main"] {
    section {
      &.section-conteneur {
        overflow: hidden;
      }
    }

    .main-wrapper {
      background-color: white;
      width: 1107px !important;

      .right-col {
        position: relative;
        top: -7em;
        z-index: 50;

        .right-pictogram {
          position: absolute;
          top: -8em;
          z-index: 50;

          .right-pictogram-list {
            width: 75%;
            float: right;
            padding: 25px 32px 32px;
            display: flex;
            justify-content: space-around;
          }
        }
      }
    }
  }
}

body.homepage-advanced {
  main[role="main"] {
    .main-wrapper {
      .right-col {
        position: relative;
      }
    }
  }
}

// Desktop
@media (min-width: 1281px) {
  body.page-programme,
  body.page-lot {
    main[role="main"] {
      .main-wrapper {
        width: 1230px !important;
      }
    }
  }
}

// Non Desktop
@media (max-width: $breakpoint-md-max) {
  body {
    overflow-x: hidden;
  }

  // Empêche de pouvoir bouger tout le contenu
  main[role="main"] {
    section {
      &.section-conteneur {
        .section-conteneur-wrap {
          box-sizing: border-box;
        }
      }
    }
  }
}

// Desktop & Landscape Tablet
@media (min-width: $breakpoint-md-min) {
  .page-layout {
    .sidebar-wrapper + main {
      flex: 1 0 0;
      width: calc(100% - #{$sidebar-width});
    }
  }
}

// Landscape Tablet
@media (min-width: $breakpoint-md-min) and (max-width: $breakpoint-md-max) {
  body.page-programme,
  body.page-lot {
    main[role="main"] {
      section {
        &.section-conteneur {
          .section-conteneur-wrap {
            box-sizing: inherit;

            &.panel-white {
              margin-left: inherit;
            }
          }
        }
      }

      .main-wrapper {
        width: 97% !important;
      }

      .page-programme-image,
      .page-lot-image {
        .page-programme-image-wrapper,
        .page-lot-image-wrapper {
          width: 940px;
        }
      }
    }
  }
}

// Mobile & portrait Tablet
@media (max-width: $breakpoint-sm-max) {
  .page-layout {
    flex-direction: column;
  }

  main[role="main"] {
    min-height: auto;
  }

  .section-conteneur {
    .container {
      width: auto !important;
      padding: 0 16px;
    }
  }

  .block-content-title {
    margin: 20px 0 30px;
    font-size: 28px;
    line-height: 1;
  }

  body {
    font-size: 15px !important;

    main[role="main"] {
      section {
        &.section-conteneur {
          &.section-mentions-legales {
            padding-top: 4em;
          }

          &.panel-criteres-research {
            overflow: hidden;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

            .section-conteneur-wrap {
              margin-top: 1em;
              margin-bottom: 1em;
            }
          }

          &.panel-pink {
            background-color: $blue;
          }

          .section-conteneur-wrap {
            &.panel-criteres {
              overflow: hidden;
              margin-top: 0;

              &.open {
                display: inherit;
              }
            }

            &.panel-custom-research {
              overflow: hidden;
            }
          }
        }
      }
    }
  }

  body.page-programme,
  body.page-lot {
    section {
      &.section-conteneur {
        &.section-grey {
          .section-conteneur-wrap {
            margin-top: 2em;
            margin-bottom: 2em;
          }
        }

        .section-conteneur-wrap {
          &.panel-white {
            margin-left: auto;
          }
        }
      }
    }
  }

  body.page-programme-localisation,
  body.page-lot-localisation {
    main[role="main"] {
      .main-wrapper {
        width: inherit !important;

        .main-col {
          display: inherit;
          width: inherit;
        }
      }
    }
  }

  body.page-programme-price,
  body.page-lot-price {
    section {
      &.section-conteneur {
        &.section-grey {
          overflow: inherit !important;

          .section-conteneur-wrap {
            min-height: 90px;
            padding-top: 20px;

            .block-content-price {
              overflow: inherit;
            }
          }
        }
      }
    }
  }

  body.homepage-advanced {
    main[role="main"] {
      section {
        &.section-conteneur {
          .section-conteneur-wrap {
            &.panel-criteres {
              overflow: inherit;
              display: inherit;
            }

            &.panel-criteres-advanced {
              padding-right: 0;
            }
          }
        }
      }

      .main-col {
        margin-top: 40px;
      }

      .right-col {
        margin-bottom: 2em;
      }
    }
  }
}

@media (max-width: $breakpoint-xs-max) {
  body {
    font-size: 14px;
  }
}

.main {
  &__authenticated {
    margin-top: $appbar-height-desktop;
    @media (max-width: $breakpoint-sm-max) {
      margin-top: $appbar-height-mobile;
    }
  }
}
