@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

.root {
  z-index: 2500 !important;

  .modalSkin {
    position: relative;
    height: auto;
    text-shadow: none;

    background: $pure-white;
    box-shadow: 0 4px 15px rgba(15, 73, 139, 0.104988);
    border: 1px solid $grey;
    border-radius: 12px;
    margin: 0;
    max-width: 560px;
    width: 100%;

    @include mobile {
      margin: 16px;
    }
  }

  .modalInner {
    padding: 40px 30px 45px;
    position: relative;

    @include mobile {
      padding: 50px 24px 33px;
    }
  }

  .modalOuter {
    position: relative;
  }

  .iconButton {
    margin: 0;
    padding: 0;
    position: absolute;
    top: 26px;
    right: 26px;

    &:hover {
      background: none;
    }

    .svgIcon {
      width: 15px;
      height: 15px;
    }
  }

  .modalContent {
    font-size: 16px;
    text-align: center;
  }

  .modalTitle {
    color: $pure-black;
    font-family: $vinciFontName;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin: 0 0 20px;
    text-align: center;

    @include mobileTablet {
      margin-left: 32px;
      margin-right: 32px;
    }
  }

  .modalFooter {
    display: flex;
    justify-content: center;
    margin: 20px -8px 0 -8px;

    @include mobile {
      flex-direction: column-reverse;
      margin: 20px 0 0;
    }

    .button {
      margin: 0 8px;

      @include mobile {
        margin: 4px 0;
      }
    }

    .cancelButton {
      @include not-mobile {
        width: 160px;
      }
    }

    .confirmButton {
      @include not-mobile {
        width: 160px;
      }
    }
  }
}
