@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.scrollWrapper {
  max-height: 300px;
  padding: 0 6px 0 0;
}

.scroll {
  height: 100%;
}

.scrollContent {
  display: flex;
  flex-direction: column;
  height: 100%;
}
