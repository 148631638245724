@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.modalRoot {
  .modalPaper {
    border-radius: 12px;
    margin: 0;
    padding: 55px 70px;
    width: 586px;

    @include not-desktop {
      max-width: 586px;
      width: 100%;
    }

    @include mobile {
      margin: 15px;
      padding: 48px 25px 34px;
    }
  }

  .modalClose {
    color: $pure-black;
    font-size: 15px;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 16px;
    top: 16px;
  }

  .modalTitle {
    color: $pure-black;
    font-size: 20px;
    font-weight: 500;

    @include mobile {
      font-size: 22px;
    }
  }

  .filters {
    color: $pure-grey;
    font-size: 16px;
    margin: 20px 0 0;
  }

  .formField {
    margin: 20px 0 0;
  }

  .formFieldCheckbox {
    align-items: flex-start;
    margin-left: -3px;
    margin-right: 0;

    .formFieldCheckboxLabel {
      color: $black;
      margin-left: 5px;
    }
  }

  .formButtons {
    display: flex;
    margin: 40px -4px 0;

    @include mobile {
      flex-direction: column-reverse;
      margin: 26px 0 0;
    }

    .formButtonsItem {
      padding: 0 4px;

      @include mobile {
        padding: 4px 0;
      }
    }
  }

  .button {
    padding: 14px 40px;
  }
}
