@import "src/assets/styles/1-settings";

.resultItemWrapper {
  flex-direction: column;
  padding: 0;
  font-family: $vinciFontName;

  .resultItemHeader {
    display: inline-flex;
    height: 65px;
    align-items: center;
    padding: 0 22px;
    background-color: $white;
    width: 100%;

    > * {
      font-size: 16px;
      line-height: 19px;
      color: $pure-black;
    }
  }
  .resultItemHeader2 {
    display: flex;
    justify-content: space-between;
    padding: 15px 22px;
    padding-top: 0px;
    background-color: $white;

    .showMapBtn {
      align-items: center;
      background-color: transparent;
      border: 0;
      color: $primary-blue;
      display: flex;
      font-size: 18px;
      justify-content: center;
      margin: 0;
      padding: 0;

      .showMapBtnIcon {
        color: $primary-blue;
        font-weight: bold;
      }
    }

    .showMapBtnIcon {
      color: $primary-blue;
      margin: 0 5px 0 0;
    }
  }

  .btnDetails {
    background-color: $primary-blue;
    color: $pure-white;
    text-transform: unset;
    font-family: $vinciFontName;
    height: 56px;
    max-height: 56px;
    border-radius: 0;
  }
  .btnDetailsPromo {
    background-color: $orange;
    color: $pure-white;
  }
  .btnDetailsLabel {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }
  .btnDetailsLabelPromo {
    display: flex;
    flex-direction: column;
  }
  .labelPromo {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
  }

  .resultItemInfo {
    padding: 24px;
    background-color: $pure-white;
    margin: 0;
    border-bottom: 1px solid $grey-light;
    .line {
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding-bottom: 8px;
    }
    dt {
      display: inline-flex;
      font-size: 14px;
      line-height: 18px;
      color: $pure-black;

      .additionnalInfo {
        font-size: 14px;
        line-height: 18px;
        color: $pure-grey;
        margin-left: 5px;
      }
    }
  }

  div.actionsBtn {
    display: inline-flex;
    background-color: $pure-white;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid $grey-light;

    .actionBtnContainer {
      margin: 16px 4px;
      .actionBtn {
        width: 50px;
        min-width: 0;
        height: 42px;
        margin-right: 8px;
      }
      .svgIcon {
        height: 25px;
        width: 40px;
      }
    }
  }
}

.pills {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > :not(:first-child) {
    margin-top: 8px;
  }
}

.pills:not(:empty) + .lotNumber {
  margin-left: 16px;
}

.lot {
  background: none;
  border: none;
  padding: 0;
  text-decoration: underline;
  color: $primary-blue;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
}

.plan {
  margin-left: auto;
}

.promoText * {
  margin: 0;
}
