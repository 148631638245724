@import "assets/styles/1-settings";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 70px;
  position: relative;
  padding: 22px $program-booster-padding $program-booster-padding;
  background: $program-booster-background;
}

.alert {
  font-size: 18px;
  color: $yellow-pale;
  transition: color 0.25s ease-in-out;

  &.active {
    background: $grey-light;
    color: $black;
  }

  &:not(.active) {
    &:hover,
    &:focus,
    &:active {
      color: $primary-blue-light;
    }
  }
}

.addToFolder {
  $padding: 10px;

  position: absolute;
  right: $program-booster-padding - $padding;
  bottom: $program-booster-padding - $padding;
  border: 0;
  padding: $padding;
  background: none;
  font-size: 24px;
  color: $white;

  :global .icon {
    display: block;
  }
}
