@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

$lineHeight: 1.4;

.root {
  display: flex;
  flex-direction: column;
}

.title {
  font-weight: 500;
  font-size: 22px;
}

.content {
  display: flex;
  align-items: flex-start;
  font-size: 1.8rem;
  line-height: $lineHeight;
}

.icon {
  display: block;
  flex: 0 0 auto;
  width: 40px;
  height: auto;
  margin-top: 10px;

  @include not-mobile {
    transform: translateY(-3px); // Correct for the bad vertical text alignment in the Vinci font

    &:not(:last-child) {
      margin-right: 24px;
    }
  }
}

.text {
  font-size: inherit;
  line-height: inherit;
}

.reverse {
  @include not-mobile {
    flex-direction: row-reverse;
    margin-right: 12px;

    .icon:not(:last-child) {
      margin-right: 0;
      margin-left: 55px;
    }
  }
}

// MODIFIERS
.standard {
  .icon {
    @include mobile {
      margin-right: 24px;
    }
  }
}

.columns {
  .title {
    margin-bottom: 16px;
  }

  .content {
    align-items: center;
    color: $pure-black;
  }

  .icon {
    margin-top: 0;
    margin-right: 24px;
    transform: none;
  }
}

.vertical {
  .content {
    flex-direction: column;
    align-items: center;
  }

  .icon {
    margin: 0 0 20px;
    transform: none;
  }
}
