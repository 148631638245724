@charset "UTF-8";

// Variables bootstrap maison - Medias queries
$screen-lg-min: 1201px;
$screen-md-min: 992px;
$screen-sm-min: 768px;
$screen-xs-min: 0px;

$screen-md-max: $screen-lg-min - 1;
$screen-sm-max: $screen-md-min - 1;
$screen-xs-max: $screen-sm-min - 1;
$screen-xxs-max: 469px;

// Variables de couleurs
// $white: #fff;
$white-soft: #f5f5f5;
// $black: #000;

$text: #293138;
$text-blue: #00264c;
$text-light: #9b9b9b;

$purple: #890c58;
$blue: #0773df;
$blue-dark: #003868;
$blue-light: #0773df;

$grey: #e3e5e8;

$red: #dc3545;

$box-bg: #fff2fa;

$border: #e3e5e8;

$grid-gap: 15px;

// General variables
$btn-height: 50px;

// Home variables
$home-margin-h: 80px;

// Variables Header + Footer
$header-height: 80px;
$header-height-mobile: 50px;
$extended-message-height-mobile: 88px;
$sidebar-width: 80px;
$footer-border-color: rgba($border, 0.26);
$research-toggle-height: 45px;

$subpanel-header-height: 50px;

// Detail Page
$detail-page-margin-h-sm: 15px;
$detail-page-margin-h-md: 60px;
$detail-page-booster-width-md: 280px;
$detail-page-booster-width-lg: 350px;
$detail-page-col-gutter-sm: 30px;
$detail-page-col-gutter-md: 50px;

// Research
$research-bar-height-md: 70px;
$research-bar-height-sm: 92px;
$research-results-actions-height: 50px;
$research-results-actions-height-mobile: 40px;
$research-results-extended-height: 89px;
$research-results-extended-height-mobile: 60px;

// Lot
$lotDetailHeaderHeight: 100px;

// Program
$programBoosterPadding: 24px;
$programBoosterBackground: $primary-dark-blue;
$programBoosterSelectedTabBackground: $pure-white;
$programDocumentsMobilePaddingX: 16px;
$programMenuAnchorsHeight: 80px;
