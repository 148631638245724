@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.root {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

.container {
  display: flex;
  flex-direction: column;
  background: $pure-white;

  @include not-mobile {
    margin: 80px;
    padding: 80px;
  }
}
