@import "src/assets/styles/1-settings";

.openIcon {
  color: $primary-dark-blue;
}

.nextMonth,
.prevMonth {
  color: $grey-light-2;
  font-size: 18px;
}

.nextMonth {
  transform: rotate(180deg);
}

.popoverRoot {
  :global(.MuiPickersToolbar-toolbar) {
    background-color: $primary-dark-blue;
  }
}
