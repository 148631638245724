@import "assets/styles/1-settings";

.cell {
  align-items: center;
  display: flex;
  position: relative;
  padding: 0;
  background: $grey-light;

  &::before,
  &::after {
    border-left: 1px solid $grey;
    position: absolute;
    top: 5px;
    bottom: 3px;
    z-index: 1;
  }

  &::before {
    right: 100%;
  }

  &::after {
    left: 100%;
  }

  &.separatorBefore:not(:first-child)::before,
  &.separatorAfter:not(:last-child)::after,
  &.separatorBoth:not(:first-child)::before,
  &.separatorBoth:not(:last-child)::after {
    content: "";
  }

  &.separatorNone::before,
  &.separatorNone::after {
    content: none !important;
  }

  &.alignLeft .cellContent {
    text-align: left;
  }

  &.alignCenter .cellContent {
    text-align: center;
  }

  &.alignRight .cellContent {
    text-align: right;
  }
}

.cellContent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 45px;
  position: relative;
  border: none;
  padding: 5px;
  background: none;
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  text-transform: uppercase;
  white-space: nowrap;
  color: $pure-black;
}

.cellText {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0.2em;
  line-height: 1.2;
}

.cellTitle {
  white-space: normal;
  line-height: 1;
}

.additionalInfo {
  font-size: 10px;
  color: $grey-light-3;
  text-align: center;
}

.sortIcon {
  flex: 0 0 auto;
  vertical-align: unset;
  margin-left: 8px;
  width: 5px;
  height: 8px;
}

.highlight {
  // TODO style when the sort by is applied to that column head
  visibility: visible;
}

.asc {
  // TODO style when the sort order is ASC
  visibility: visible;
}
