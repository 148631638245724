@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

.dialog {
  background: rgba($pure-black, 0.8);
  height: 100vh;
  z-index: 2000 !important;
}

.paper {
  background: transparent;
  box-shadow: none;
  overflow: visible !important;
}

.close {
  background: none;
  border: 0;
  color: $pure-white;
  font-size: 26px;
  line-height: 1;
  padding: 0;
  position: fixed;
  right: 30px;
  top: 30px;
  z-index: 10;
}

.slideImg {
  width: 100%;
}
