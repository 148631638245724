@import "assets/styles/1-settings";
@import "assets/styles/2-tools";
@import "assets/styles/variables";

.root {
  flex: 1 0 auto;
}

.title {
  margin: 0;
  font-weight: 500;
  line-height: 1.25;
  color: $blue-dark;

  &:not(.show) {
    display: none;
  }

  @include not-mobile {
    padding: 30px $home-margin-h;
    font-size: 40px;
  }
}
