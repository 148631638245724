@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.root {
  background-color: $pure-white;
  border-radius: 4px;
  cursor: default;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 241px;

  @include mobileTablet {
    border-radius: 0;
    height: calc(100vh - 56px);
    left: 0;
    position: fixed;
    top: 56px;
    width: 100%;
    z-index: 100;
  }

  @include tablet {
    top: 66px;
  }

  .largeButton {
    min-width: 0;

    @include mobileTablet {
      padding: 16px;
    }
  }

  .largeButtonSvgIcon {
    height: 20px;
    margin: 0 10px 0 0;
    width: 20px;
  }

  .btnLabelWrapper {
    align-items: center;
    display: flex;
  }

  .btnLabel {
    color: $primary-dark-blue;
    font-family: $vinciFontName;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.1;
    text-transform: none;
  }

  .btnLabelInfos {
    font-size: 12px;
    font-weight: 400;
  }
}

.header {
  background-color: $primary-dark-blue;
  padding: 20px 60px 20px 20px;
  position: relative;
}

.closeBtn {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 8px;
  right: 8px;

  @include mobileTablet {
    top: 10px;
    right: 16px;
  }
}

.closeIcon {
  color: $pure-white;
  font-size: 14px;
  line-height: 1.2;

  @include mobileTablet {
    font-size: 22px;
  }
}

.listWrapper {
  padding: 2px 6px 2px 0;

  @include mobileTablet {
    flex-grow: 1;
    padding: 10px 6px 10px 0;
  }
}

.list {
  @include desktopTablet {
    max-height: 390px;
  }

  @include mobileTablet {
    height: 100%;
  }
}

.noDocs {
  padding: 10px 0;
}
