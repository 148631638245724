@import "src/assets/styles/1-settings";

$listGutter: 14px;

.list {
  display: flex;
  list-style: none;
  margin: 0 (-$listGutter / 2);
  padding: 0;

  > * {
    margin: 0 ($listGutter / 2);
  }
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $green;

  &.futureItem {
    color: $grey;
  }

  .itemTag {
    margin-bottom: 7px;
  }

  .itemIcon {
    width: 1em;
    height: 1em;
    margin-bottom: 2px;
    font-size: 22px;
  }

  svg.itemIcon {
    fill: currentColor;
  }

  .itemTitleContainer {
  }

  .itemTitle {
    max-width: 102px;
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    font-weight: 500;
  }

  .itemDate {
    margin-top: 3px;
    font-size: 10px;
  }
}

.currentItem {
  color: $primary-dark-blue;
}

.currentItem ~ .item {
  color: $grey;
}
