@import "src/assets/styles/1-settings";

.root {
  display: inline-block;

  &:hover {
    .icon {
      color: $primary-dark-blue;
    }
  }
}

.icon {
  color: $primary-blue;
  font-size: 22px;
}
