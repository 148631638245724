@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

.root {
  border-radius: 100%;
  background-color: $pink;
  font-size: 10px;
  line-height: 1.2;
  font-weight: 700;
  text-align: center;
  color: $pure-white;

  @include desktopTablet {
    height: map-get($notification-dot-dim, xl);
    width: map-get($notification-dot-dim, xl);
  }

  @include mobileTablet {
    height: map-get($notification-dot-dim, sm);
    width: map-get($notification-dot-dim, sm);
  }
}
