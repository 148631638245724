@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

.container {
  background-color: $pure-white;

  @include desktopTablet {
    padding: 0 24px;
  }
}

.nbItems {
  color: $pure-black;
  font-size: 26px;
  font-weight: 500;
  line-height: 1;
  margin: -6px 30px 0 0;
  white-space: nowrap;

  @include mobileTablet {
    margin-right: 20px;
  }
}

.header {
  align-items: center;
  box-sizing: content-box;
  display: flex;
  height: 46px;
  padding: 40px 0 24px;

  @include mobileTablet {
    flex-wrap: wrap;
    height: auto;
    padding: 24px;
  }
}

.buttonLink {
  background: none;
  border: 0;
  color: $primary-dark-blue;
  font-size: 15px;
  margin: 0 20px 0 0;
  padding: 0;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.buttonsHeader {
  @include mobileTablet {
    flex: 0 0 auto;
    margin: 16px 0 0;
    width: 100%;
  }
}

.buttons {
  align-items: center;
  display: flex;
  margin: 0 -10px;

  @include mobileTablet {
    justify-content: center;
    margin: 0 -4px;
    width: 100%;
  }
}

.buttonsItem {
  padding: 0 10px;

  @include mobileTablet {
    padding: 0 4px;
  }

  .button {
    background-color: transparent;
    border-width: 1px;
    color: $primary-dark-blue;
    font-family: $vinciFontName;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: unset;
    height: 46px;
    max-height: none;
    max-width: none;
    min-width: auto;
    padding: 0;
    text-transform: none;
    width: 50px;

    @include mobileTablet {
      height: 42px;
      width: 51px;
    }

    &:hover {
      background-color: $primary-blue-light;
      border: 1px solid rgba(5, 117, 230, 0.4);
    }
  }

  .buttonIcon {
    margin: 0;
    color: $primary-dark-blue;
    font-size: 18px;
  }

  .buttonSvgIcon {
    margin: 0;
    height: 20px;
    width: 20px;
  }

  .buttonLoader {
    margin: 0;
    height: 20px !important;
    width: 20px !important;
  }

  .deleteBtn {
    color: $pink;

    &:hover {
      border-color: $pink;
      color: $pink;
    }

    .buttonIcon {
      color: $pink;
    }
  }
}

.programs {
  display: grid;
  justify-items: center;
  align-items: stretch;
  gap: $search-program-grid-gap;

  @include desktop {
    grid-template-columns: repeat(3, 1fr);
  }

  @include desktop-xxl {
    grid-template-columns: repeat(4, 1fr);
  }

  @include tablet {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mobile {
    display: flex;
    flex-direction: column;
  }
}

.program {
  display: flex;
  align-items: flex-start;

  @include mobileTablet {
    flex-direction: column;
  }

  &.selected {
    border-color: $pure-white;
  }

  .checkbox {
    margin: 0 10px 0 0;
  }
}

.programCard {
  flex: 0 0 auto;
}

.compareLabel {
  display: flex;
  flex-direction: column;

  &.disabled {
    color: $pure-grey;
  }

  .top {
    line-height: 1.2;
  }

  .sub {
    font-size: 8px;
    line-height: 1;
    text-transform: uppercase;
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  max-height: 100%;
}
