@import "assets/styles/1-settings";
@import "assets/styles/2-tools";

$padding: 24px;

.root {
  @include mobile {
    padding: 0 $padding;
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.alert {
  position: relative;
  margin-bottom: 20px;
  padding: 12px 20px 10px 60px;
  font-weight: 500;
  box-shadow: 0 4px 4px rgba($pure-black, 0.25);

  &::before {
    content: "!";
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.2857em;
    height: 1.2857em;
    position: absolute;
    top: 12px;
    left: 20px;
    border-radius: 100%;
    padding-bottom: 0.1em;
    font-size: 14px;
  }
}

.warning {
  margin-bottom: 20px;
  padding: 12px 20px 12px 60px;
  background: $gold;
  color: $pure-white;

  &::before {
    background: $pure-white;
    color: $orange;
  }
}

.header {
  background-color: $pure-white;
  display: flex;
  flex-direction: column;
  margin: 0 -24px;
  padding: 0 24px;

  @include mobile {
    margin: 0;
  }
}

.headerInfos {
  align-items: center;
  display: flex;

  @include not-mobile {
    padding: 20px 0;
  }

  @include mobile {
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 20px;
    width: calc(100% - 70px);
  }
}

.title {
  font-weight: 500;
  font-size: 32px;
  color: $pure-black;
  white-space: nowrap;

  @include not-mobile {
    line-height: 1;

    &:not(:first-child) {
      margin-right: 16px;
      margin-left: 10px;
    }
  }

  @include mobile {
    line-height: 1.5625;
  }
}

.typology {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -2px;
}

.typologyItem {
  padding: 2px;
}

.noResult {
  display: flex;
  flex-direction: column;

  @include not-mobile {
    margin: 110px 0 220px;
    font-weight: 300;
    font-size: 40px;
    text-align: center;
  }

  @include mobile {
    width: 100%;
    margin-top: 40px;
    font-weight: 500;
    font-size: 22px;
    text-align: center;
  }
}

.lots {
  @include mobile {
    width: calc(100% + #{2 * $padding});
    margin-right: -$padding;
    margin-left: -$padding;
  }
}

.promotionHBRoot {
  @include not-desktop {
    border-radius: 0;
    margin: 16px -24px 0 -24px;
  }
}
