@import "src/assets/styles/1-settings";

.tooltip {
  text-transform: uppercase;
  color: $white;
  background-color: $pure-black;
  font-family: $vinciFontName;
  border-radius: 2px;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  padding: 8px;
  margin-bottom: 8px;
}

.arrowTooltip {
  color: $pure-black;
}

.btnDetailsContainer {
  width: 30px;
  height: 30px;
}

.root {
  &.MuiRoot {
    height: 30px;
    min-width: 30px;
    width: 30px;

    background: $pure-white;

    border: 1px solid $grey-light;
    box-sizing: border-box;
    border-radius: 8px;

    &:hover {
      color: $white;
      background-color: $primary-blue;
      border-color: $primary-blue;
    }
  }
}

.rotate {
  transform: rotate(180deg);
}

.loader {
  color: $pure-white;
}
