@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.root {
  .menuItemRoot {
    margin: 10px 0;
    padding: 0;

    @include mobileTablet {
      flex-direction: row-reverse;
      margin: 0;
      min-height: 0;
      padding: 10px 0;
    }

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    :global(.MuiFormControlLabel-root) {
      margin: 0;
    }
  }

  .labelRoot {
    color: $pure-black;
    font-family: $vinciFontName;
    font-size: 15px;
    margin: 0 0 0 8px;

    @include mobileTablet {
      margin: 0;
    }
  }

  .labelChecked {
    font-weight: 500;
  }

  .toggle {
    background: none;
    border: 0;
    color: $primary-blue;
    font-size: 15px;
    margin: 10px 0 0;
    padding: 0;
    text-decoration: underline;
  }
}
