@import "src/assets/styles/1-settings";

.root {
  max-width: 500px;
  margin: 10% auto;
  text-align: center;
}

.title {
  margin: 0 0 46px;
  font-weight: 700;
  font-size: 48px;
  line-height: 1.2;
  color: $primary-dark-blue;
}

.subtitle {
  font-size: 16px;
  color: $pure-black;
}

.image {
  display: block;
  width: 417px;
  height: auto;
  margin: 0 auto;
}
