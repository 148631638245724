@import "assets/styles/1-settings";

.loading {
  display: flex;
  flex: 1 0 auto;
  height: 100%;
  min-height: calc(100vh - #{$appbar-height-mobile});
  justify-content: center;
  align-items: center;
  background: rgba($black2, 0.3);
}
