@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.listLotContainer {
  position: relative;
}

.fullListLot {
  @include desktopTablet {
    max-height: calc(100vh - 320px) !important;
    height: calc(100vh - 320px) !important;
    overflow-y: scroll;
  }
}

.lotsList {
  display: table;
  width: 100%;
  position: relative;
  color: $pure-black;
  border-collapse: separate;

  // table-layout: fixed;

  .resultItemHeader {
    font-family: $vinciFontName;
    font-weight: 500;
    font-style: normal;
    font-size: 10px;
    line-height: 10px;
    text-transform: uppercase;
    color: $pure-black;
    width: 100%;

    display: table-row;
    text-align: center;

    background-color: $white;

    position: sticky;
    top: 0;

    z-index: 150;

    > * {
      display: table-cell;
      vertical-align: top;
      position: relative;
      padding-top: 15px;
      padding-bottom: 4px;
      text-align: left;

      background: $grey-light;
      border: 1px solid $grey-light;
      box-sizing: border-box;

      position: sticky;
      top: 0;
      z-index: 1000;

      &:not(:first-child):not(:last-child):not(:nth-last-child(2)):after {
        content: "";
        border-left: 1px solid $grey;
        height: 80%;
        position: absolute;
        right: 0;
        margin-left: -3px;
        top: 5px;
      }
    }

    label {
      margin-right: 0px;
      margin-left: 8px;
      span {
        margin-top: -4px;
      }
    }

    &:nth-child(1) {
      background: none;
    }

    > *:first-child {
      border-radius: 8px 0px 0px 0px;
      -moz-border-radius: 8px 0px 0px 0px;
      -webkit-border-radius: 8px 0px 0px 0px;
    }

    > *:last-child {
      border-radius: 0px 8px 0px 0px;
      -moz-border-radius: 0px 8px 0px 0px;
      -webkit-border-radius: 0px 8px 0px 0px;
    }

    > .actions {
      border-left-width: 0;
    }

    > :empty::before {
      content: none;
    }

    button {
      border: none;
      overflow: hidden;
      background: none;
      line-height: 1;
      font-weight: 500;
      font-style: normal;
      font-size: 10px;
      line-height: 10px;
      text-transform: uppercase;
      color: $pure-black;
      white-space: nowrap;

      // &::after {
      //   content: '';
      //   display: inline-block;
      //   width: 0.5em;
      //   height: 0.5em;
      //   position: relative;
      //   // top: 50%;
      //   // right: 10px;
      //   margin-left: 10px;
      //   border: solid $text-light;
      //   border-width: 0 1px 1px 0;
      //   transform: translateY(-50%) rotate(45deg);
      //   transition: transform 0.25s ease;
      // }
    }

    // .sort-up button::after {
    //   transform: translateY(-50%) rotate(-135deg);
    // }
  }
}
