.loading {
  height: auto;
  opacity: 0;
  width: 100%;
}

.loaded {
  animation: fadeInImg cubic-bezier(0.23, 1, 0.32, 1) 1;
  animation-delay: 0.1s;
  animation-duration: 0.7s;
  animation-fill-mode: forwards;
  opacity: 0;
  position: relative;
}

@keyframes fadeInImg {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
