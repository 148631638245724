@import "../../../common/assets/styles/main";
@import "KelQuartierSettings.module";

$map-poi-width: 30rem;

.root.v4 {
  @include revertContainerPadding();
  @include blockMargin(bottom);

  @include desktop {
    height: $program-map-height + $program-map-title-height-lg;
  }

  @include not-desktop {
    height: $program-map-height + $program-map-title-height-sm;
  }

  @include mobileTablet {
    height: auto;
  }
}

.poi.v4 {
  padding-top: 5.6rem;
  width: $map-poi-width;
  right: $page-margin-x-lg;

  @include desktop {
    top: -($program-map-height + $program-map-title-height-lg);
  }
}

.actionXL.v4 {
  width: $map-poi-width;
  right: $page-margin-x-lg;

  @include desktop {
    top: -($program-map-height + $program-map-title-height-lg);
  }

  @include not-desktop {
    top: -($program-map-height + $program-map-title-height-sm);
  }
}

.actionSM.v4 {
  width: $map-poi-width;

  @include mobileTablet {
    top: -56rem;
  }
}

.button.v4 {
  height: 3.2rem;
  font-size: 1.2rem;
  margin: 1.6rem auto 0;
  white-space: nowrap;
}
