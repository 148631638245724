@import "src/assets/styles/1-settings";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.subtitle {
  margin-top: 5px;
  font-size: 10px;
  line-height: 1;
  text-transform: uppercase;
  color: $pure-black;
}

.tags {
  $gutter: 4px;

  display: flex;
  margin-right: (-$gutter / 2);
  margin-left: (-$gutter / 2);

  .tag {
    margin: 0 ($gutter / 2);
  }

  &.displayColumn {
    flex-direction: column;
    margin: 0;

    .tag {
      margin: ($gutter / 2) 0;
    }
  }
}
