@import "src/assets/styles/1-settings";

.root {
  display: flex;
  flex-direction: column;

  &.double {
    margin-bottom: -5px;
  }
}

.priceContainer {
  align-items: center;
  display: flex;
  flex-direction: row;

  .svgIcon {
    color: $pure-grey;
    font-size: 15px;
    height: 15px;
    margin-right: 6px;
    width: 15px;
  }

  .highlight {
    color: $primary-dark-blue;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
  }

  .tax {
    font-size: 12px;
    line-height: 1;

    .displayPanel & {
      display: block;
    }
  }
}
