@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

$activeOutlineWidth: 2px;
$itemsGutter: 1.2rem;

.activeContainer {
  border-radius: 5px;
  background: $pure-black;
}

.mediasSlider {
  $arrow-dim: 32px;
  $arrow-font-size: 14px;
  $arrow-margin: 5px;

  @include not-mobile {
    flex: 0 0 auto;
    width: 80px;
    margin-left: 32px;
  }

  &.noSlider {
    @include mobile {
      display: flex;
      justify-content: center;
      margin-right: (-$itemsGutter / 2);
      margin-left: (-$itemsGutter / 2);

      .sliderItem {
        flex: 0 1 auto;
        width: calc(25% - #{$itemsGutter});
      }
    }
  }
}

.sliderItem {
  border-radius: 0.4rem;
  transition: box-shadow $transition-duration-fast ease-in-out;

  &.activeItem {
    box-shadow: 0 0 0 $activeOutlineWidth $primary-dark-blue;
  }
}

.videoMini {
  @extend %youtube-thumbnail;
}
