@import "src/assets/styles/1-settings";
@import "src/assets/styles/2-tools";

.root {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 24px 0;

  @include mobileTablet {
    flex-direction: column;
    justify-content: flex-start;
    padding: 16px 0;
  }

  .button {
    height: 45px;
    min-width: auto;
    width: 100%;
    font-family: $vinciExpanded;

    @include mobileTablet {
      height: 40px;
    }
  }

  .buttonProfile {
    color: $cyan-light;

    &:hover {
      color: $cyan-dark;
    }
  }

  .buttonTelProfile {
    background-color: $blue-dark-cerulean;
  }
}

.contact {
  @include mobileTablet {
    margin: 0 0 16px;
    width: 100%;
  }
}

.contactName {
  font-size: 16px;
  font-weight: 500;
}

.contactInfos {
  align-items: center;
  display: flex;
  font-size: 16px;
  margin: 5px 0 0;

  @include mobileTablet {
    align-items: flex-start;
    flex-direction: column;
  }
}

.contactSeparator {
  color: $primary-blue;
  padding: 0 4px;

  @include mobileTablet {
    display: none;
  }
}

.contactMail {
  color: $primary-blue;

  &:hover {
    color: $primary-dark-blue;
  }
}

.contactMailProfile {
  text-wrap: nowrap;
  color: $cyan-light;

  &:hover {
    color: $cyan-dark;
  }
}

.contactButtons {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;

  @include desktopTablet {
    max-width: 48%;
  }

  @include mobileTablet {
    flex-direction: column;
    width: 100%;
  }
}

.buttonWrapper {
  margin: 0 16px 0 0;
  min-width: 180px;

  &:last-child {
    margin: 0;
  }

  @include mobileTablet {
    margin: 0 0 8px;
    min-width: auto;
    width: 100%;
  }
}
