@import "src/assets/styles/1-settings";

.popper {
  z-index: 2500;
}

.tooltip {
  text-transform: uppercase;
  font-weight: 500;
  color: $white;
  background-color: $pure-black;
  padding: 8px 7px;
  font-size: 10px;
  font-family: $vinciFontName;
  border-radius: 2px;
}

.arrowTooltip {
  color: $pure-black;
}
