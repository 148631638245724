@import "assets/styles/1-settings";

.root {
  display: flex;
  align-items: flex-end;
  background: none;
  border: none;
  color: $pure-black;
  font-family: $vinciFontName;
  font-size: 14px;
  margin: 0;
  outline: none;
  padding: 0;

  &.sortAsc,
  &.sortDesc {
    color: $primary-blue;
    font-weight: 500;

    .sortIndicator {
      border-color: $primary-blue transparent;
    }
  }

  &.sortDesc {
    .sortIndicator {
      border-width: 5px 0 0 5px;
    }
  }
}

.sortIndicator {
  border-color: $pure-black transparent;
  border-style: solid;
  border-width: 0 0 5px 5px;
  display: inline-flex;
  height: 0;
  margin: 0 0 0 5px;
  width: 0;
}
