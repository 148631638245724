@import "assets/styles/1-settings";

.root {
  background: $pure-white;
}

.title {
  background: $pure-white;
}

.news {
  padding-top: 0;
}
