@import "PoiSettings.module";
@import "PoiVI3P.module";
@import "PoiV4.module";

.root {
  padding: 1.5rem 2rem;
  color: $neutral-darker;

  &:not(:first-child) {
    border-top: 1px solid $semantic-grey;
  }
}

.header {
  display: flex;
  align-items: center;
  margin-top: 0.8rem;
  margin-bottom: 1.6rem;
  color: $text-dark;
}

.toggle {
  // stylelint-disable-next-line declaration-no-important
  margin: 1.2rem 0 0 !important;

  > label {
    margin-left: 0;
  }
}
