@import "assets/styles/1-settings";
@import "assets/styles/variables";

.root {
  &:not(.foldable),
  &.foldable .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.head {
  display: flex;
  align-items: center;
  position: relative;
}

.title {
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 14px;

  &.rte {
    p, h1, h2, h3, h4, h5, h6 {
      margin: 0;
      line-height: 1;
    }
  }
}

// Desktop & landscape Tablet
@media (min-width: $breakpoint-md-min) {
  .container {
    display: flex;
    align-items: flex-start;
  }

  .picto {
    flex: 0 0 auto;
    margin-right: 15px;
  }

  .foldable .btn {
    display: none;
  }
}

// Mobile & portrait Tablet
@media (max-width: $breakpoint-sm-max) {
  .root {
    &:not(.foldable) {
      margin-top: 30px;
    }

    &:not(.foldable),
    &.foldable .container {
      flex-direction: row;
    }
  }

  .foldable {
    .btn {
      display: flex;
      align-items: center;
      width: 100%;
      position: relative;
      padding: 12px 15px 12px 0;
      border: 0;
      background: none;
      font-weight: 500;
      font-size: 18px;
      line-height: 1.1;
      text-align: left;

      &::after {
        content: "";
        display: block;
        width: 0.5em;
        height: 0.5em;
        position: absolute;
        top: 50%;
        right: 0;
        border: solid currentColor;
        border-width: 0 2px 2px 0;
        transform: translateY(-25%) rotate(-135deg);
      }
    }

    &:not(.open) {
      .btn {
        &::after {
          transform: translateY(-75%) rotate(45deg);
        }
      }

      .container {
        display: none;
      }
    }
  }

  .picto, .title {
    display: none;
  }
}
